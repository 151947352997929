// src/components/AllStudy.js
import React, { useContext, useEffect, useState } from "react";
import StudyContext from "../../../context/study/StudyContext";
import { Link, useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { FaEye, FaPencilAlt } from "react-icons/fa";
import {
  postExportExcel,
  postExportPDF,
  getDownloadReport,
} from "../../../services/sponsors";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import {
  createTheme,
  ThemeProvider,
  alpha,
  styled,
} from "@mui/material/styles";
import {
  DataGridPro,
  gridClasses,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  MenuItem,
  Box,
  Paper,
  InputBase,
  Tabs,
  Tab,
  Typography,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import "./Study.css";
import { mapExportData, mapStudyData } from "../../../utils/dataMapping";
import { CustomPagination } from "../../../components/CustomPagination";
import { SearchSharp } from "@mui/icons-material";
import PropTypes from "prop-types";
import UserContext from "../../../context/user/UserContext";
import { getSystemRoleById } from "../../../services/system_roles";
import ViewStudies from "./ViewStudies";
import { getAllExportStudies } from "../../../services/studies";
import { buttonLinkStyle } from "../StudySetup/Visits/Questionnaires/EditVisitQuestionnaire";

const getMuiTheme = createTheme({
  typography: {
    fontSize: 25,
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        cell: {
          "&:focus": {
            outline: "none",
          },
        },
      },
    },
  },
});

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.background.default,
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    ffontWeight: "bold",
    fontSize: 18,
    color: "#000",
    backgroundColor: theme.palette.grey[300],
  },
}));

const AllStudy = () => {
  const navigate = useNavigate();
  const { studyData, fetchStudies, totalStudyCount } = useContext(StudyContext);
  const { value, setValue } = useContext(UserContext);

  const [studyRoles, setStudyRoles] = React.useState([]);
  const [systemRoles, setSystemRoles] = React.useState([]);

  const [sortingField, setSortingField] = useState(null);
  const [sortingType, setSortingType] = useState(null);
  const [searchString, setSearchString] = useState("");
  const [debouncedSearchString, setDebouncedSearchString] = useState("");

  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
    studyName: true,
    sponsorName: true,
    portalUrl: true,
    apiUrl: true,
    questionnaireBuilderUrl: true,
    actions: true,
  });
  const [filters, setFilters] = useState([]);
  const [studiesAllData, setStudiesAllData] = useState([]);
  // useEffect(() => {
  //   const handler = setTimeout(() => {
  //     setDebouncedSearchString(searchString);
  //   }, 500);

  //   return () => {
  //     clearTimeout(handler);
  //   };
  // }, [searchString]);

  useEffect(() => {
    fetchAllDataForExport();
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchString(searchString);

      const searchColumns = [
        { field: "studyName", path: "studyName" },
        { field: "apiUrl", path: "apiUrl" },
        { field: "portalUrl", path: "portalUrl" },
        { field: "questionnaireBuilderUrl", path: "questionnaireBuilderUrl" },
        { field: "sponsorName", path: "sponsor.sponsorName" },
      ];
      // const searchColumns = ["name", "apiUrl", "portalUrl", "questionnaireBuilderUrl", "sponsorName"]
      const newFilters = searchColumns.map((column, index) => ({
        Id: index + 1,
        columnField: column.path,
        operatorValue: "contains",
        value: searchString,
      }));

      setFilters(newFilters);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchString]);

  useEffect(() => {
    if (studyData?.length > 0) {
      setLoad(false);
    }
  }, [studyData]);

  useEffect(() => {
    fetchStudies(
      pageNumber,
      pageSize,
      sortingType,
      sortingField,
      debouncedSearchString,
      filters
    );
  }, [
    pageNumber,
    pageSize,
    debouncedSearchString,
    filters,
    sortingType,
    sortingField,
  ]);

  const fetchAllDataForExport = async () => {
    setLoad(true);
    try {
      const res = await getAllExportStudies();
      if (res.status === 200) {
        const mappedData = mapStudyData(res.data.result);
        setStudiesAllData(mappedData);
        setLoad(false);
        return res.data;
      }
    } catch (err) {
      console.log("ERROR: ", err);
      setLoad(false);
    }
    return [];
  };

  const handlePageChange = (event, newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPageNumber(1);
  };
  // const handleColumnVisibilityChange = (newModel) => {
  //   setColumnVisibilityModel(newModel);
  // };
  const handleColumnVisibilityChange = (newModel) => {
    const updatedModel = {};
    Object.keys(newModel).forEach((key) => {
      updatedModel[key] = newModel[key];
    });
    setColumnVisibilityModel(updatedModel);
  };

  const handleFilterChange = (filterModel) => {
    const formattedFilters = filterModel.items.map((filter, index) => ({
      Id: index + 1,
      columnField: filter.columnField,
      operatorValue: filter.operatorValue,
      value: filter.value,
    }));
    setFilters(formattedFilters);
  };

  const handleSortModelChange = (sortModel) => {
    if (sortModel.length > 0) {
      const { field, sort } = sortModel[0];
      setSortingField(field);
      setSortingType(sort === "asc" ? "asc" : "desc");
    } else {
      setSortingField(null);
      setSortingType(null);
    }
  };
  const handleSearch = (event) => {
    const searchValue = event.target.value;
    setSearchString(searchValue);

    const searchColumns = [
      { field: "studyName", path: "name" },
      { field: "apiUrl", path: "apiUrl" },
      { field: "portalUrl", path: "portalUrl" },
      { field: "questionnaireBuilderUrl", path: "questionnaireBuilderUrl" },
      { field: "sponsorName", path: "sponsor.sponsorName" },
    ];
    // const searchColumns = ["name", "apiUrl", "portalUrl", "questionnaireBuilderUrl", "sponsorName"]
    const newFilters = searchColumns.map((column, index) => ({
      Id: index + 1,
      columnField: column.path,
      operatorValue: "contains",
      value: searchValue,
    }));

    setFilters(newFilters);
  };
  // const exportData = mapExportData(studyData);
  const exportData = mapStudyData(studyData);

  const GridExportToPDF = (props) => {
    return (
      <MenuItem
        onClick={async () => {
          try {
            const res = await postExportPDF(props.rows);
            if (res.status === 200) {
              const res2 = await getDownloadReport(res.data);
              if (res2.status === 200) {
                const blob = new Blob([res2.data], { type: "application/pdf" });
                saveAs(blob, "Studies.pdf");
              }
            }
            props.hideMenu?.();
          } catch (err) {
            toast.error("Failed to export PDF");
            console.log("Error exporting PDF: ", err.message);
          }
        }}
      >
        Export PDF
      </MenuItem>
    );
  };

  const GridExportToExcel = (props) => {
    return (
      <MenuItem
        onClick={async () => {
          try {
            const res = await postExportExcel(props.rows);
            if (res.status === 200) {
              const res2 = await getDownloadReport(res.data);
              if (res2.status === 200) {
                const blob = new Blob([res2.data], {
                  type: "application/vnd.ms-excel",
                });
                saveAs(blob, "Studies.xlsx");
              }
            }
            props.hideMenu?.();
          } catch (err) {
            toast.error("Failed to export Excel");
            console.log("Error exporting Excel: ", err.message);
          }
        }}
      >
        Export Excel
      </MenuItem>
    );
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExportContainer>
          <GridExportToExcel rows={studiesAllData} />
          <GridExportToPDF rows={studiesAllData} />
        </GridToolbarExportContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  }

  // const columns = [
  //   {
  //     field: "id",
  //     headerName: "ID",
  //     width: 0,
  //     hide: columnVisibilityModel.id,
  //     filterable: false,
  //     sortable: true,
  //   },
  //   {
  //     field: "studyName",
  //     headerName: "Name",
  //     flex: 1,
  //     minWidth: 220,
  //     hide: columnVisibilityModel.name,
  //     sortable: true,
  //   },
  //   {
  //     field: "sponsorName",
  //     headerName: "Sponsor Name",
  //     flex: 1,
  //     minWidth: 220,
  //     hide: columnVisibilityModel.sponsorName,
  //     sortable: true,
  //   },
  //   {
  //     field: "portalUrl",
  //     headerName: "Portal Url",
  //     flex: 1,
  //     minWidth: 220,
  //     hide: columnVisibilityModel.portalUrl,
  //     sortable: true,
  //   },
  //   {
  //     field: "apiUrl",
  //     headerName: "API Url",
  //     flex: 1,
  //     minWidth: 220,
  //     hide: columnVisibilityModel.apiUrl,
  //     sortable: true,
  //   },
  //   {
  //     field: "questionnaireBuilderUrl",
  //     headerName: "Question Builder Url",
  //     flex: 1,
  //     minWidth: 220,
  //     hide: columnVisibilityModel.questionnaireBuilderUrl,
  //     sortable: true,
  //   },
  //   {
  //     field: "actions",
  //     headerName: "Actions",
  //     hide: columnVisibilityModel.actions,
  //     flex: 1,
  //     sortable: false,
  //     filterable: false,
  //     minWidth: 100,
  //     renderCell: (params) => {
  //       const { id, sponsorId } = params.row;
  //       return (
  //         <button
  //           style={{
  //             cursor: "pointer",
  //             textAlign: "center",
  //             backgroundColor: "#337ab7",
  //             padding: "8px",
  //             border: "none",
  //             borderRadius: "5px",
  //             marginLeft: "10px",
  //           }}
  //           onClick={() =>
  //             navigate(`/edit-study/${id}`, {
  //               state: {
  //                 sponsorId: sponsorId,
  //               },
  //             })
  //           }
  //         >
  //           <FaPencilAlt color="white" size={15} />
  //         </button>
  //       );
  //     },
  //   },
  // ];

  const generateColumns = (data) => {
    const predefinedColumns = [
      {
        field: "studyName",
        headerName: "Name",
        flex: 1,
        minWidth: 220,
        sortable: true,
        filterable: true,
      },
      {
        field: "sponsorName",
        headerName: "Sponsor Name",
        flex: 1,
        minWidth: 220,
        sortable: true,
        filterable: true,
      },
      {
        field: "portalUrl",
        headerName: "Portal Url",
        flex: 1,
        minWidth: 220,
        sortable: true,
        filterable: true,
      },
      {
        field: "apiUrl",
        headerName: "API Url",
        flex: 1,
        minWidth: 220,
        sortable: true,
      },
      {
        field: "questionnaireBuilderUrl",
        headerName: "Questionnaire Builder Url",
        flex: 1,
        minWidth: 220,
        sortable: true,
      },
      // {
      //   field: "actions",
      //   headerName: "Actions",
      //   flex: 1,
      //   minWidth: 200,
      //   sortable: false,
      //   renderCell: (params) => (
      //     <Link to={`/study/${params.id}`}>
      //       <FaPencilAlt className="editIcon" />
      //     </Link>
      //   ),
      // },
      {
        field: "actions",
        headerName: "Actions",
        hide: columnVisibilityModel.actions,
        flex: 1,
        sortable: false,
        filterable: false,
        minWidth: 100,
        renderCell: (params) => {
          const { id, sponsorId } = params.row;
          return (
            <button
              style={{
                cursor: "pointer",
                textAlign: "center",
                backgroundColor: "#337ab7",
                padding: "8px",
                border: "none",
                borderRadius: "5px",
                marginLeft: "10px",
              }}
              onClick={() =>
                navigate(`/edit-study/${id}`, {
                  state: {
                    sponsorId: sponsorId,
                  },
                })
              }
            >
              <FaPencilAlt color="white" size={15} />
            </button>
          );
        },
      },
    ];

    return predefinedColumns.filter(
      (column) => columnVisibilityModel[column.field] !== false
    );
  };

  const newColumns = [
    {
      field: "studyName",
      // hide: true,
      headerName: "Name",
      width: 200,
      renderHeader: () => <div className="grid-heading">{"Name"}</div>,
      renderCell: (params) => {
        const studyName = params.row.studyName;
        const questionnaireBuilderUrl = params.row?.questionnaireBuilderUrl;

        return (
          <div className="grid-body">
            <button
              style={buttonLinkStyle}
              onClick={() => {
                let newUrl = "";
                const dataValue = params?.row?.studyName;
                if (
                  questionnaireBuilderUrl?.startsWith("https://") ||
                  questionnaireBuilderUrl?.startsWith("http://")
                ) {
                  newUrl = `${questionnaireBuilderUrl}?studyName=${encodeURIComponent(
                    dataValue
                  )}`;
                } else {
                  newUrl = `https://${questionnaireBuilderUrl}?studyName=${encodeURIComponent(
                    dataValue
                  )}`;
                }

                // Open the link with the data in the URL query string
                window.open(newUrl, "_blank", "noopener,noreferrer");
              }}
            >
              {studyName}
            </button>
          </div>
        );
      },
    },
    {
      field: "sponsorName",
      // hide: true,
      headerName: "Sponsor Name",
      width: 200,
      renderHeader: () => <div className="grid-heading">{"Sponsor Name"}</div>,
      renderCell: (params) => {
        const sponsorName = params.row.sponsorName;

        return (
          <div className="grid-body">
            <h5>{sponsorName}</h5>
          </div>
        );
      },
    },
    {
      field: "portalUrl",
      // hide: true,
      headerName: "Study Portal URL",
      width: 220,
      renderHeader: () => (
        <div className="grid-heading">{"Study Portal URL"}</div>
      ),
      renderCell: (params) => {
        const portalUrl = params.row.portalUrl;

        return (
          <div className="grid-body">
            <a
              href={`https://${portalUrl}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {portalUrl}
            </a>
          </div>
        );
      },
    },
    {
      field: "apiUrl",
      // hide: true,
      headerName: "API URL",
      width: 220,
      renderHeader: () => <div className="grid-heading">{"API URL"}</div>,
      renderCell: (params) => {
        const apiUrl = params.row.apiUrl;

        return (
          <div className="grid-body">
            <a
              href={`https://${apiUrl}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {apiUrl}
            </a>
          </div>
        );
      },
    },
    {
      field: "questionnaireBuilderUrl",
      // hide: true,
      headerName: "Study Management URL",
      width: 250,
      renderHeader: () => (
        <div className="grid-heading">{"Study Management URL"}</div>
      ),
      renderCell: (params) => {
        const questionnaireBuilderUrl = params.row.questionnaireBuilderUrl;

        return (
          <div className="grid-body">
            <button
              style={buttonLinkStyle}
              onClick={() => {
                let newUrl = "";
                const dataValue = params?.row?.studyName;
                if (
                  questionnaireBuilderUrl?.startsWith("https://") ||
                  questionnaireBuilderUrl?.startsWith("http://")
                ) {
                  newUrl = `${questionnaireBuilderUrl}?studyName=${encodeURIComponent(
                    dataValue
                  )}`;
                } else {
                  newUrl = `https://${questionnaireBuilderUrl}?studyName=${encodeURIComponent(
                    dataValue
                  )}`;
                }

                // Open the link with the data in the URL query string
                window.open(newUrl, "_blank", "noopener,noreferrer");
              }}
            >
              {questionnaireBuilderUrl}
            </button>
          </div>
        );
      },
    },
    {
      field: "actions",
      hide: columnVisibilityModel.actions,
      flex: 1,
      sortable: false,
      filterable: false,
      minWidth: 250,
      renderHeader: () => <div className="grid-heading ms-4">{"Edit"}</div>,
      renderCell: (params) => {
        const { id, sponsorId } = params.row;
        return (
          <div style={{ display: "flex", alignContent: "center", gap: "40px" }}>
            <button
              style={{
                cursor: "pointer",
                textAlign: "center",
                backgroundColor: "#337ab7",
                padding: "8px",
                border: "none",
                borderRadius: "5px",
                marginLeft: "10px",
              }}
              onClick={() =>
                navigate(`/edit-study/${id}`, {
                  state: {
                    sponsorId: sponsorId,
                  },
                })
              }
            >
              <FaPencilAlt color="white" size={15} />
            </button>
          </div>
        );
      },
    },
  ];

  const columns = generateColumns(exportData);
  if (load) {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          <BeatLoader color="#3661eb" />
        </div>
      </>
    );
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="content-body">
      <p className="admin-link" style={{ fontWeight: "600" }}>
        <Link to="/homepage">Home</Link>
        {" > "}
        <Link to="/all-studies">All Studies</Link>
      </p>
      <Row>
        <Col md={6}>
          <p className="sponsor-heading">All Studies</p>
        </Col>
        <Col sm={4}>
          <Paper
            component="form"
            sx={{
              p: "4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <SearchSharp style={{ width: 20, height: 20 }} />
            <InputBase
              sx={{ ml: 1, flex: 1, fontSize: 16 }}
              placeholder="Search Studies"
              inputProps={{ "aria-label": "search studies" }}
              onChange={handleSearch}
            />
          </Paper>
        </Col>
        <Col md={2}>
          <div className="userButtonContainer">
            <Link to="/create-study">
              <button
                className="addUserButton font-extrabold"
                style={{ borderRadius: "5px" }}
              >
                + Add Study
              </button>
            </Link>
          </div>
        </Col>
      </Row>
      <Box
        sx={{
          height: 669,
          width: "100%",
          "& .MuiDataGrid-columnHeaders": {
            minHeight: "58px !important",
          },
        }}
      >
        <ThemeProvider theme={getMuiTheme}>
          {/* {load ? (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "70vh" }}>
              <BeatLoader color="#3661eb" />
            </div>
          ) : ( */}
          {load ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "70vh",
              }}
            >
              <BeatLoader color="#3661eb" />
            </div>
          ) : studyData.length === 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "70vh",
                fontSize: "24px",
              }}
            >
              No Results Found
            </div>
          ) : (
            <StripedDataGrid
              rows={studyData}
              // rows={exportData}
              columns={newColumns}
              disableSelectionOnClick={true}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={handleColumnVisibilityChange}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
              components={{
                Toolbar: CustomToolbar,
              }}
              filterMode="server"
              onFilterModelChange={handleFilterChange}
              sortingMode="server"
              sortModel={
                sortingField && sortingType
                  ? [{ field: sortingField, sort: sortingType }]
                  : []
              }
              onSortModelChange={handleSortModelChange}
            />
          )}
          {/* )} */}
          <CustomPagination
            currentPage={pageNumber}
            totalCount={totalStudyCount}
            pageSize={pageSize}
            size="large"
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
        </ThemeProvider>
      </Box>
    </div>
  );
};

export default AllStudy;
