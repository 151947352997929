import React, { useCallback, useEffect } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { Stack, Box, TextField, Autocomplete } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { Row, Col } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import BeatLoader from "react-spinners/BeatLoader";
import { useCallbackPrompt } from "../../../../hooks/useCallbackPrompt";
import DialogBox from "../../../../components/DialogBox";

import {
  getAllRegions,
  editSystemCountry,
  getSystemCountryById,
} from "../../../../services/system_country";

import "../../Users/User.css";
import { rowStyles } from "../../StudySetup/Visits/Questionnaires/EditVisitQuestionnaire";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const EditCountries = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const id = params.id;
  const status = location?.state?.status;
  const regionId = location?.state?.regionId;
  const [load, setLoad] = React.useState(false);
  const [isActive, setIsActive] = React.useState(status);
  const [showDialog, setShowDialog] = React.useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);
  const [regionsData, setRegionsData] = React.useState([]);
  const [regionID, setRegionID] = React.useState(regionId);
  const [selectedRegion, setSelectedRegion] = React.useState(null);

  const createCountrySchema = yup.object().shape({
    name: yup.string().required("This field is required"),
    isoId: yup.string().required("This field is required"),
    regionId: yup.string().required("This field is required"),
    isActive: yup.bool().oneOf([true, false], "This field is required"),
  });

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(createCountrySchema),
  });

  const fetchRegionData = async () => {
    try {
      setLoad(true);
      const res = await getAllRegions();
      if (res.data.status === "Success" && res.data.result) {
        setRegionsData(res.data.result);
      } else {
        console.error("Failed to fetch regions");
      }
    } catch (error) {
      console.error("An error occurred while fetching the regions", error);
    } finally {
      setLoad(false);
    }
  };

  const fetchCountryById = async (id) => {
    try {
      setLoad(true);
      const res = await getSystemCountryById(id);
      if (res.status) {
        const { name, isoId, regionId, isActive } = res.data;
        setIsActive(isActive);
        setSelectedRegion(
          regionsData.find((region) => region.id === regionId) || null
        );
        setRegionID(regionId);
        reset({ name, isoId, regionId, isActive });
      }
    } catch (err) {
      console.log("Error: ", err.message);
    } finally {
      setLoad(false);
    }
  };

  React.useEffect(() => {
    fetchRegionData();
  }, []);

  React.useEffect(() => {
    if (regionsData.length) {
      fetchCountryById(id);
    }
  }, [id, regionsData]);

  const notify = () =>
    toast.success("Country Updated Successfully", {
      toastId: "form-creation",
    });

  const requestFailed = () =>
    toast.error("Something went wrong", {
      toastId: "requestFailed",
    });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      setLoad(true);
      setShowDialog(false);

      const name = data.name ?? "";
      const isoId = data.isoId ?? "";
      const isActive = data.isActive ?? "";
      const res = await editSystemCountry(id, {
        name,
        regionId: regionID,
        isoId,
        isActive,
      });

      if (res.status) {
        notify();
        setLoad(false);
        navigate("/system-settings");
      } else {
        requestFailed();
        setLoad(false);
      }
    } catch (err) {
      console.log("submit error: ", err);
      requestFailed();
      setLoad(false);
    }
  };

  const handleRegionChange = (event, value) => {
    setSelectedRegion(value);
    setRegionID(value ? value.id : "");
    setShowDialog(true);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    if (window.confirm("Changes you made will not be saved. Are you sure?")) {
      navigate("/system-settings");
    }
  };

  const handleNavigation = useCallback(
    (path) => {
      if (showDialog) {
        const userConfirmed = window.confirm(
          "Changes you made will not be saved. Are you sure?"
        );
        if (userConfirmed) {
          setShowDialog(false);
          navigate(path);
        }
      } else {
        navigate(path);
      }
    },
    [showDialog, navigate]
  );

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (showDialog) {
        event.preventDefault();
        event.returnValue = "";
        return "Changes you made will not be saved. Are you sure?";
      }
    };

    const handlePopState = (event) => {
      if (showDialog) {
        const userConfirmed = window.confirm(
          "Changes you made will not be saved. Are you sure?"
        );
        if (!userConfirmed) {
          window.history.pushState(null, "", window.location.href);
        }
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopState);
    };
  }, [showDialog]);

  return (
    <>
      {load ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <BeatLoader color="#3661eb" />
          </div>
        </>
      ) : (
        <>
          <div className="content-body">
            <p className="admin-link" style={{ fontWeight: "600" }}>
              <button onClick={() => handleNavigation("/homepage")}>
                <Link>Home</Link>
              </button>
              {" > "}
              <button onClick={() => handleNavigation("/system-settings")}>
                <Link>System Settings</Link>
              </button>
              {" > "}
              <span
                style={{
                  color: "#4b8ac0",
                  cursor: "pointer",
                }}
              >
                Update Country
              </span>
            </p>
            <div>
              <DialogBox
                showDialog={showPrompt}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
              />
            </div>

            <Box sx={{ marginTop: "2%" }}>
              <p className="user-heading">Update Country</p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Row style={{ marginTop: "2%" }}>
                  <Col md={5}>
                    <label className="uploadInputLabel">
                      Name <span className="error-highlight">*</span>
                    </label>
                    <input
                      className="nameField"
                      type="text"
                      name="name"
                      {...register("name", {
                        onChange: (e) => {
                          setShowDialog(true);
                        },
                      })}
                    />
                    <span className="error-text">{errors.name?.message}</span>
                  </Col>
                  <Col md={2}></Col>
                  <Col md={5}>
                    <label className="uploadInputLabel">
                      ISO Code <span className="error-highlight">*</span>
                    </label>
                    <input
                      className="nameField"
                      type="text"
                      name="isoId"
                      {...register("isoId", {
                        onChange: (e) => {
                          setShowDialog(true);
                        },
                      })}
                    />
                    <span className="error-text">{errors.isoId?.message}</span>
                  </Col>
                </Row>
                <Row style={{ marginTop: "2%" }}>
                  <Col md={5}>
                    <label className="uploadInputLabel">
                      Region <span className="error-highlight">*</span>
                    </label>
                    <Autocomplete
                      disablePortal
                      id="region-autocomplete"
                      options={regionsData}
                      getOptionLabel={(option) => option.name || ""}
                      value={selectedRegion}
                      onChange={handleRegionChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // label="Region"
                          {...register("regionId")}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: "14px" }, // Set the font size for selected value here
                          }}
                          error={!!errors.regionId}
                        />
                      )}
                      componentsProps={{
                        popper: {
                          sx: {
                            "& .MuiAutocomplete-listbox": {
                              fontSize: "13px", // Font size for dropdown options
                            },
                          },
                        },
                      }}
                    />
                    <span className="error-text">
                      {errors.regionId?.message}
                    </span>
                  </Col>
                  <Col md={2}></Col>
                  <Col md={5}></Col>
                </Row>
                <Row style={{ marginTop: "2%", paddingLeft: "0.5%" }}>
                  <Col md={2}>
                    <div className="toggleContainerCountries">
                      <p className="generalHead">Active</p>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <AntSwitch
                          defaultChecked={isActive}
                          inputProps={{ "aria-label": "ant design" }}
                          {...register("isActive", {
                            onChange: (e) => {
                              setIsActive(!isActive);
                              setShowDialog(true);
                            },
                          })}
                        />
                      </Stack>
                    </div>
                  </Col>
                  <Col md={10}></Col>
                </Row>
                {/* <Row style={{ marginTop: "3%" }}>
                  <Col md={6}></Col>
                  <Col md={6}>
                    <div className="createSponsor-buttons">
                      <button
                        className="sponsorCancelButton"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                      <button className="sponsorCreateButton" type="submit">
                        Save
                      </button>
                    </div>
                  </Col>
                </Row> */}
                <Row style={rowStyles}>
                  <Col md={8}></Col>
                  <Col md={2}>
                    <div className="study-management-head-end">
                      <button
                        onClick={(e) => {
                          handleCancel(e);
                        }}
                        className="study-management-cancel-btn"
                      >
                        Cancel
                      </button>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="study-management-head-end">
                      <button
                        type="submit"
                        className="study-management-create-btn-md"
                      >
                        Update
                      </button>
                    </div>
                  </Col>
                </Row>
              </form>
            </Box>
          </div>
        </>
      )}
    </>
  );
};

export default EditCountries;
