import React, { useEffect, useState } from "react";
import { Select, MenuItem, Button, FormControl } from "@mui/material";
import { FaTrashAlt } from "react-icons/fa";

const DynamicNavigtion = ({
  patientStatus,
  allVisits,
  setSelectedNavigation,
}) => {
  // State to store the navigation rows
  const [navigationRows, setNavigationRows] = useState([]);
  const [selectedPatienStatus, setSelectedPatientStatus] = useState(null);
  const [selectedVisit, setSelectedVisit] = useState(null);

  // Handle adding a new row
  const addNavigationRow = () => {
    setNavigationRows([
      ...navigationRows,
      { from: "", to: "" }, // You can add default values if needed
    ]);
  };

  // console.log(navigationRows, "rows");

  // Handle removing a row
  const removeNavigationRow = (e, index) => {
    e.preventDefault();
    const updatedRows = navigationRows.filter((_, i) => i !== index);
    setNavigationRows(updatedRows);
  };

  const textBoxStyles = {
    fontSize: 15,
    width: "500px",
    height: "10px",
  };

  const selectStyles = {
    width: 230,
    marginTop: 1,
  };

  useEffect(() => {
    if (selectedPatienStatus && selectedVisit) {
      let obj = {
        patientStatusTypeId: selectedPatienStatus?.id,
        childVisitId: selectedVisit?.id,
      };
      setSelectedNavigation((prevData) => [...prevData, obj]);
      setSelectedPatientStatus(null);
      setSelectedVisit(null);
    }
  }, [selectedPatienStatus, selectedVisit]);

  const handleSelectPatientStatus = (value) => {
    const data = patientStatus?.find((item) => item?.id === value);
    if (data) setSelectedPatientStatus(data);
  };

  const handleSelectVisit = (value) => {
    const data = allVisits?.find((item) => item?.id === value);
    if (data) setSelectedVisit(data);
  };

  return (
    <div style={{ width: "100%", marginLeft: "275px" }}>
      <Button variant="outlined" onClick={addNavigationRow}>
        Add Visit Navigation
      </Button>

      {navigationRows.map((row, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 10,
            gap: "10px",
          }}
        >
          <FormControl sx={selectStyles}>
            <Select
              name="visitAvailableBusinessRuleId"
              defaultValue={""}
              onChange={(e) => handleSelectPatientStatus(e.target.value)}
              inputProps={{
                style: textBoxStyles,
                "aria-label": "Without label",
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {patientStatus &&
                patientStatus?.map((item) => (
                  <MenuItem key={item?.id} value={item?.id}>
                    {item?.patientStatusDescription}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <span style={{ margin: "0 10px" }}>Navigates To</span>

          <FormControl sx={selectStyles}>
            <Select
              name="visitAvailableBusinessRuleId"
              defaultValue={""}
              onChange={(e) => handleSelectVisit(e.target.value)}
              inputProps={{
                style: textBoxStyles,
                "aria-label": "Without label",
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {allVisits &&
                allVisits?.map((item) => (
                  <MenuItem key={item?.id} value={item?.id}>
                    {item?.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <button
            onClick={(e) => {
              removeNavigationRow(e, index);
            }}
            style={{
              cursor: "pointer",
              textAlign: "center",
              background: "red",
              border: "none",
              borderRadius: "5px",
              padding: "10px 10px",
              marginLeft: "10px",
            }}
          >
            <FaTrashAlt
              color="white"
              style={{
                fontSize: "15px",
              }}
            />
          </button>
        </div>
      ))}
    </div>
  );
};

export default DynamicNavigtion;
