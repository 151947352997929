import { studyServer } from "../../../../../services/study_axios";

export const getAllBusinessRulesDataService = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get("/app/BusinessRule/GetAllBusinessRules")
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const createNewBussinessRuleService = (data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post("/app/BusinessRule/AddNewBusinessRule", data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getAllRuleDetailsService = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`/app/BusinessRule/GetEditDataByRuleID?RuleId=${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getTypeFieldsDropDown = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get("/app/BusinessRule/GetAllBusinessRuleFieldTypes")
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getTypeComparisonService = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get("/app/BusinessRule/GetAllBusinessRuleOperatorTypes")
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const addNewBusinessRuleDetailsService = (data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post("/app/BusinessRule/AddBusinessRuleDetail", data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const deleteBusinessRuleDetail = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`/app/BusinessRule/DeleteBusinessRuleDetailById?DetailId=${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getFieldValueService = (
  businessRuleId,
  businessRuleFieldTypeId,
  fieldLookup
) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(
        `/app/BusinessRule/FindRuleValue?BusinessRuleId=${businessRuleId}&BusinessRuleFieldTypeId=${businessRuleFieldTypeId}&FieldLookup=${fieldLookup}`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getQuestionsDataService = (questionnaireId, choicesOnly) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(
        `/app/BusinessRule/GetQuestions?QuestionnaireId=${questionnaireId}&ChoicesOnly=${choicesOnly}`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getChoicedDataService = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`/app/BusinessRule/GetChoices?QuestionId=${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
