import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { Box } from "@mui/system";
import { ThemeProvider } from "@mui/material/styles";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  Alert,
  Button,
  FormControl,
  MenuItem,
  Popover,
  Select,
  TextField,
} from "@mui/material";
import { FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import { useCallbackPrompt } from "../../../../../hooks/useCallbackPrompt";
import DialogBox from "../../../../../components/DialogBox";
import {
  getMuiTheme,
  selectStyles,
  StripedDataGrid,
  textBoxStyles,
} from "../../Visits/ConfigureNavigations";
import DeleteModal from "../../../../../components/DeleteModal";
import {
  addNewBusinessRuleDetailsService,
  deleteBusinessRuleDetail,
  getAllRuleDetailsService,
  getFieldValueService,
  getTypeComparisonService,
  getTypeFieldsDropDown,
} from "./BusinessRules";
import { requestFailed } from "../../Visits/CreateVisit";
import FieldRuleComp from "./FieldRuleComp";

const EditBusinessRules = () => {
  const location = useLocation();
  const ruleId = location?.state?.id;

  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(5);
  const [ruleDetails, setRuleDetails] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [typeFieldData, setTypeFieldData] = useState([]);
  const [selectedTypeField, setSelectedTypeField] = useState(null);
  const [selectedField, setSelectedField] = useState("");
  const [comparisonData, setComparisonData] = useState([]);
  const [detailsData, setDetailsData] = useState({
    name: "",
    description: "",
  });
  const [nestLoading, setNestLoading] = useState(false);
  const [ruleData, setRuleData] = useState([]);
  const [fieldValue, setFieldValue] = useState(null);

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    getFieldRuleValue();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const createBusinessDetailSchema = yup.object().shape({
    typeField: yup.string().required("This field is required"),
    typeComparison: yup.string().required("This field is required"),
    includeTrueValue: yup.bool().oneOf([true, false]),
    comparisonValue: yup.string(),
    comparisonResultValue: yup.string(),
  });

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createBusinessDetailSchema),
  });

  const notify = () =>
    toast.success("Business Detail Added Successfully", {
      toastId: "form-creation",
    });

  const deleteNotify = () =>
    toast.success("Business Rule Detail Deleted Successfully", {
      toastId: "form-creation",
    });

  useEffect(() => {
    if (ruleId) {
      getAllRuleDetails(ruleId);
    }
  }, [ruleId]);

  useEffect(() => {
    getTypeFieldData();
    getComparisonData();
  }, []);

  useEffect(() => {
    if (selectedTypeField && typeFieldData && typeFieldData?.length > 0) {
      let item = typeFieldData?.find((item) => item?.id === selectedTypeField);
      setSelectedField(item);
    }
  }, [selectedTypeField]);

  useEffect(()=>{
    if(selectedTypeField){
      setFieldValue(null)
    }
  },[selectedTypeField])

  const columns = [
    {
      field: "id",
      hide: true,
      headerName: "ID",
      width: 0,
      renderHeader: () => <div className="grid-heading-id">{"ID"}</div>,
      renderCell: (params) => {
        const id = params.row.id;

        return (
          <div className="grid-id">
            <p>{id}</p>
          </div>
        );
      },
    },
    {
      field: "type",
      headerName: "Type Of Rule",
      width: 180,
      renderHeader: () => <div className="grid-heading">{"Type Of Rule"}</div>,
      renderCell: (params) => {
        const type = params.row.businessRuleFieldType?.name;

        return (
          <div className="grid-body">
            <p>{type}</p>
          </div>
        );
      },
    },
    {
      field: "leftValue",
      headerName: "Left Value",
      width: 180,
      renderHeader: () => <div className="grid-heading">{"Left Value"}</div>,
      renderCell: (params) => {
        const leftValue = params?.row?.businessRuleFieldValueDisplay;

        return (
          <div className="grid-body">
            <p>{leftValue || ""}</p>
          </div>
        );
      },
    },
    {
      field: "operator",
      headerName: "Operator",
      width: 180,
      renderHeader: () => <div className="grid-heading">{"Operator"}</div>,
      renderCell: (params) => {
        const operator = params.row.businessRuleOperatorType?.name;

        return (
          <div className="grid-body">
            <p>{operator || ""}</p>
          </div>
        );
      },
    },
    {
      field: "rightValue",
      headerName: "Right Value",
      width: 200,
      renderHeader: () => <div className="grid-heading">{"Right Value"}</div>,
      renderCell: (params) => {
        const rightValue = params.row.businessRuleValueDisplay;

        return (
          <div className="grid-body">
            <p>{rightValue || ""}</p>
          </div>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      renderHeader: () => <div className="grid-heading">{"Delete"}</div>,
      width: 120,
      renderCell: (params) => {
        const id = params.row.id;

        return (
          <button
            onClick={() => {
              setDeleteId(id);
              setShowConfirmModal(true);
            }}
            style={{
              cursor: "pointer",
              textAlign: "center",
              background: "red",
              border: "none",
              borderRadius: "5px",
              padding: "10px 10px",
            }}
          >
            <FaTrashAlt
              color="white"
              style={{
                fontSize: "15px",
              }}
            />
          </button>
        );
      },
    },
  ];

  const getAllRuleDetails = async (id) => {
    setLoading(true);
    try {
      const res = await getAllRuleDetailsService(id);
      if (res?.status) {
        setLoading(false);
        setRuleDetails(res?.data?.result?.detailsData);
        setDetailsData({
          description: res?.data?.result?.businessRuleData?.description,
          name: res?.data?.result?.businessRuleData?.name,
        });
      }
    } catch (err) {
      setLoading(false);
      console.log("Error :", err?.message);
    }
  };

  const getTypeFieldData = async () => {
    setLoading(true);
    try {
      const res = await getTypeFieldsDropDown();
      if (res?.status) {
        setLoading(false);
        setTypeFieldData(res?.data?.result);
        setSelectedTypeField(res?.data?.result[0]?.id);
        setValue("typeField", res?.data?.result[0]?.id);
      }
    } catch (err) {
      setLoading(false);
      console.log("Error: ", err?.message);
    }
  };

  const getComparisonData = async () => {
    setLoading(true);
    try {
      const res = await getTypeComparisonService();
      if (res?.status) {
        setLoading(false);
        setComparisonData(res?.data?.result);
      }
    } catch (err) {
      setLoading(false);
      console.log("Error :", err?.message);
    }
  };

  const getFieldRuleValue = async () => {
    setNestLoading(true);
    try {
      const res = await getFieldValueService(
        ruleId,
        selectedTypeField,
        selectedField?.allowFieldLookup
      );
      if (res?.status) {
        setNestLoading(false);
        setRuleData(res?.data?.result);
      }
    } catch (err) {
      setNestLoading(false);
      console.log("Error :", err?.message);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const payload = {
        businessRuleId: ruleId,
        businessRuleFieldTypeId: data?.typeField,
        businessRuleFieldValue: "",
        businessRuleOperatorTypeId: data?.typeComparison,
        businessRuleValue: data?.comparisonValue || "",
        businessRuleFieldValueAdd: data?.comparisonResultValue || 0,
        businessRuleValueAdd: 0,
      };
      const res = await addNewBusinessRuleDetailsService(payload);
      if (res?.status) {
        setLoading(false);
        notify();
        reset();
        getAllRuleDetails(ruleId);
      }
    } catch (err) {
      setLoading(false);
      requestFailed(err?.message);
      reset();
      console.log("Error: ", err?.message);
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      setLoading(true);
      const res = await deleteBusinessRuleDetail(deleteId);

      if (res.status) {
        setLoading(false);
        setShowConfirmModal(false);
        getAllRuleDetails(ruleId);
        deleteNotify();
      }
    } catch (err) {
      console.log("Error: ", err.message);
      setLoading(false);
      setShowConfirmModal(false);
      requestFailed(err.message);
    }
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          <BeatLoader color="#3661eb" />
        </div>
      ) : (
        <div className="content-body">
          <p className="study-management-link" style={{ fontWeight: "600" }}>
            <Link to="/study-management">Manage Your Study</Link> |{" "}
            <Link to="/study-management/business-rules">
              Manage Business Rules
            </Link>{" "}
            |
            <Link to="/study-management/edit-business-rules">
              Edit Business Rules Detail
            </Link>
            -{" "}
            <b style={{ color: "gray" }}>
              {sessionStorage?.getItem("studyName")}
            </b>
          </p>
          <Row style={{ marginTop: "2%", marginBottom: "2%" }}>
            <Col md={12}>
              <div className="study-management-head-start">
                <p className="study-management-heading">{detailsData?.name}</p>
                <Alert
                  icon={false}
                  severity="success"
                  sx={{ width: "100%", minHeight: "50px", marginTop: "5px" }}
                >
                  <h5>{detailsData?.description}</h5>
                </Alert>
              </div>
            </Col>
          </Row>
          <div>
            <DialogBox
              showDialog={showPrompt}
              confirmNavigation={confirmNavigation}
              cancelNavigation={cancelNavigation}
            />
          </div>

          <Row>
            <Col md={8} sm={12}>
              <Box sx={{ height: 400, width: "100%", display: "flex" }}>
                <ThemeProvider theme={getMuiTheme}>
                  <StripedDataGrid
                    className="allQuestionnaire-grid"
                    rows={ruleDetails}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20]}
                    // components={{ Toolbar: GridToolbar }}
                    pagination
                    rowHeight={38}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                    getRowClassName={(params) =>
                      params.indexRelativeToCurrentPage % 2 === 0
                        ? "even"
                        : "odd"
                    }
                  />
                </ThemeProvider>
              </Box>
            </Col>
            <Col md={4} sm={12}>
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
                sx={{
                  border: "1px solid rgb(224 224 224)",
                  borderRadius: "5px",
                  padding: "20px",
                  minheight: "500px",
                  maxHeight: "850px",
                }}
              >
                <h4>Add a New Rule Detail</h4>
                <div style={{ marginTop: "30px" }}>
                  <h5 style={{ fontWeight: "bold" }}>
                    Type of Field
                    <span className="error-highlight">*</span>
                  </h5>
                  <FormControl sx={selectStyles}>
                    <Select
                      name="typeField"
                      value={selectedTypeField}
                      defaultValue={""}
                      inputProps={{
                        style: textBoxStyles,
                        "aria-label": "Without label",
                      }}
                      {...register("typeField", {
                        onChange: (e) => {
                          setValue("typeField", e.target.value, {
                            shouldValidate: true,
                          });
                          setShowDialog(true);
                          setSelectedTypeField(e.target.value);
                        },
                      })}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {typeFieldData &&
                        typeFieldData?.map((item) => (
                          <MenuItem key={item?.id} value={item?.id}>
                            {item?.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                {errors.typeField && (
                  <>
                    <div style={{ marginTop: "30px" }}>
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errors.typeField.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}

                {selectedTypeField && (
                  <Alert
                    icon={false}
                    severity="info"
                    sx={{ width: "90%", minHeight: "50px", marginTop: "20px" }}
                  >
                    <h5>{selectedField?.description}</h5>
                  </Alert>
                )}

                <div style={{ marginTop: "30px" }}>
                  <h5 style={{ fontWeight: "bold" }}>
                    Type of Comparison
                    <span className="error-highlight">*</span>
                  </h5>
                  <FormControl sx={selectStyles}>
                    <Select
                      name="typeComparison"
                      defaultValue={""}
                      inputProps={{
                        style: textBoxStyles,
                        "aria-label": "Without label",
                      }}
                      {...register("typeComparison", {
                        onChange: (e) => {
                          setValue("typeComparison", e.target.value, {
                            shouldValidate: true,
                          });
                          setShowDialog(true);
                        },
                      })}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {comparisonData &&
                        comparisonData
                          .filter(
                            (item) =>
                              !selectedField?.equalOnly ||
                              item?.name === "Equals"
                          )
                          .map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </div>
                {errors.typeComparison && (
                  <>
                    <div style={{ marginTop: "30px" }}>
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errors.typeComparison.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                {selectedField && selectedField?.allowValueLookup && (
                  <div style={{ marginTop: "30px" }}>
                    <h5 style={{ fontWeight: "bold" }}>Value of Field</h5>
                    <div>
                      <Button
                        aria-describedby={id}
                        variant="outlined"
                        color="info"
                        size="large"
                        sx={{ textTransform: "none", fontSize: "12px" }}
                        onClick={(e) => handleClick(e)}
                      >
                        Get Field Value
                      </Button>
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <div style={{ width: "400px", minHeight: "100px" }}>
                          {nestLoading ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "30px",
                              }}
                            >
                              <BeatLoader color="#3661eb" />
                            </div>
                          ) : (
                            <>
                              <FieldRuleComp
                                data={ruleData}
                                modelName={
                                  ruleData?.businessRuleFieldType?.name
                                }
                                setLoading={setNestLoading}
                                setFieldValue={setFieldValue}
                                setAnchorEl={setAnchorEl}
                              />
                            </>
                          )}
                        </div>
                      </Popover>
                    </div>
                  </div>
                )}

                {fieldValue && (
                  <div>
                    <h5 style={{ fontWeight: "bold" }}>{fieldValue}</h5>
                  </div>
                )}

                {selectStyles && selectedField?.allowValueAdd && (
                  <div style={{ marginTop: "20px" }}>
                    <h5 style={{ fontWeight: "bold" }}>
                      Add to the value (this value will be added to the above
                      result, negative numbers are allowed)
                    </h5>
                    <FormControl sx={selectStyles}>
                      <TextField
                        type={"text"}
                        inputProps={{
                          style: textBoxStyles,
                        }}
                        {...register("comparisonResultValue", {
                          onChange: (e) => {
                            setShowDialog(true);
                          },
                        })}
                      />
                    </FormControl>
                  </div>
                )}
                {selectedField && selectedField?.allowValueLookup && (
                  <div style={{ marginTop: "30px" }}>
                    <h5 style={{ fontWeight: "bold" }}>Comparison Value</h5>
                    <FormControl sx={selectStyles}>
                      <TextField
                        type={"text"}
                        inputProps={{
                          style: textBoxStyles,
                        }}
                        {...register("comparisonValue", {
                          onChange: (e) => {
                            setShowDialog(true);
                          },
                        })}
                      />
                    </FormControl>
                  </div>
                )}

                <div style={{ marginTop: "30px", textAlign: "center" }}>
                  <button
                    type="submit"
                    style={{
                      cursor: "pointer",
                      textAlign: "center",
                      color: "white",
                      backgroundColor: "#337ab7",
                      padding: "8px 17px",
                      fontSize: "15px",
                      border: "none",
                      borderRadius: "5px",
                    }}
                  >
                    Add New Detail
                  </button>
                </div>
              </Box>
            </Col>
          </Row>

          <Row style={{ marginTop: "2%" }}>
            <Col md={6}></Col>
            <Col md={6}>
              <div className="study-management-head-end">
                <button
                  className="study-management-create-btn-lg"
                  onClick={() => {
                    navigate("/study-management/business-rules");
                  }}
                >
                  Back
                </button>
              </div>
            </Col>
          </Row>
        </div>
      )}
      {showConfirmModal && (
        <DeleteModal
          open={showConfirmModal}
          handleClose={() => setShowConfirmModal(false)}
          handleDelete={handleDeleteConfirm}
          subject={"Business Rule Detail"}
        />
      )}
    </>
  );
};

export default EditBusinessRules;
