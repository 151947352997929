// subjectAttributeManagementReducer.js

export const initialState = {
    subjectAttributeData: [],
    createSubjectAttributeData: [],
    createSubjectAttributeDetailData: [],
    businessDetailData: [],
    dataTypeDetailData: [],
    setAttributeDetailsData: [],
    setSubjectConfigurationData: [],
    updateSuccess: null,
    updateError: null,
};

const subjectAttributeManagementReducer = (state, action) => {
    switch (action.type) {

        case "SET_SUBJECTATTRIBUTE_DATA":
            return {
                ...state,
                subjectAttributeData: action.payload,
            };

        case "CREATE_SUBJECT_ATTRIBUTE_DATA":
            return {
                ...state,
                createSubjectAttributeData: action.payload,
            };
        case "SET_BUSINESS_DETAIL_DATA":
            return {
                ...state,
                businessDetailData: action.payload
            }
        case "SET_DATATYPE_DETAIL_DATA":
            return {
                ...state,
                dataTypeDetailData: action.payload
            };
        case "CREATE_SUBJECT_ATTRIBUTE_DETAIL_DATA":
            return {
                ...state,
                createSubjectAttributeDetailData: action.payload,
            }
        case "SET_ATTRIBUTE_DETAILS_DATA":
            return {
                ...state,
                setAttributeDetailsData: action.payload,
            };
        case "SET_SUBJECT_CONFIGURATION_DATA":
            return {
                ...state,
                setSubjectConfigurationData: action.payload,
            };
        case "UPDATE_REQUEST":
            return {
                ...state,
                updateError: null,
            };
        case "UPDATE_SUCCESS":
            return {
                ...state,
                updateSuccess: action.payload,
            };
        case "UPDATE_FAILURE":
            return {
                ...state,
                updateError: action.payload,
            };


        case "DISABLE_LOADING":
            return {
                ...state,
                isLoading: false,
            };

        default:
            return state;
    }
};

export default subjectAttributeManagementReducer;
