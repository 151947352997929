import { dataServer } from "./axios.config";

export const getSystemLanguages = (pageNumber, pageSize, sortingType, sortingField, debouncedSearchString, languageFilters) => {
  return new Promise((resolve, reject) => {
    const filterItems = (Array.isArray(languageFilters) ? languageFilters : []).map((filter, index) => ({
      Id: index + 1,
      columnField: filter.columnField,
      operatorValue: filter.operatorValue,
      value: filter.value,
    }));
    const filterPayload = encodeURIComponent(JSON.stringify({
      items: filterItems,
      linkOperator: "or",
      quickFilterLogicOperator: "or",
    }));

    const sortingPayload = encodeURIComponent(JSON.stringify([{
      Field: sortingField,
      sort: sortingType
    }]));
    const url = `/app/SystemLanguage?pageNumber=${pageNumber}&pageSize=${pageSize}&sorting=${sortingPayload}&filter=${filterPayload}`;
    dataServer
      .get(url)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.error("API Error:", err);
        reject(err);
      });
  });
};

export const createSystemLanaguage = (data) => {
  return new Promise((resolve, reject) => {
    dataServer
      .post("/app/SystemLanguage", data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getSystemLanguageById = (id) => {
  return new Promise((resolve, reject) => {
    dataServer
      .get(`/app/SystemLanguage/${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const editSystemLanguage = (id, data) => {
  return new Promise((resolve, reject) => {
    dataServer
      .put(`/app/SystemLanguage/${id}`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const deleteSystemLanguage = (id) => {
  return new Promise((resolve, reject) => {
    dataServer
      .delete(`/SystemLanguage/${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
