import React from "react";
import { FaCamera, FaTimesCircle } from "react-icons/fa";
import { Button, Box, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// import "../Questionnaire/Questionnaire.css";

const Camera = React.forwardRef((props, ref) => {
  const {
    name,
    defaultValue,
    disabled,
    border,
    borderTop,
    borderBottom,
    borderSides,
    currentPage,
    label,
  } = props;

  const [selectedFile, setSelectedFile] = React.useState(null);
  const [imgPreview, setImgPreview] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [titleName, setTitleName] = React.useState("");
  const [showTitle, setShowTitle] = React.useState(false);

  const removeImage = () => {
    setImgPreview(null);
    setSelectedFile(null);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleShowTitle = () => {
    if (titleName === "") {
      setShowTitle(false);
    } else {
      setShowTitle(true);
    }
  };

  const handleTitleName = (event) => {
    event.preventDefault();
    setTitleName(event.target.value);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none !important",
    boxShadow: 24,
    borderRadius: "3px",
    p: 3,
  };

  return (
    <>
      <div
        className={`${border ? "borderFullRadius " : ""} ${
          borderTop ? "borderTop " : ""
        } ${borderSides ? "borderSides " : ""} ${
          borderBottom ? "borderBottom " : ""
        } flexCenter`}
      >
        {currentPage && (
          <div className="currentPage">
            {/* Page <p>{currentPage}</p> */}
            {!showTitle ? (
              <>
                <Modal
                  open={openModal}
                  onClose={handleCloseModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={modalStyle}>
                    <Row>
                      <Col md={12}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: "2%",
                          }}
                        >
                          <h4>Enter Page Title</h4>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <TextField
                            className="muiTextInput"
                            id="outlined-basic"
                            onChange={handleTitleName}
                            placeholder="Page Title"
                            variant="outlined"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div
                          style={{
                            marginTop: "4%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <button
                            onClick={handleShowTitle}
                            className="saveTitleBtn"
                          >
                            Save
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Box>
                </Modal>
                {/* <button onClick={handleOpenModal} className="addTitleBtn">
                  Add Title
                </button> */}
                {/* <div className="addTitleBtn">Container Title</div> */}
              </>
            ) : (
              <p>{titleName}</p>
            )}
          </div>
        )}
        <div className={`${disabled ? "labelDisabled" : "labelContainer"}`}>
          <p>{label}</p>
        </div>
        <div className="ageRangeInputBody">
          <div>
            {imgPreview === null ? (
              <>
                <Button
                  variant="outlined"
                  component="label"
                  className={`${
                    disabled
                      ? "questionnaire-btn-disabled"
                      : "questionnaire-btn"
                  }`}
                  // className="questionnaire-btn"
                  id="disable-input-preview"
                  disabled={disabled}
                >
                  <input
                    ref={ref}
                    name={name}
                    defaultValue={defaultValue}
                    disabled={disabled}
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={(event) => {
                      var file = event.target.files[0];
                      const reader = new FileReader();
                      var url = reader.readAsDataURL(file);
                      // setFieldValue("file", event.target.files[0]);
                      reader.onloadend = function (e) {
                        setImgPreview([reader.result]);
                      };
                      setSelectedFile(file);
                    }}
                  />
                  <FaCamera
                    size={"20px"}
                    style={
                      disabled
                        ? {
                            width: "80px !important",
                            textAlign: "center",
                            color: "gray",
                          }
                        : { width: "80px !important", textAlign: "center" }
                    }
                  />
                </Button>
              </>
            ) : (
              <>
                <div className="uploadAfterBody">
                  <button
                    style={{
                      position: "absolute",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => removeImage()}
                  >
                    <FaTimesCircle
                      style={{
                        fontSize: "20px",
                        color: "red !important",
                        marginLeft: "162px",
                        marginBottom: "300px",
                        color: "red",
                      }}
                    />
                  </button>
                  <img src={imgPreview} className="uploadImgAfter" />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
});

export default Camera;
