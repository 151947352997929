import axios from "axios";

const ruleServer = axios.create({
  baseURL: process.env.REACT_APP_BACKEND,
  timeout: 100000,
  // headers: { Accept: 'application/json', 'Content-Type': 'application/json' }
});

export const getBusinessRules = () => {
  return new Promise((resolve, reject) => {
    ruleServer
      .get("/study/BusinessRules")
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
