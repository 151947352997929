import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import DialogBox from "../../../../../components/DialogBox";
import { useCallbackPrompt } from "../../../../../hooks/useCallbackPrompt";
import { Box } from "@mui/system";
import { Tab, Tabs, TextField, Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { getAllTabDataService, updateValueService } from "./StudySetupServices";
import { toast } from "react-toastify";

const StudySetup = () => {
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }
  const loadContainerStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70vh",
  };
  const textBoxStyles = {
    fontSize: 15,
    width: "210px",
    height: "5px",
  };
  const notifyUpdateStatus = () =>
    toast.success("Updated Detail Successfully", {
      // theme: "colored",
      toastId: "form-creation",
    });

  const requestFailed = (msg) =>
    toast.error(`${msg}`, {
      // theme: "colored",
      toastId: "requestFailed",
    });

  const [allTabData, setAllTabData] = useState([]);
  const [value, setTabValue] = useState(0);
  const [load, setLoad] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);
  const keys = Object.keys(allTabData);
  const selectedKey = keys[value];
  const [focusedField, setFocusedField] = useState({ tabKey: "", index: null });
  const inputRefs = useRef({});
  const cursorPositionRef = useRef(null);

  useEffect(() => {
    getAllTabData();
  }, []);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getAllTabData = async () => {
    setLoad(true);
    try {
      const res = await getAllTabDataService();
      setAllTabData(res?.data?.result);
      setLoad(false);
    } catch (err) {
      console.log("Error: ", err.message);
      setLoad(false);
    }
  };

  const handleInputChange = (e, tabKey, idx) => {
    const { value } = e.target;

    // Set the cursor position before updating state
    const inputRef = inputRefs.current[`${tabKey}-${idx}`];
    const cursorPosition = inputRef?.selectionStart;
    cursorPositionRef.current = cursorPosition;

    // Set the currently focused field
    setFocusedField({ tabKey, index: idx });

    // Update the state
    setAllTabData((prevTabData) => {
      const updatedTabData = { ...prevTabData };
      updatedTabData[tabKey] = updatedTabData[tabKey].map((item, itemIdx) =>
        itemIdx === idx ? { ...item, value } : item
      );
      return updatedTabData;
    });
  };

  useEffect(() => {
    if (
      focusedField.tabKey &&
      focusedField.index !== null &&
      cursorPositionRef.current !== null
    ) {
      const inputRef =
        inputRefs.current[`${focusedField.tabKey}-${focusedField.index}`];
      if (inputRef) {
        inputRef.setSelectionRange(
          cursorPositionRef.current,
          cursorPositionRef.current
        );
      }
    }
  });

  const handleUpdate = async (e, tabKey, key, idx) => {
    e.preventDefault();
    setLoad(true);
    const updatedValue = allTabData[tabKey][idx]?.value;
    console.log(
      `Updated value for ${tabKey} at index ${idx}:`,
      updatedValue,
      key
    );
    try {
      const res = await updateValueService(key, updatedValue);
      if (res?.status) {
        getAllTabData();
        setLoad(false);
        notifyUpdateStatus();
      }
    } catch (err) {
      console.log("Error: ", err.message);
      setLoad(false);
      requestFailed(err.message);
    }
  };

  return (
    <>
      {load ? (
        <>
          <div style={loadContainerStyles}>
            <BeatLoader color="#3661eb" />
          </div>
        </>
      ) : (
        <div className="content-body">
          <p className="study-management-link" style={{ fontWeight: "600" }}>
            <Link to="/study-management">Manage Your Study</Link> |{" "}
            <Link to="/study-management/study-settings">Study Setup</Link>-{" "}
            <b style={{ color: "gray" }}>
              {sessionStorage?.getItem("studyName")}
            </b>
          </p>

          <div>
            <DialogBox
              showDialog={showPrompt}
              confirmNavigation={confirmNavigation}
              cancelNavigation={cancelNavigation}
            />
          </div>
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              style={{
                width: "fit-content",
              }}
            >
              {keys &&
                keys?.map((item, index) => (
                  <Tab label={item} {...a11yProps(index)} />
                ))}
            </Tabs>
            {keys &&
              keys?.map((key, index) => (
                <TabPanel key={key} value={value} index={index}>
                  <Box
                    component="form"
                    // onSubmit={handleSubmit(onSubmit)}
                    sx={{ height: "auto", width: "100%" }}
                    autoComplete="off"
                  >
                    <div className="subjectConfiguration">
                      <div className="subjectVariableHead">
                        <h1>{key}</h1>
                      </div>
                      <Row>
                        {allTabData &&
                          allTabData[key]?.map((alert, idx) => (
                            <Col md={6} key={idx}>
                              <div className="subjectVariableBody">
                                <div className="subjectVariableBodyInfo">
                                  <p className="smallHeading">{alert?.key} </p>
                                </div>
                                <p className="subjectDate">
                                  Last Updated:{" "}
                                  <b>
                                    {new Date(
                                      alert.lastUpdate
                                    ).toLocaleString()}
                                  </b>
                                </p>
                                <div className="subjectVariableBody2">
                                  <p>{alert?.notes}</p>
                                </div>
                                <div className="subjectVariableInputBody">
                                  <TextField
                                    value={alert?.value || ""}
                                    onChange={(e) =>
                                      handleInputChange(e, key, idx)
                                    }
                                    inputProps={{
                                      style: textBoxStyles,
                                    }}
                                    inputRef={(el) =>
                                      (inputRefs.current[`${key}-${idx}`] = el)
                                    }
                                    autoFocus={
                                      focusedField.tabKey === key &&
                                      focusedField.index === idx
                                    }
                                  />
                                  <button
                                    className="updateBlueBtn"
                                    onClick={(e) =>
                                      handleUpdate(e, key, alert.key, idx)
                                    }
                                  >
                                    Update
                                  </button>
                                </div>
                              </div>
                              <div className="customDivider"></div>
                            </Col>
                          ))}
                      </Row>
                    </div>
                  </Box>
                </TabPanel>
              ))}
          </Box>
          <Row style={{ marginTop: "2%" }}>
            <Col md={6}></Col>
            <Col md={6}>
              <Link to="/study-management">
                <div className="study-management-head-end">
                  <button className="study-management-create-btn-lg">
                    Back
                  </button>
                </div>
              </Link>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default StudySetup;
