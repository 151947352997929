import React from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
} from "@mui/material";

const DeleteModal = ({ open, handleClose, handleDelete, subject }) => {
  return (
    <div style={{ width: "400px", height: "400px", fontWeight: "600" }}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <h3>{`Delete ${subject}?`}</h3>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <h5>{`Are you sure you want to delete this ${subject}?`}</h5>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleDelete}>
            Yes
          </Button>
          <Button variant="outlined" color="error" onClick={handleClose}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteModal;
