import { studyServer } from "./study_axios";

export const getAllVisits = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get("/app/Visit/GetVisitList")
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const createNewVisit = (data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post("app/Visit/PostVisit", data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getVisitById = (visitId) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`app/Visit/EditVisit`, {
        params: {
          visitId: visitId,
        },
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const editVisit = (id, data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .put(`app/Visit/putVisit`, data, {
        params: {
          Id: id,
        },
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getAllBusinessRules = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get("app/PatientAttributeConfiguration/GetBusinessRules")
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getAllResonsService = (selectedValue = 0) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get("app/Visit/GetReasonsOfVisit", {
        params: selectedValue,
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getPatientStatusTypesService = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get("app/Visit/GetPatientStatusTypes")
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const deleteVisit = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post(`app/Visit/DeleteVisitConfirmed?visitId=${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getNavigationByIdService = (visitId) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get("app/Visit/GetVisitNavigationIndexOnload", {
        params: {
          visitId,
        },
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const addNavigationService = (data) => {
  const { visitId, patientStatusTypeId, childVisitId } = data;
  return new Promise((resolve, reject) => {
    studyServer
      .get("app/Visit/AddVisitNavigation", {
        params: {
          visitId: visitId,
          patientStatusTypeId: patientStatusTypeId,
          childVisitId: childVisitId,
        },
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const deleteNavigationService = (data) => {
  const { id, visitId } = data;
  return new Promise((resolve, reject) => {
    studyServer
      .get("app/Visit/RemoveVisitNavigation", {
        params: {
          visitId: visitId,
          id: id,
        },
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getVisitQuestionnaireListService = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`app/VisitQuestionaire/GetListingDataOnLoad?visitId=${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getQuestionnaireDropdownListService = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`app/VisitQuestionaire/CreateOnload?visitId=${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const createNewVisitQuestionnaireService = (data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post(`app/VisitQuestionaire/CreateVisitQuestionnaire`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getVisitQuestionnaireDataById = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`app/VisitQuestionaire/EditOnload?visitQuestionnaireId=${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const editVisitQuestionnaireService = (data, id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post(`app/VisitQuestionaire/EditVisitQuestionnaire?Id=${id}`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const addVisitQuestionnaireCalculationService = (data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post(`app/VisitQuestionaire/AddVisitQuestionnaireCalculation`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const addVisitQuestionnaireStudyRoleService = (data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post(`app/VisitQuestionaire/AddVisitQuestionnaireStudyRole`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const addVisitQuestionnaireRoleService = (data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post(`app/VisitQuestionaire/AddVisitQuestionnaireVisit`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const addReviewCalculationService = (data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post(
        `app/VisitQuestionaire/AddVisitQuestionnaireReviewCalculation`,
        data
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const removeStudyRole = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`app/VisitQuestionaire/RemoveVisitQuestionnaireStudyRole?Id=${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const removeVisitQuestionnaireService = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`app/VisitQuestionaire/RemoveVisitQuestionnaire?Id=${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const updateReviewVisitQuestionnaireService = (data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .put(
        `app/VisitQuestionaire/UpdateReviewQuestionnaireId_In_VisitQuestionnaire`,
        data
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const removeReviewVisitQuestionnaireService = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(
        `app/VisitQuestionaire/RemoveVisitQuestionnaireVisit?VisitQuestionnaireVisitId=${id}`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const removeQuestionnaireCalculationService = (id, data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post(
        `app/VisitQuestionaire/RemoveVisitQuestionnaireCalculation?calculationId=${id}`,
        data
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const removeQuestionnaireCalculationByIdService = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post(
        `app/VisitQuestionaire/RemoveVisitQuestionnaireCalculationById?Id=${id}`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const removeReviewCalculationQuestionnaireService = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(
        `app/VisitQuestionaire/RemoveVisitQuestionnaireReviewCalculation?VisitQuestionnaireReviewCalculationId=${id}`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
