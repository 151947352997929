import { useMutation, useQuery } from "react-query";
import { deleteDrugs, getAllDrugType } from "../../services/drugManagement";
import { mapDrugData } from "../../utils/dataMapping";
import { useDrugManagement } from "../../context/studyManagement/DrugManagement/DrugManagementContext";
import { createDrugType } from "../../services/drug-types-services/drug-types";

const useDrugManagementDetails = () => {
    const { state, dispatch } = useDrugManagement();
    const { pageNumber, pageSize, sortingType, sortingField, filters } = state;
    const fetchDrugLevelData = async () => {
        try {
            const res = await getAllDrugType(pageNumber, pageSize, sortingType, sortingField, filters);
            if (res && res.status === "Success" && Array.isArray(res.result)) {
                const mappedData = mapDrugData(res?.result);
                return { data: mappedData, totalCount: res.totalCount };
            } else {
                console.error("Invalid response structure:", res);
                throw new Error("Invalid response structure");
            }
        } catch (error) {
            console.error("Error fetching dose level data:", error.message);
            throw error;
        }
    };
    const createDrug = async (createDrugData) => {
        try {
            console.log("Creating drug with data:", createDrugData);
            const res = await createDrugType(createDrugData);

            if (res.status === 200 && res.data && res.data.status === "200") {
                return res.data;
            } else {
                console.error("Error creating dose data:", res.data.message);
                throw new Error(res.data.message || "Error creating dose data");
            }
        } catch (error) {
            console.error("Error creating dose data:", error.message);
            throw error;
        }
    };

    const drugQuery = useQuery(
        ["drugData", { pageNumber, pageSize, sortingType, sortingField, filters }],
        fetchDrugLevelData,
        {
            onSuccess: (data) => {
                dispatch({ type: "SET_DRUG_DATA", payload: data.data });
                dispatch({ type: "SET_TOTAL_COUNT", payload: data.totalCount });
            },
            onError: (error) => {
                console.error("Error fetching drug data: ", error.message);
                dispatch({ type: "DISABLE_LOADING" });
            },
            refetchOnWindowFocus: false,
        }
    );
    const createDrugMutation = useMutation(createDrugType, {
        onSuccess: (data) => {
            dispatch({ type: "CREATE_DRUG_DATA", payload: data.data });
        },
        onError: (error) => {
            console.error("Error creating drug:", error.message);
        }
    });

    const deleteDrugMutation = useMutation(deleteDrugs, {
        onSuccess: () => {
            dispatch({ type: "DELETE_DRUG_DATA" });
            drugQuery.refetch();
        },
        onError: (error) => {
            console.error("Error deleting Drug:", error.message);
        }
    });
    return {
        drugQuery,
        createDrugMutation,
        deleteDrugMutation
    };
};

export default useDrugManagementDetails;


