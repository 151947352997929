import {
  Box,
  FormControl,
  MenuItem,
  Select,
  ThemeProvider,
} from "@mui/material";
import React, { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { textBoxStyles } from "../Visits/CreateVisit";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { labelStyles } from "../Visits/Questionnaires/EditVisitQuestionnaire";
import { selectStyles } from "./CreateQuestionnaire";
import { rowStyles } from "../SubjectAttributes/SubjectAttributes";
import { useNavigate } from "react-router";
import {
  addVisitQuestionnaireCalculationService,
  removeQuestionnaireCalculationByIdService,
  removeQuestionnaireCalculationService,
} from "../../../../services/visits";
import { toast } from "react-toastify";
import { getMuiTheme } from "../Visits/Visits";
import { StripedDataGrid } from "../Dispensation/Dispensations";
import { FaTrashAlt } from "react-icons/fa";
import DeleteModal from "../../../../components/DeleteModal";

const QuestionnaireCalculation = ({
  calculationDropdown,
  calcualationListingData,
  setLoad,
  getData,
  questionnaireId,
}) => {
  const [pageSize, setPageSize] = useState(5);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const navigate = useNavigate();

  const notify = (msg) =>
    toast.success(msg, {
      toastId: "form-creation",
    });

  const notifyDelete = (msg) =>
    toast.success(msg, {
      toastId: "form-creation",
    });

  const requestFailed = (msg) =>
    toast.error(`${msg}`, {
      toastId: "requestFailed",
    });

  const createCalculationSchema = yup.object().shape({
    calculation: yup.string().required("This field is required"),
  });

  const {
    register: registerCalculation,
    handleSubmit: handleSubmitCalculation,
    reset: resetCalculation,
    setValue: calculationSetValue,
    formState: { errors: errorsCalculation, isDirty: dirtyCalculation },
  } = useForm({
    resolver: yupResolver(createCalculationSchema),
  });

  const rows =
    calcualationListingData &&
    calcualationListingData.map((row) => ({
      id: row.id,
      name: row?.calculation?.name,
      delete: row.id,
    }));

  const columns = [
    {
      field: "id",
      hide: true,
      headerName: "ID",
      width: 0,
      renderHeader: () => <div className="grid-heading-id">{"ID"}</div>,
      renderCell: (params) => {
        const id = params.row.id;

        return (
          <div className="grid-id">
            <p>{id}</p>
          </div>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 450,
      renderHeader: () => <div className="grid-heading">{"Name"}</div>,
      renderCell: (params) => {
        const name = params.row?.name;
        return (
          <div className="grid-body">
            <p>{name}</p>
          </div>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      renderHeader: () => <div className="grid-heading">{"Delete"}</div>,
      width: 100,
      renderCell: (params) => {
        const id = params.row.delete;

        return (
          <button
            onClick={() => {
              setShowConfirmModal(true);
              setDeleteId(id);
            }}
            style={{
              cursor: "pointer",
              textAlign: "center",
              background: "red",
              border: "none",
              borderRadius: "5px",
              padding: "10px 10px",
            }}
          >
            <FaTrashAlt
              color="white"
              style={{
                fontSize: "15px",
              }}
            />
          </button>
        );
      },
    },
  ];

  const handleCalculation = async (data) => {
    setLoad(true);
    const newData = {
      visitQuestionnaireId: null,
      visitId: null,
      questionnaireId: questionnaireId,
      calculationId: data?.calculation,
    };
    try {
      const res = await addVisitQuestionnaireCalculationService(newData);
      if (res?.status) {
        setLoad(false);
        getData(questionnaireId);
        resetCalculation();
        notify("Added Questionnaire Calculation Successfulluy !");
        navigate("/study-management/questionnaires");
      }
    } catch (err) {
      console.log("Error: ", err?.message);
      requestFailed(err?.message);
      setLoad(false);
    }
  };

  const handleDeleteConfirm = async () => {
    setLoad(true);
    const data = {
      visitQuestionnaireId: null,
      visitId: null,
      questionnaireId: questionnaireId,
      calculationId: deleteId,
    };
    try {
      const res = await removeQuestionnaireCalculationByIdService(deleteId);
      if (res?.status) {
        setLoad(false);
        getData(questionnaireId);
        resetCalculation();
        notifyDelete("Deleted Questionnaire Calculation Successfully !");
        navigate("/study-management/questionnaires");
      }
    } catch (err) {
      setLoad(false);
      console.log("Error: ", err?.message);
      requestFailed(err?.message);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    if (dirtyCalculation) {
      const confirm = window?.confirm(
        "You have unsaved changes,Are you sure want to leave ?"
      );
      if (confirm) {
        navigate("/study-management/questionnaires");
      } else {
        e.preventDefault();
      }
    } else {
      navigate("/study-management/questionnaires");
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmitCalculation(handleCalculation)}>
      <div className="edit-visit-questionnaire-form-body">
        <div style={{ width: "43vw" }}>
          <div className="">
            <p style={labelStyles}>
              Add a calculation
              {/* <span className="error-highlight">*</span> */}
            </p>
            <FormControl sx={selectStyles}>
              <Select
                name="calculation"
                defaultValue={""}
                inputProps={{
                  style: textBoxStyles,
                  "aria-label": "Without label",
                }}
                {...registerCalculation("calculation", {
                  onChange: (e) => {
                    calculationSetValue("calculation", e.target.value, {
                      shouldValidate: true,
                    });
                  },
                })}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {calculationDropdown &&
                  calculationDropdown?.map((item) => (
                    <MenuItem key={item?.id} value={item?.id}>
                      {item?.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          {errorsCalculation.calculation && (
            <>
              <div className="" style={{ marginTop: "30px" }}>
                <div className="createVisitError">
                  <span className="error-text" style={{ color: "red" }}>
                    {errorsCalculation.calculation.message}
                  </span>
                </div>
              </div>
            </>
          )}
          <Row style={rowStyles}>
            <Col md={4}>
              <div className="me-4">
                <button
                  onClick={(e) => {
                    handleCancel(e);
                  }}
                  className="study-management-cancel-btn"
                >
                  Cancel
                </button>
              </div>
            </Col>
            <Col md={4}>
              <div className="">
                <button
                  type="submit"
                  className="study-management-create-btn-md"
                >
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </div>
        {calcualationListingData && calcualationListingData?.length > 0 && (
          <div style={{ width: "40vw" }}>
            <>
              <Box sx={{ height: 320, width: "100%", display: "flex" }}>
                <ThemeProvider theme={getMuiTheme}>
                  <StripedDataGrid
                    className="allQuestionnaire-grid"
                    rows={rows}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20]}
                    pagination
                    rowHeight={38}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                    getRowClassName={(params) =>
                      params.indexRelativeToCurrentPage % 2 === 0
                        ? "even"
                        : "odd"
                    }
                  />
                </ThemeProvider>
              </Box>
              {showConfirmModal && (
                <DeleteModal
                  open={showConfirmModal}
                  handleClose={() => setShowConfirmModal(false)}
                  handleDelete={handleDeleteConfirm}
                  subject={"Questionnaire Calculation"}
                />
              )}
            </>
          </div>
        )}
      </div>
    </Box>
  );
};

export default QuestionnaireCalculation;
