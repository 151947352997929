import React from "react";
import { Box, TextField } from "@mui/material";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Oval } from "react-loader-spinner";
import Modal from "@mui/material/Modal";

// import "../Questionnaire/Questionnaire.css";

const NumberSpinner = React.forwardRef((props, ref) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [titleName, setTitleName] = React.useState("");
  const [showTitle, setShowTitle] = React.useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleShowTitle = () => {
    if (titleName === "") {
      setShowTitle(false);
    } else {
      setShowTitle(true);
    }
  };

  const handleTitleName = (event) => {
    event.preventDefault();
    setTitleName(event.target.value);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none !important",
    boxShadow: 24,
    borderRadius: "3px",
    p: 3,
  };

  const {
    name,
    defaultValue,
    disabled,
    borderTop,
    borderBottom,
    borderSides,
    currentPage,
    label
  } = props;

  return (
    <>
      <div
        className={`${borderTop ? "borderTop " : ""} ${borderSides ? "borderSides " : ""
          } ${borderBottom ? "borderBottom " : ""} flexCenter`}
      >
        {currentPage && (
          <div className="currentPage">
            {/* Page <p>{currentPage}</p> */}
            {!showTitle ? (
              <>
                <Modal
                  open={openModal}
                  onClose={handleCloseModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={modalStyle}>
                    <Row>
                      <Col md={12}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: "2%",
                          }}
                        >
                          <h4>Enter Page Title</h4>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <TextField
                            className="muiTextInput"
                            id="outlined-basic"
                            onChange={handleTitleName}
                            placeholder="Page Title"
                            variant="outlined"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div
                          style={{
                            marginTop: "4%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <button
                            onClick={handleShowTitle}
                            className="saveTitleBtn"
                          >
                            Save
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Box>
                </Modal>
                {/* <button onClick={handleOpenModal} className="addTitleBtn">
                  Add Title
                </button> */}
                {/* <div className="addTitleBtn">
                  Container Title
                </div> */}
              </>
            ) : (
              <p>{titleName}</p>
            )}
          </div>
        )}
        <div className={`${disabled ? "labelDisabled" : "labelContainer"}`}>
          <p>{label}</p>
        </div>
        <div className="ageRangeInputBody">
          <div>
            <Oval
              name={name}
              defaultValue={defaultValue}
              disabled={disabled}
              ref={ref}
              height={80}
              width={80}
              color="#4fa94d"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#4fa94d"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        </div>
      </div>
    </>
  );
});

export default NumberSpinner;
