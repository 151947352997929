import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import axios from "axios";
import HomepageCard from "../../../components/HomepageCard";
import BeatLoader from "react-spinners/BeatLoader"; // Import BeatLoader

import sponsors from "../../../assets/admin/sponsors.png";
import studies from "../../../assets/admin/studies_1.png";
import users from "../../../assets/admin/users_1.png";
import settings from "../../../assets/admin/system_settings.png";
import { cardColors } from "../../../utils";

const Homepage = () => {
  // State to store the counts
  const [counts, setCounts] = useState({
    sponsorsCount: 0,
    studyCount: 0,
    userCount: 0,
    systemSettingsCount: 0,
  });

  // State to track loading status
  const [loading, setLoading] = useState(true);

  // Function to fetch the dashboard counts
  const fetchDashboardCounts = async () => {
    try {
      const response = await axios.get(
        "https://genesisapi.augursapps.com/app/User/GetDashBoardCounts"
      );
      setCounts(response.data);
    } catch (error) {
      console.error("Failed to fetch dashboard counts:", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  useEffect(() => {
    fetchDashboardCounts();
  }, []);

  const cardData = [
    {
      title: "Total Sponsors",
      number: counts.sponsorsCount,
      subtitle: "Sponsors",
      link: "/all-sponsors",
      src: sponsors,
    },
    {
      title: "Total Studies",
      number: counts.studyCount,
      subtitle: "Studies",
      link: "/all-studies",
      src: studies,
    },
    {
      title: "Total Users",
      number: counts.userCount,
      subtitle: "Users",
      link: "/all-users",
      src: users,
    },
    {
      title: "Total System Settings",
      number: counts.systemSettingsCount,
      subtitle: "System Settings",
      link: "/system-settings",
      src: settings,
    },
  ];

  return (
    <div className="content-body">
      <p className="admin-heading">Admin Panel</p>

      <Grid container marginTop={2}>
        {cardData?.map((data, i) => (
          <Grid item md={3} padding={2} key={i}>
            <HomepageCard
              title={data?.title}
              number={data?.number}
              subtitle={data?.subtitle}
              cardColor={cardColors[i]}
              link={data?.link}
              src={data?.src}
              loading={loading} // Pass loading state
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Homepage;
