import React, { createContext, useReducer, useContext } from "react";
import systemCountryReducer, {initialState as countryInitialState} from "./SystemCountryReducer";


const SystemCountryContext = createContext();

export const SystemCountryProvider = ({ children }) => {
  const [state, dispatch] = useReducer(systemCountryReducer, countryInitialState);
  const handlePageChangeCountries = (event, newPageNumber) => {
    dispatch({ type: "SET_PAGE_NUMBER_COUNTRIES", payload: newPageNumber });
  };

  const handlePageSizeChangeCountries = (event) => {
    dispatch({ type: "SET_PAGE_SIZE_COUNTRIES", payload: event.target.value });
    dispatch({ type: "SET_PAGE_NUMBER_COUNTRIES", payload: 1 });
  };

  const handleCountrySearch = (searchValue) => {
   dispatch({type:"SET_SEARCH_STRING_COUNTRIES", payload:searchValue});
   dispatch({ type: "SET_IS_CALLED_FROM_OUTER", payload: !!searchValue }); // Update the flag based on search value
   dispatch({type:"SET_PAGE_NUMBER_COUNTRIES",payload:1});
  };

  const handleSortingChange = (newSortModel) => {
    const { field, sort } = newSortModel[0] || {};
    dispatch({ type: "SET_SORTING_COUNTRIES", payload: { sortingField: field, sortingType: sort } });
    dispatch({ type: "SET_PAGE_NUMBER_COUNTRIES", payload: 1 });
  };

  const handleFilterChange = (newFilters) => {
    dispatch({ type: "SET_FILTERS", payload: newFilters });
    if (newFilters.length === 0) {
      dispatch({ type: "CLEAR_FILTERS" });
    }
  };
  
  return (
    <SystemCountryContext.Provider value={{ state, dispatch, handlePageChangeCountries, handlePageSizeChangeCountries,handleCountrySearch,handleSortingChange,handleFilterChange }}>
      {children}
    </SystemCountryContext.Provider>
  );
};

export const useSystemCountry = () => useContext(SystemCountryContext);

export default SystemCountryContext;
