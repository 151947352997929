import { useQuery } from "react-query";
import { getAllSystemCountries } from "../../services/system_country";
import { useSystemCountry } from "../../context/systemSetting/SystemCountries/SystemCountryContext";

const useSystemCountries = () => {
  const { state, dispatch } = useSystemCountry();
  const { pageNumberCountries, pageSizeCountries, searchStringCountries, sortingField, sortingType, filters, isCalledFromOuter } = state;

  const fetchSystemCountries = async () => {
    const res = await getAllSystemCountries(pageNumberCountries, pageSizeCountries, sortingType, sortingField, searchStringCountries, filters, isCalledFromOuter);

    const trimmedData = res.result.map((row) => ({
      ...row,
      name: row.name.trim(),
      isoId: row.isoId.trim(),
    }));
    return { data: trimmedData, totalCount: res.totalCount }
  }

  const query = useQuery(
    ["systemCountries", { pageNumberCountries, pageSizeCountries, searchStringCountries, sortingField, sortingType, filters, isCalledFromOuter }],
    fetchSystemCountries,
    {
      onSuccess: (data) => {
        dispatch({ type: "SET_SYSTEM_DATA", payload: data.data });
        dispatch({ type: "SET_TOTAL_COUNTRY_COUNT", payload: data.totalCount });
      },
      onError: (error) => {
        console.error("Error fetching system countries: ", error.message);
        dispatch({ type: "DISABLE_LOADING" });
      },
      refetchOnWindowFocus: false,
    }
  );

  return { query, fetchSystemCountries };
};

export default useSystemCountries;
