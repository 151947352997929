import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles for Quill

// Custom toolbar as per your screenshot
const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike"], // toggled buttons
    [{ list: "ordered" }, { list: "bullet" }],
    [{ align: [] }],
    ["link"], // link tool
    ["clean"], // remove formatting button
  ],
};

const formats = [
  "font",
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "list",
  "bullet",
  "align",
  "link",
];

const RichTextEditor = ({ value, onChange }) => {
  return (
    <div>
      <ReactQuill
        value={value || ""}
        onChange={(content, delta, source, editor) => {
          // Get the HTML from the editor
          const html = editor.getHTML(); // Get HTML string
          onChange(html); // Pass the HTML string back to parent
        }}
        modules={modules}
        formats={formats}
        theme="snow" // Snow theme for Quill
        style={{ height: "200px" }}
      />
    </div>
  );
};

export default RichTextEditor;
