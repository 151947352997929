import React, { createContext, useReducer, useContext } from "react";
import drugManagementReducer, { initialState as drugInitialState } from "./DrugManagementReducer";


const DrugManagementContext = createContext();

export const DrugManagementProvider = ({ children }) => {
    const [state, dispatch] = useReducer(drugManagementReducer, drugInitialState);

    const setPageNumber = (pageNumber) => {
        dispatch({ type: "SET_PAGE_NUMBER", payload: pageNumber });
    };

    const setPageSize = (pageSize) => {
        dispatch({ type: "SET_PAGE_SIZE", payload: pageSize });
    };

    const setSorting = (sortingType, sortingField) => {
        dispatch({ type: "SET_SORTING", payload: { sortingType, sortingField } });
    };

    const setFilters = (filters) => {
        dispatch({ type: "SET_FILTERS", payload: filters });
    };
    return (
        <DrugManagementContext.Provider value={{ state, dispatch, setSorting, setFilters, setPageNumber, setPageSize }}>
            {children}
        </DrugManagementContext.Provider>
    );
};

export const useDrugManagement = () => useContext(DrugManagementContext);

export default DrugManagementContext;
