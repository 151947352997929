import React, { memo, useState, useEffect, useCallback } from "react";
import { useMethods } from "react-use";
import { useNavigate } from "react-router-dom";
import { ReactFormGenerator, ElementStore } from "react-form-builder2";
import "react-form-builder2/dist/app.css";
import Modal from "react-modal";
import { toast } from "react-toastify";
import axios from "axios";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import TabletAndroidIcon from "@mui/icons-material/TabletAndroid";

import "./Questionnaire.css";
// import { useSetRule } from "../../hooks/useRules";

/**
 * TODO:
 * Check the previous functionality and enabling the fields
 */

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    border: "none",
    height: "auto",
    marginTop: "10px",
    // zIndex: "100"
    // padding: "5%"
  },
};

Modal.setAppElement("#root");

const SubmitQuestionnaire = (props) => {
  const reducer = (state) => {
    return {
      init(initState) {
        state = initState;
        return state;
      },
      update(property, value) {
        const tempState = state;
        tempState[property] = value;
        return { ...tempState };
      },
      bulkUpdate(data) {
        return { ...state, ...data };
      },
    };
  };

  const initState = {
    totalPages: 0,
    json: [],
    submitFlag: true,
    btnName: "Save & Next",
    page: 0,
  };

  const navigate = useNavigate();
//   const setRule = useSetRule();
  const [desktopSize, setDesktopSize] = useState(true);
  const [tabletSize, setTabletSize] = useState(false);
  const [originalForm, setOriginalForm] = useState([]);
  const [formId, setFormId] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [nextBtnVisible, setNextBtnVisible] = useState(true);
  const [newJsonForm, setNewJsonForm] = useState([]);
  const [state, stateActions] = useMethods(reducer, initState);
  const [formName, setFormName] = useState("");
  const [fieldEnabled, setFieldEnabled] = useState([]);
  const [showPageBreak, setShowPageBreak] = useState([]);
  const [finalPreview, setFinalPreview] = useState([]);
  const [showFinalPreview, setShowFinalPreview] = useState(false);
  const [stateData, setStateData] = useState([]);
  const [incrementDisable, setIncrementDisable] = useState(0);
  const [decrementEnable, setDecrementEnable] = useState(0);
  const [set, setFunction] = useState({});

  useEffect(() => {
    const { key, value } = set;
    if (value != undefined) {
      console.log("formResponse", key, value);
    //   setRule.update("formResponse", key, value);
    }
  }, [set]);

  const formEmpty = () =>
    toast.warn("Form Data Is Emtpy", {
      toastId: "form-empty",
    });

  const notify = () =>
    toast.success("Form Submitted Successfully", {
      toastId: "form-creation",
    });

  const requestFailed = () =>
    toast.error("Something went wrong", {
      toastId: "requestFailed",
    });

  const onSubmit = () => {
    const formSubmission = align();
    props.setShowDialog(false);
    if (formSubmission.length === 0 || !formSubmission) {
      formEmpty();
    } else {
      const config = {
        method: "patch",
        url: `${process.env.REACT_APP_FORM_API}/Form/design-form?id=${formId}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          formJson: [align()],
        },
      };

      axios(config)
        .then(() => {
          notify();
          navigate("/");
        })
        .catch((error) => {
          requestFailed();
          console.log("Form Submission Error ===> ", error);
        });
    }
  };

  const addPreviousState = () => {
    if (newJsonForm.length) {
      let filtered = [];
      let isFirst = true;
      let count = 0;
      let jsonData;

      for (let i = 0; i <= state.page; i++) {
        jsonData = newJsonForm[i];
        let transformedData = jsonData.map((value, index) => {
          if (value?.props?.customPageBreak || value?.pageBreakBefore) {
            isFirst = true;
            return {
              ...value,
              props: {
                ...value?.props,
                hide: true,
              },
            };
          }

          if (value?.id) {
            if (isFirst && jsonData.length === 1) {
              isFirst = false;
              count = count + 1;
              return {
                ...value,
                props: {
                  ...value?.props,
                  // border: true,
                  // disabled: true,
                  currentPage: count,
                },
              };
            } else if (isFirst && jsonData.length > 1) {
              isFirst = false;
              count = count + 1;
              return {
                ...value,
                props: {
                  ...value?.props,
                  // borderTop: true,
                  // disabled: true,
                  currentPage: count,
                },
              };
            } else if (index === jsonData.length - 1) {
              return {
                ...value,
                props: {
                  ...value?.props,
                  // borderBottom: true,
                  // disabled: true,
                },
              };
            } else {
              return {
                ...value,
                props: {
                  ...value?.props,
                  // borderSides: true,
                  // disabled: true,
                },
              };
            }
          }
        });
        filtered = [...filtered, ...transformedData];
      }

      return filtered;
    }
  };

  const enableFunction = () => {
    const tempNames = [];

    if (incrementDisable) {
      newJsonForm[state.page].map((value) => {
        const { field_name } = value;
        tempNames.push(field_name);
      });
      setFieldEnabled([...fieldEnabled, ...tempNames]);
    }
  };

  const disableFunction = () => {
    if (incrementDisable > 0) {
      let isFirst = true;
      let onlyOne;
      if (state.page - 1 === 0) {
        onlyOne = newJsonForm[state.page - 1].length;
      } else {
        onlyOne = newJsonForm[state.page - 1].length - 1;
      }
      const jsonData = newJsonForm[state.page - 1];
      let pageData = jsonData.map((value, index) => {
        if (value?.props?.customPageBreak || value?.pageBreakBefore) {
          isFirst = true;
          return {
            ...value,
            props: {
              ...value?.props,
              hide: true,
            },
          };
        }

        if (value?.id) {
          if (isFirst) {
            isFirst = false;
            if (onlyOne === 1) {
              return {
                ...value,
                props: {
                  ...value?.props,
                  // borderTop: true,
                  // borderBottom: true,
                  disabled: true,
                  currentPage: state.page,
                },
              };
            }
            return {
              ...value,
              props: {
                ...value?.props,
                // borderTop: true,
                disabled: true,
                currentPage: state.page,
              },
            };
          } else if (index === jsonData.length - 1) {
            return {
              ...value,
              props: {
                ...value?.props,
                // borderBottom: true,
                disabled: true,
              },
            };
          } else {
            return {
              ...value,
              props: {
                ...value?.props,
                // borderSides: true,
                disabled: true,
              },
            };
          }
        }
      });

      let test = [...newJsonForm];
      test[state.page - 1] = [...pageData];
      console.log("TEST DISABLED .... ", test);
      setNewJsonForm(test);
    }
  };

  function modifyObjects(array) {
    array.forEach((object) => {
      if (object.element === "FieldSet") {
        // Modify the object by adding a new property
        object.type = "custom";
      }
    });

    // Return the modified array of objects
    return array;
  }

  const splitPages = (data) => {
    setNextBtnVisible(false);

    let newData = data;

    const modifiedArray = modifyObjects(newData);

    const temp = [];

    let pages = 0;
    temp[pages] = [];

    console.log("NEW DATAAAA ... ", modifiedArray);

    for (let i = 0; i < modifiedArray.length; i += 1) {
      if (
        modifiedArray[i]?.pageBreakBefore ||
        modifiedArray[i]?.props?.customPageBreak
      ) {
        setNextBtnVisible(true);
        pages += 1;
        temp[pages] = [];
      }
      temp[pages].push(modifiedArray[i]);
    }
    stateActions.update("totalPages", pages);

    // disableFunction(temp);

    if (pages > 1) {
      stateActions.update("btnName", "Save & Next");
    }

    setNewJsonForm(temp);
  };

  function arraymove(arr, fromIndex, toIndex) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  const align = () => {
    originalForm.forEach((element, i) => {
      if (element.parentId) {
        arraymove(originalForm, i, element.parentIndex + element.col + 1);
      }
    });
    return originalForm;
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    // setRule.resetForm();

    setIsOpen(false);
  }

  const update = (data) => {
    setStateData(data);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSubmit = (data) => {
    // console.log("Data ==> ", data);
  };

  const onNext = () => {
    setIncrementDisable(incrementDisable + 1);
    if (state.page < state.totalPages) {
      stateActions.update("page", state.page + 1);
      if (state.page === state.totalPages - 1) {
        setNextBtnVisible(false);
      }
    }
  };

  const onBack = () => {
    setShowFinalPreview(false);
    setFinalPreview([]);
    setDecrementEnable(decrementEnable + 1);
    stateActions.update("page", state.page - 1);
    setNextBtnVisible(true);
  };

  const onDeskClick = () => {
    setDesktopSize(true);
    setTabletSize(false);
  };

  const onTabClick = () => {
    setTabletSize(true);
    setDesktopSize(false);
  };

  const onFinalPreview = () => {
    setShowFinalPreview(true);
  };

  const optimizeShowFinalPreview = useCallback(() => {
    if (showFinalPreview) {
      // if (state.page) {
      const finalArray = [];
      newJsonForm.map((array) => {
        array.forEach((val) => {
          finalArray.push(val);
        });
      });

      var final = [...finalArray];

      const tempPageBreakName = [];

      final.map((val) => {
        const { field_name, key } = val;
        if (key === "PageBreak") {
          tempPageBreakName.push(field_name);
        }
      });

      setShowPageBreak([...showPageBreak, ...tempPageBreakName]);
      setFinalPreview([...finalPreview, ...finalArray]);
      // }
    }
  }, [showFinalPreview]);

  const updateFinalPreview = useCallback(() => {
    let isFirst = true;
    let count = 0;
    const jsonData = finalPreview;
    let finalData = jsonData.map((value, index) => {
      if (value?.props?.customPageBreak || value?.pageBreakBefore) {
        isFirst = true;
        return {
          ...value,
          props: {
            ...value?.props,
            hide: true,
          },
        };
      }

      if (value?.id) {
        if (isFirst) {
          isFirst = false;
          count = count + 1;
          return {
            ...value,
            props: {
              ...value?.props,
              // borderTop: true,
              disabled: true,
              currentPage: count,
            },
          };
        } else if (index === finalPreview.length - 1) {
          return {
            ...value,
            props: {
              ...value?.props,
              // borderBottom: true,
              disabled: true,
            },
          };
        } else {
          return {
            ...value,
            props: {
              ...value?.props,
              // borderSides: true,
              disabled: true,
            },
          };
        }
      }
    });
    if (finalData.length > 0 && modalIsOpen === true) {
      setFinalPreview(finalData);
    }
  }, [finalPreview]);

  useEffect(() => {
    console.log("FINAL PREVIEW >>>> ", finalPreview);
  }, [finalPreview]);

  useEffect(() => {
    ElementStore.subscribe((state) => update(state.data));
  }, []);

  useEffect(() => {
    disableFunction();
  }, [incrementDisable]);

  useEffect(() => {
    enableFunction();
  }, [decrementEnable]);

  const updateFormData = () => {
    // Set / Reset Initial State, When Form Is Open
    stateActions.init(initState);
    setNextBtnVisible(true);
    setDecrementEnable(0);
    setFinalPreview([]);
    setShowFinalPreview(false);

    // Set Other Form Values / Rules
    setFormName(props.formName);
    splitPages(props.formData);
    // setRule.setForm(props.formData);

    setFormId(props.formId);
    // if (props.formData.length !== 0) {
    //   let newData = [];
    //   props.formData.map((item) => {
    //     item["props"]["set"] = setFunction;
    //     newData.push(item);
    //   });
    //   setOriginalForm(newData);
    // }
  };

  useEffect(() => {
    if (modalIsOpen === true) {
      updateFormData();
    }
  }, [modalIsOpen]);

  useEffect(() => {
    optimizeShowFinalPreview();
  }, [optimizeShowFinalPreview]);

  useEffect(() => {
    updateFinalPreview();
  }, [updateFinalPreview]);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "left" }}>
        <button className="customWhiteFormBtn float-right" onClick={openModal}>
          Preview Form
        </button>
      </div>

      {newJsonForm ? (
        <>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
          >
            <div
              className={
                desktopSize ? "previewContainer" : "previewContainerTab"
              }
            >
              <div className="previewOptions">
                <div className="row d-flex justify-content-center">
                  <div className="col-md-10"></div>
                  <div className="col-md-1">
                    <div className="iconContianer" onClick={onDeskClick}>
                      <p>Desktop</p>
                      <PersonalVideoIcon
                        style={{
                          fontSize: "20px",
                          color: desktopSize ? "#3661eb" : "#000000",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="iconContianer" onClick={onTabClick}>
                      <p>Tablet</p>
                      <TabletAndroidIcon
                        style={{
                          fontSize: "20px",
                          color: desktopSize ? "#000000" : "#3661eb",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <span className="previewDivider"></span>
              <div className="previewNameContianer">
                <div className="row">
                  {/* <div className="col-md-1"></div> */}
                  <div className="col-md-3">
                    <span className="previewNameHeading">{formName}</span>
                    {/* <span className="previewNameHeading">Test Preview</span> */}
                  </div>
                  <div className="col-md-2">
                    <div className="previewPages">(Pages {state.page + 1})</div>
                  </div>
                  <div className="col-md-7">
                    <div className="previewCloseBody">
                      <button className="previewCloseBtn" onClick={handleClose}>
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={desktopSize ? "previewBorder" : "previewBorderTab"}
                style={
                  desktopSize
                    ? {
                        "previewBorder .react-form-builder-form .rfb-item": {
                          width: "auto !important",
                        },
                        ".flexCenter": {
                          width: "100% !important",
                        },
                        ".ageRangeInputBody": {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "row",
                          gap: "5px",
                        },
                      }
                    : {
                        "previewBorderTab .react-form-builder-form .rfb-item": {
                          width: "500px !important",
                        },
                        ".flexCenter": {
                          width: "130% !important",
                        },
                        ".ageRangeInputBody": {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          gap: "5px",
                        },
                      }
                }
              >
                {showFinalPreview && finalPreview ? (
                  <div className="containerCenter">
                    <h3>Confirm Changes</h3>
                    <div className="containerText">
                      <p>
                        After confirmation, changes will be saved and an email
                        will be send to your account.
                      </p>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <ReactFormGenerator
                  className="previewGenerator"
                  // data={addPreviousState()}
                  data={
                    showFinalPreview && finalPreview
                      ? finalPreview
                      : addPreviousState()
                  }
                  onSubmit={handleSubmit}
                  actionName="Set this to change the default submit button text"
                  submitButton={
                    <>
                      <div
                        className={
                          !showFinalPreview
                            ? "previewButtonContainer"
                            : "submitButtonContainer"
                        }
                      >
                        {!showFinalPreview && (
                          <>
                            <button
                              className={
                                state.page > 0
                                  ? "previewBackBtn"
                                  : "previewBackBtnDisabled"
                              }
                              onClick={onBack}
                              disabled={state.page > 0 ? false : true}
                            >
                              Previous
                            </button>
                          </>
                        )}

                        {nextBtnVisible ? (
                          <button
                            className={
                              nextBtnVisible
                                ? "previewNextBtn"
                                : "previewNextDisabled"
                            }
                            onClick={onNext}
                            disabled={nextBtnVisible ? false : true}
                          >
                            Next
                          </button>
                        ) : showFinalPreview && finalPreview ? (
                          <>
                            <div className=""></div>
                            <button
                              className="previewNextBtn"
                              onClick={onSubmit}
                            >
                              Submit
                            </button>
                          </>
                        ) : (
                          <button
                            className="previewNextBtn"
                            onClick={onFinalPreview}
                          >
                            Confirm
                          </button>
                        )}
                      </div>
                    </>
                  }
                  backButton={
                    <a href="/" className="btn btn-default btn-cancel btn-big">
                      Back
                    </a>
                  }
                />
              </div>
            </div>
          </Modal>
        </>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};
export default memo(SubmitQuestionnaire);
