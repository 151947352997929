export const initialState = {
  systemCountries: [],
  totalCountryCount: 0,
  pageNumberCountries: 1,
  pageSizeCountries: 10,
  searchStringCountries: '',
  sortingField: 'name',
  sortingType: '',
  isLoading: true,
  filters: [],
  filterEnabled: false,
  isCalledFromOuter: false, 
};

const systemCountryReducer = (state, action) => {
  switch (action.type) {
    case "SET_SYSTEM_DATA":
      return {
        ...state,
        systemCountries: action.payload,
      };
    case "SET_TOTAL_COUNTRY_COUNT":
      return {
        ...state,
        totalCountryCount: action.payload,
      };
    case "SET_PAGE_NUMBER_COUNTRIES":
      return {
        ...state,
        pageNumberCountries: action.payload,
      };
    case "SET_PAGE_SIZE_COUNTRIES":
      return {
        ...state,
        pageSizeCountries: action.payload,
      };
    case 'SET_SEARCH_STRING_COUNTRIES':
      return {
        ...state,
        searchStringCountries: action.payload,
      };
    case 'SET_SORTING_COUNTRIES':
      return {
        ...state,
        sortingField: action.payload.sortingField,
        sortingType: action.payload.sortingType,
      };
      case "SET_FILTERS":
        console.log("Setting filters in reducer:", action.payload);
      return {
        ...state,
        filters: action.payload,
        filterEnabled: true,
      };
    case "CLEAR_FILTERS":
      return {
        ...state,
        filters: [],
        filterEnabled: false,
      };
      case "SET_IS_CALLED_FROM_OUTER":
        return {
          ...state,
          isCalledFromOuter: action.payload,
        };
    case "DISABLE_LOADING":
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default systemCountryReducer;
