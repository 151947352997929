import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../../context/user/UserContext";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { FaEdit, FaCheck, FaTimes, FaPencilAlt } from "react-icons/fa";
import {
  postExportExcel,
  postExportPDF,
  getDownloadReport,
} from "../../../services/sponsors";
import { saveAs } from "file-saver";
import {
  createTheme,
  ThemeProvider,
  alpha,
  styled,
} from "@mui/material/styles";
import Box from "@mui/material/Box";
import {
  DataGridPro,
  gridClasses,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MenuItem from "@mui/material/MenuItem";
import {
  getAllExportCountry,
  getAllExportLanguage,
  getAllExportRegion,
  getAllExportRole,
  getAllSystemCountries,
} from "../../../services/system_country";
import { getSystemLanguages } from "../../../services/system_language";
import { getSystemRegion } from "../../../services/system_region";
import { getSystemRoles } from "../../../services/system_roles";
import "../Users/User.css";
import { CustomPagination } from "../../../components/CustomPagination";
import { Chip, InputBase, Paper } from "@mui/material";
import { Iso, SearchSharp } from "@mui/icons-material";
import {
  mapCountryData,
  mapLanguageData,
  mapRegionData,
  mapRolesData,
} from "../../../utils/dataMapping";
import useSystemCountries from "../../../hooks/Api/useSystemCountries";
import { useSystemCountry } from "../../../context/systemSetting/SystemCountries/SystemCountryContext";

const getMuiTheme = createTheme({
  typography: {
    fontSize: 25,
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        cell: {
          "&:focus": {
            outline: "none",
          },
        },
      },
    },
  },
});

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.background.default,
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    ffontWeight: "bold",
    fontSize: 18,
    color: "#000",
    backgroundColor: theme.palette.grey[300],
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SystemSettings = () => {
  const { value, setValue } = useContext(UserContext);
  const [systemLoad, setSystemLoad] = React.useState(true);
  const [systemCountry, setSystemCountry] = React.useState([]);
  // const [systemCountries, setSystemCountries] = React.useState([]);
  const [systemLanguages, setSystemLanguages] = React.useState([]);
  const [regionsData, setRegionsData] = React.useState([]);
  const [rolesData, setRolesData] = React.useState([]);

  // const [totalCountCountries, setTotalCountCountries] = useState(0);
  const [totalCountLanguages, setTotalCountLanguages] = useState(0);
  const [totalCountRegions, setTotalCountRegions] = useState(0);
  const [totalCountRoles, setTotalCountRoles] = useState(0);

  // const [pageNumberCountries, setPageNumberCountries] = useState(1);
  // const [pageSizeCountries, setPageSizeCountries] = useState(10);
  const [pageNumberLanguages, setPageNumberLanguages] = useState(1);
  const [pageSizeLanguages, setPageSizeLanguages] = useState(10);
  const [pageNumberRegions, setPageNumberRegions] = useState(1);
  const [pageSizeRegions, setPageSizeRegions] = useState(10);
  const [pageNumberRoles, setPageNumberRoles] = useState(1);
  const [pageSizeRoles, setPageSizeRoles] = useState(10);

  // const [sortingField, setSortingField] = useState();
  // const [sortingType, setSortingType] = useState("asc");

  const [roleSortingField, setRoleSortingField] = useState();
  const [roleSortingType, setRoleSortingType] = useState("asc");

  const [regionSortingField, setRegionSortingField] = useState(null);
  const [regionSortingType, setRegionSortingType] = useState(null);

  // const [ setFilters] = useState([]);
  const [roleSearchFilter, setRoleSearchFilter] = useState([])
  const [languageSearchFilter, setLanguageSearchFilter] = useState([])
  const [regionSearchFilter, setRegionSearchFilter] = useState([])

  const [languageFilters, setLanguageFilters] = useState([]);
  const [regionFilters, setRegionFilters] = useState([]);
  const [roleFilters, setRoleFilters] = useState([]);
  const [systemCountryFilter, setSystemCountryFilter] = useState({});

  const [searchStringSystemCountries, setSearchStringSystemCountries] =
    useState("");
  const [
    debouncedSearchStringSystemCountries,
    setDebouncedSearchStringSystemCountries,
  ] = useState("");
  const [searchStringCountries, setSearchStringCountries] = useState("");
  const [debouncedSearchStringCountries, setDebouncedSearchStringCountries] =
    useState("");
  const [searchStringLanguages, setSearchStringLanguages] = useState("");
  const [debouncedSearchStringLanguages, setDebouncedSearchStringLanguages] =
    useState("");
  const [searchStringRegions, setSearchStringRegions] = useState("");
  const [debouncedSearchStringRegions, setDebouncedSearchStringRegions] =
    useState("");
  const [searchStringRoles, setSearchStringRoles] = useState("");
  const [debouncedSearchStringRoles, setDebouncedSearchStringRoles] =
    useState("");
  const [rolesColumnVisibility, setRolesColumnVisibility] = useState({
    id: false,
    name: true,
    description: true,
    isBlinded: true,
    autoAssignNewSites: true,
    actions: true,
  });

  const [languagesColumnVisibility, setLanguagesColumnVisibility] = useState({
    id: false,
    name: true,
    cultureName: true,
    displayName: true,
    isRightToLeft: true,
    status: true,
    actions: true,
  });

  const [countriesColumnVisibility, setCountriesColumnVisibility] = useState({
    id: false,
    name: true,
    isoId: true,
    regionName: true,
    isActive: true,
    actions: true,
  });

  const [regionColumnsVisibility, setRegionColumnsVisibility] = useState({
    id: false,
    name: true,
    status: true,
    actions: true,
  });

  const [countryAllData, setCountryAllData] = useState([]);
  const [languageAllData, setLanguageAllData] = useState([]);
  const [rolesAllData, setRolesAllData] = useState([]);
  const [regionsAllData, setRegionsAllData] = useState([]);

  const [searchInput, setSearchInput] = useState("");

  const {
    state,
    dispatch,
    handlePageChangeCountries,
    handlePageSizeChangeCountries,
    handleCountrySearch,
    handleSortingChange,
    handleFilterChange,
  } = useSystemCountry();
  const {
    systemCountries,
    isLoading,
    totalCountryCount,
    sortingField,
    sortingType,
    filters,
    isCalledFromOuter
  } = state;

  const { query } = useSystemCountries();
  const {data :countryData} = query
  
  useEffect(() => {
    const handler = setTimeout(() => {
      handleCountrySearch(searchInput);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [searchInput]);

  useEffect(() => {
    if (systemCountries.length > 0) {
      dispatch({ type: "DISABLE_LOADING" });
      localStorage.setItem("system_settings_data", totalCountryCount);
    }
  }, [systemCountries, totalCountryCount, dispatch]);

  useEffect(() => {
    if (value == 0) {
      fetchAllDataForExportCountry();
    } else if (value == 1) {
      fetchAllDataForExportLanguage();
    } else if (value == 2) {
      fetchAllDataForExportRoles();
    } else if (value == 3) {
      fetchAllDataForExportRegion();
    }
  }, [value]);

  const fetchAllDataForExportCountry = async () => {
    setSystemLoad(true);
    try {
      const res = await getAllExportCountry();
      if (res.status === 200) {
        const mappedData = mapCountryData(res.data.result);
        setCountryAllData(mappedData);
        setSystemLoad(false);
        return res.data;
      }
    } catch (err) {
      console.log("ERROR: ", err);
      setSystemLoad(false);
    }
    return [];
  };

  const fetchAllDataForExportLanguage = async () => {
    setSystemLoad(true);
    try {
      const res = await getAllExportLanguage();
      if (res.status === 200) {
        const mappedData = mapLanguageData(res.data.result);
        setLanguageAllData(mappedData);
        setSystemLoad(false);
        return res.data;
      }
    } catch (err) {
      console.log("ERROR: ", err);
      setSystemLoad(false);
    }
    return [];
  };

  const fetchAllDataForExportRegion = async () => {
    setSystemLoad(true);
    try {
      const res = await getAllExportRegion();
      if (res.status === 200) {
        const mappedData = mapRegionData(res.data.result);
        setRegionsAllData(mappedData);
        setSystemLoad(false);
        return res.data;
      }
    } catch (err) {
      console.log("ERROR: ", err);
      setSystemLoad(false);
    }
    return [];
  };

  const fetchAllDataForExportRoles = async () => {
    setSystemLoad(true);
    try {
      const res = await getAllExportRole();
      if (res.status === 200) {
        const mappedData = mapRolesData(res.data.result);
        setRolesAllData(mappedData);
        setSystemLoad(false);
        return res.data;
      }
    } catch (err) {
      console.log("ERROR: ", err);
      setSystemLoad(false);
    }
    return [];
  };

  // const fetchSystemCountries = async (
  //   pageNumberCountries,
  //   pageSizeCountries,
  //   sortingField,
  //   sortingType,
  //   filters
  // ) => {
  //   try {
  //     const res = await getAllSystemCountries(
  //       pageNumberCountries,
  //       pageSizeCountries,
  //       sortingField,
  //       sortingType,
  //       filters
  //     );
  //     if (res.status) {
  //       const trimmedData = res.result.map((row) => ({
  //         ...row,
  //         name: row.name.trim(),
  //         isoId: row.name.trim(),
  //       }));

  //       // setSystemCountries(trimmedData);
  //       setSystemCountry(trimmedData)
  //       setTotalCountCountries(res.totalCount);
  //     }
  //   } catch (err) {
  //     console.log("Error: ", err.message);
  //     setSystemLoad(false);
  //   }
  // };

  const fetchSystemLanguages = async (
    pageNumberLanguages,
    pageSizeLanguages,
    sortingField,
    sortingType,
    debouncedSearchStringLanguages,
    languageFilters
  ) => {
    try {
      const res = await getSystemLanguages(
        pageNumberLanguages,
        pageSizeLanguages,
        sortingField,
        sortingType,
        debouncedSearchStringLanguages,
        languageFilters
      );
      if (res.status) {
        setSystemLanguages(res?.result);
        setTotalCountLanguages(res?.totalCount);
      }
    } catch (err) {
      console.log("Error: ", err.message);
      setSystemLoad(false);
    }
  };

  const fetchSystemRegion = async (
    pageNumberRegions,
    pageSizeRegions,
    regionSortingField,
    regionSortingType,
    debouncedSearchStringRegions,
    regionFilters
  ) => {
    try {
      const res = await getSystemRegion(
        pageNumberRegions,
        pageSizeRegions,
        regionSortingField,
        regionSortingType,
        debouncedSearchStringRegions,
        regionFilters
      );
      if (res.status) {
        const trimmedData = res.result.map((row) => ({
          ...row,
          name: row.name.trim(),
        }));
        setRegionsData(trimmedData);
        setTotalCountRegions(res?.totalCount);
      }
    } catch (err) {
      console.log("Error: ", err.message);
      setSystemLoad(false);
    }
  };

  const fetchSystemRoles = async (
    pageNumberRoles,
    pageSizeRoles,
    roleSortingField,
    roleSortingType,
    debouncedSearchStringRoles,
    roleFilters
  ) => {
    try {
      const res = await getSystemRoles(
        pageNumberRoles,
        pageSizeRoles,
        roleSortingField,
        roleSortingType,
        debouncedSearchStringRoles,
        roleFilters
      );
      if (res.status) {
        setRolesData(res?.result);
        setTotalCountRoles(res?.totalCount);
      }
    } catch (err) {
      console.log("Error: ", err.message);
      setSystemLoad(false);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchStringSystemCountries(searchStringSystemCountries);
      const newFilters = {
        value: searchStringSystemCountries,
      };

      setSystemCountryFilter(newFilters);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchStringSystemCountries]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchStringLanguages(searchStringLanguages);

      // Construct the filter payload based on the search query
      const searchColumns = ["cultureName", "displayName", "name"];
      const newFilters = searchColumns.map((columnField, index) => ({
        Id: index + 1,
        columnField,
        operatorValue: "contains",
        value: searchStringLanguages,
      }));

      setLanguageFilters(newFilters);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchStringLanguages]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchStringRoles(searchStringRoles);

      // Construct the filter payload based on the search query
      const searchColumns = ["name", "description", "shortName"];
      const newFilters = searchColumns.map((columnField, index) => ({
        Id: index + 1,
        columnField,
        operatorValue: "contains",
        value: searchStringRoles,
      }));

      setRoleSearchFilter(newFilters);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchStringRoles]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchStringRegions(searchStringRegions);

      // Construct the filter payload based on the search query
      const searchColumns = ["name"];
      const newFilters = searchColumns.map((columnField, index) => ({
        Id: index + 1,
        columnField,
        operatorValue: "contains",
        value: searchStringRegions,
      }));

      setRegionFilters(newFilters);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchStringRegions]);

  useEffect(() => {
    fetchSystemLanguages(
      pageNumberLanguages,
      pageSizeLanguages,
      sortingType,
      sortingField,
      debouncedSearchStringLanguages,
      languageFilters
    );
  }, [
    pageNumberLanguages,
    pageSizeLanguages,
    debouncedSearchStringLanguages,
    sortingField,
    sortingType,
    languageFilters,
  ]);

  useEffect(() => {
    fetchSystemRegion(
      pageNumberRegions,
      pageSizeRegions,
      regionSortingType,
      regionSortingField,
      debouncedSearchStringRegions,
      regionFilters
    );
  }, [
    pageNumberRegions,
    pageSizeRegions,
    debouncedSearchStringRegions,
    regionFilters,
    regionSortingField,
    regionSortingType,
  ]);

  useEffect(() => {
    fetchSystemRoles(
      pageNumberRoles,
      pageSizeRoles,
      roleSortingField,
      roleSortingType,
      debouncedSearchStringRoles,
      roleFilters
    );
  }, [
    pageNumberRoles,
    pageSizeRoles,
    roleSortingField,
    roleSortingType,
    debouncedSearchStringRoles,
    roleFilters,
  ]);

  const handlePageChangeLanguages = (event, newPageNumber) => {
    setPageNumberLanguages(newPageNumber);
  };

  const handlePageSizeChangeLanguages = (event) => {
    setPageSizeLanguages(event.target.value);
    setPageNumberLanguages(1);
  };

  const handlePageChangeRegions = (event, newPageNumber) => {
    setPageNumberRegions(newPageNumber);
  };

  const handlePageSizeChangeRegions = (event) => {
    setPageSizeRegions(event.target.value);
    setPageNumberRegions(1);
  };

  const handlePageChangeRoles = (event, newPageNumber) => {
    setPageNumberRoles(newPageNumber);
  };

  const handlePageSizeChangeRoles = (event) => {
    setPageSizeRoles(event.target.value);
    setPageNumberRoles(1);
  };

  React.useEffect(() => {
    if (
      // systemCountries.length > 0 &&
      systemLanguages.length > 0 &&
      regionsData.length > 0 &&
      rolesData.length > 0
    ) {
      setSystemLoad(false);
      localStorage.setItem(
        "system_settings_data",
        // totalCountCountries +
        totalCountLanguages + totalCountRegions + totalCountRoles
      );
    }
  }, [systemLanguages, regionsData, rolesData]);

  let languagesRows;
  if (Array.isArray(systemLanguages)) {
    languagesRows = systemLanguages?.map((row, index) => ({
      id: row.id || index,
      cultureName: row?.cultureName || "-",
      name: row?.name || "-",
      displayName: row?.displayName || "-",
      isRightToLeft: row?.isRightToLeft || "-",
      editId: row?.id,
      deleteId: row?.id,
      status: row?.isActive || "-",
    }));
    // Further processing with languagesRows
  } else {
    console.error("systemLanguages is not an array:", systemLanguages);
  }

  // const countriesRows = state?.systemCountries?.map((row, index) => ({
    const countriesRows = state?.systemCountries?.map((row, index) => ({
    id: row.id || index,
    name: row?.name || "-",
    isoId: row?.isoId || "-",
    regionId: row?.regionId || "-",
    // notes: row.notes,
    // region: row?.systemRegion?.name || "-",
    regionName: row?.regionName || "-",
    editId: row?.id,
    isActive: row?.isActive,
  }));

  const regionRows = regionsData?.map((row) => ({
    id: row?.id,
    name: row?.name || "-",
    editId: row?.id || "-",
    status: row?.isActive || "-",
  }));

  const rolesRows = rolesData?.map((row) => ({
    id: row?.id,
    name: row?.shortName || "-",
    description: row?.description || "-",
    isBlinded: row?.isBlinded || "-",
    autoAssignNewSites: row?.autoAssignNewSites || "-",
    editId: row?.id,
  }));

  const roleColumns = [
    {
      field: "id",
      hide: !rolesColumnVisibility.id,
      headerName: "ID",
      sortable: true,
      width: 0,
      filterable: false,
    },

    {
      field: "name",
      headerName: "Name",
      sortable: true,
      flex: 1,
      minWidth: 220,
      hide: !rolesColumnVisibility.name,
    },
    {
      field: "description",
      headerName: "Description",
      sortable: true,
      flex: 1,
      minWidth: 220,
      hide: !rolesColumnVisibility.description,
    },
    {
      field: "isBlinded",
      headerName: "Is Blinded",
      sortable: true,
      flex: 1,
      minWidth: 220,
      hide: !rolesColumnVisibility.isBlinded,
      renderCell: (params) => (
        <Chip
          label={params.row.isBlinded ? "Yes" : "No"}
          color={params.row.isBlinded ? "success" : "error"}
          size="small"
        />
      ),
    },
    {
      field: "autoAssignNewSites",
      headerName: "Auto Assign New Sites",
      flex: 1,
      sortable: true,
      minWidth: 220,
      hide: !rolesColumnVisibility.autoAssignNewSites,
      renderCell: (params) => (
        <Chip
          label={params.row.autoAssignNewSites ? "Yes" : "No"}
          color={params.row.autoAssignNewSites ? "success" : "error"}
          size="small"
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      filterable: false,
      filterable: false,
      hide: !rolesColumnVisibility.actions,
      sortable: false,
      flex: 1,
      minWidth: 220,
      renderCell: (params) => {
        const id = params.row.id;

        return (
          <button
            // className="bg-green-500"
            style={{
              cursor: "pointer",
              textAlign: "center",
              backgroundColor: "#337ab7",
              padding: "8px",
              border: "none",
              borderRadius: "5px",
              marginLeft: "10px",
            }}
            onClick={async () => {
              try {
                navigate(`/system-settings/roles-edit`, {
                  state: {
                    id: id,
                  },
                });
              } catch (err) {
                console.log("Error: ", err.message);
              }
            }}
          >
            <FaPencilAlt color="white" size={15} />
          </button>
        );
      },
    },
  ];

  const languagesColumns = [
    {
      field: "id",
      hide: !languagesColumnVisibility.id,
      headerName: "ID",
      width: 0,
      sortable: true,
      filterable: false,
    },
    {
      field: "name",
      hide: !languagesColumnVisibility.name,
      headerName: "Name",
      sortable: true,
      flex: 1,
      minWidth: 220,
    },
    {
      field: "cultureName",
      hide: !languagesColumnVisibility.cultureName,
      headerName: "Culture Name",
      sortable: true,
      flex: 1,
      minWidth: 220,
    },
    {
      field: "displayName",
      hide: !languagesColumnVisibility.displayName,
      headerName: "Display Name",
      flex: 1,
      sortable: true,
      minWidth: 220,
    },
    {
      field: "isRightToLeft",
      hide: !languagesColumnVisibility.isRightToLeft,
      headerName: "Right To Left",
      flex: 1,
      sortable: true,
      filterable: false,
      minWidth: 220,
      renderCell: (params) => (
        <Chip
          label={params.row.isRightToLeft ? "Yes" : "No"}
          color={params.row.isRightToLeft ? "success" : "error"}
          size="small"
        />
      ),
    },
    {
      field: "isActive",
      hide: !languagesColumnVisibility.status,
      headerName: "Active",
      flex: 1,
      sortable: true,
      filterable: false,
      minWidth: 220,
      renderCell: (params) => (
        <Chip
          label={params.row.status ? "Yes" : "No"}
          color={params.row.status ? "success" : "error"}
          size="small"
        />
      ),
    },
    {
      field: "actions",
      hide: !languagesColumnVisibility.actions,
      headerName: "Actions",
      flex: 1,
      sortable: false,
      filterable: false,
      minWidth: 220,
      renderCell: (params) => {
        const id = params.row.id;
        const status = params.row.status;

        return (
          <button
            // className="bg-green-500"
            style={{
              cursor: "pointer",
              textAlign: "center",
              backgroundColor: "#337ab7",
              padding: "8px",
              border: "none",
              borderRadius: "5px",
              marginLeft: "10px",
            }}
            onClick={async () => {
              try {
                navigate(`/system-settings/languages-edit`, {
                  state: {
                    id: id,
                    status: status,
                  },
                });
              } catch (err) {
                console.log("Error: ", err.message);
              }
            }}
          >
            <FaPencilAlt color="white" size={15} />
          </button>
        );
      },
    },
  ];

  const countriesColumns = [
    {
      field: "id",
      hide: !countriesColumnVisibility.id,
      headerName: "ID",
      width: 0,
      sortable: true,
      filterable: false,
    },
    {
      field: "name",
      hide: !countriesColumnVisibility.name,
      headerName: "Name",
      flex: 1,
      sortable: true,
      minWidth: 220,
    },
    {
      field: "isoId",
      hide: !countriesColumnVisibility.isoId,
      headerName: "ISO Name",
      flex: 1,
      minWidth: 220,
      sortable: true,
    },

    {
      field: "regionName",
      hide: !countriesColumnVisibility.region,
      headerName: "Region",
      flex: 1,
      minWidth: 220,
      sortable: true,
    },
    {
      field: "isActive",
      hide: !countriesColumnVisibility.isActive,
      headerName: "Active",
      flex: 1,
      sortable: true,
      filterable: false,
      minWidth: 220,
      renderCell: (params) => (
        <Chip
          label={params.row.isActive ? "Yes" : "No"}
          color={params.row.isActive ? "success" : "error"}
          size="small"
        />
      ),
    },
    {
      field: "actions",
      hide: !countriesColumnVisibility.actions,
      headerName: "Actions",
      flex: 1,
      sortable: false,
      filterable: false,
      minWidth: 220,
      renderCell: (params) => {
        const { id, name, isoId, regionId } = params.row;

        // const id = params.row.id;
        // const status = params.row.status;

        return (
          <button
            // className="bg-green-500"
            style={{
              cursor: "pointer",
              textAlign: "center",
              backgroundColor: "#337ab7",
              padding: "8px",
              border: "none",
              borderRadius: "5px",
              marginLeft: "10px",
            }}
            onClick={() =>
              navigate(`/system-settings/countries-edit/${id}`, {
                state: {
                  id,
                  name,
                  isoId,
                  regionId,
                  isActive: status === "Active",
                },
              })
            }
          >
            <FaPencilAlt color="white" size={15} />
          </button>
        );
      },
    },
  ];

  const regionColumns = [
    {
      field: "id",
      hide: !regionColumnsVisibility.id,
      headerName: "ID",
      width: 0,
      filterable: false,
      sortable: true,
    },
    {
      field: "name",
      hide: !regionColumnsVisibility.name,
      headerName: "Name",
      flex: 1,
      minWidth: 220,
      sortable: true,
    },
    {
      field: "isActive",
      hide: !regionColumnsVisibility.status,
      headerName: "Active",
      sortable: true,
      flex: 1,
      filterable: false,
      minWidth: 220,
      renderCell: (params) => (
        <Chip
          label={params.row.status ? "Yes" : "No"}
          color={params.row.status ? "success" : "error"}
          size="small"
        />
      ),
    },
    {
      field: "actions",
      hide: !regionColumnsVisibility.actions,
      headerName: "Actions",
      sortable: false,
      flex: 1,
      filterable: false,
      minWidth: 220,
      renderCell: (params) => {
        const id = params.row.id;

        return (
          <button
            // className="bg-green-500"
            style={{
              cursor: "pointer",
              textAlign: "center",
              backgroundColor: "#337ab7",
              padding: "8px",
              border: "none",
              borderRadius: "5px",
              marginLeft: "10px",
            }}
            onClick={async () => {
              try {
                navigate(`/system-settings/regions-edit`, {
                  state: {
                    id: id,
                  },
                });
              } catch (err) {
                console.log("Error: ", err.message);
              }
            }}
          >
            <FaPencilAlt color="white" size={15} />
          </button>
        );
      },
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();

  const handleFilterModelChange = (newFilterModel) => {
    console.log("New filter model:", newFilterModel);
    const filterItems = newFilterModel.items.map((filter, index) => ({
      Id: index + 1,
      columnField: filter.columnField,
      operatorValue: filter.operatorValue,
      value: filter.value
    }));
    console.log("Filters to be dispatched:", filterItems);
    handleFilterChange(filterItems);
    console.log(filterItems,"country payload")
  };

  const handleLanguageFilterChange = (filterModel) => {
    const formattedFilters = filterModel.items.map((filter, index) => ({
      Id: index + 1,
      columnField: filter.columnField,
      operatorValue: filter.operatorValue,
      value: filter.value,
    }));
    setLanguageFilters(formattedFilters);
  };

  const handleRegionFilterChange = (filterModel) => {
    const formattedFilters = filterModel.items.map((filter, index) => ({
      Id: index + 1,
      columnField: filter.columnField,
      operatorValue: filter.operatorValue,
      value: filter.value,
    }));
    setRegionFilters(formattedFilters);
  };
  const handleRoleFilterChange = (filterModel) => {
    const formattedFilters = filterModel.items.map((filter, index) => ({
      Id: index + 1,
      columnField: filter.columnField,
      operatorValue: filter.operatorValue,
      value: filter.value,
    }));
    setRoleFilters(formattedFilters);
  };

  // const handleSortModelChange = (sortModel) => {
  //   if (sortModel.length > 0) {
  //     const { field, sort } = sortModel[0];
  //     setSortingField(field);
  //     setSortingType(sort === "asc" ? "asc" : "desc");
  //   } else {
  //     setSortingField(null);
  //     setSortingType(null);
  //   }
  // };
  const handleSortModelChange = (newSortModel) => {
    handleSortingChange(newSortModel);
  };

  const handleRoleSortModelChange = (sortModel) => {
    if (sortModel.length > 0) {
      const { field, sort } = sortModel[0];
      setRoleSortingField(field);
      setRoleSortingType(sort === "asc" ? "asc" : "desc");
    } else {
      setRoleSortingField(null);
      setRoleSortingType(null);
    }
  };

  const handleRegionSortModelChange = (sortModel) => {
    if (sortModel.length > 0) {
      const { field, sort } = sortModel[0];
      setRegionSortingField(field);
      setRegionSortingType(sort === "asc" ? "asc" : "desc");
    } else {
      setRegionSortingField(null);
      setRegionSortingType(null);
    }
  };

  const handleLanguageSearch = (event) => {
    const searchValue = event.target.value;
    setDebouncedSearchStringLanguages(searchValue);
    const searchColumns = ["cultureName", "displayName", "name"];
    const newFilters = searchColumns.map((columnField, index) => ({
      Id: index + 1,
      columnField,
      operatorValue: "contains",
      value: searchValue,
    }));
    setLanguageFilters(newFilters);
  };
  const handleRoleSearch = (event) => {
    const searchValue = event.target.value;
    setDebouncedSearchStringRoles(searchValue);
    const searchColumns = ["name", "description", "shortName"];
    const newFilters = searchColumns.map((columnField, index) => ({
      Id: index + 1,
      columnField,
      operatorValue: "contains",
      value: searchValue,
    }));
    setRoleFilters(newFilters);
  };
  const handleRegionSearch = (event) => {
    const searchValue = event.target.value;
    setDebouncedSearchStringRegions(searchValue);
    const searchColumns = ["name"];
    const newFilters = searchColumns.map((columnField, index) => ({
      Id: index + 1,
      columnField,
      operatorValue: "contains",
      value: searchValue,
    }));
    setRegionFilters(newFilters);
  };

  const GridExportToPDF = (props) => {
    return (
      <MenuItem
        onClick={async () => {
          try {
            const res = await postExportPDF(props.rows);
            if (res.status === 250) {
              const res2 = await getDownloadReport(res.data);
              if (res2.status === 250) {
                const blob = new Blob([res2.data], { type: "application/pdf" });
                props.type === "Country"
                  ? saveAs(blob, "System Countries.pdf")
                  : props.type === "Language"
                  ? saveAs(blob, "System Languages.pdf")
                  : props.type === "Role"
                  ? saveAs(blob, "System Roles.pdf")
                  : saveAs(blob, "System Regions.pdf");
              }
            }
            props.hideMenu?.();
          } catch (err) {
            console.log("ERROR: ", err);
          }
        }}
      >
        Export PDF
      </MenuItem>
    );
  };

  const GridExportToExcel = (props) => {
    return (
      <MenuItem
        onClick={async () => {
          try {
            const res = await postExportExcel(props.rows);
            if (res.status === 200) {
              const res2 = await getDownloadReport(res.data);
              if (res2.status === 200) {
                const blob = new Blob([res2.data], {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                props.type === "Country"
                  ? saveAs(blob, "System Countries.xlsx")
                  : props.type === "Language"
                  ? saveAs(blob, "System Languages.xlsx")
                  : props.type === "Role"
                  ? saveAs(blob, "System Roles.xlsx")
                  : saveAs(blob, "System Regions.xlsx");
              }
            }
            props.hideMenu?.();
          } catch (err) {
            console.log("ERROR: ", err);
          }
        }}
      >
        Export Excel
      </MenuItem>
    );
  };

  const CustomExportButtonCountries = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridExportToExcel rows={countryAllData} type="Country" />
      <GridExportToPDF rows={countryAllData} type="Country" />
    </GridToolbarExportContainer>
  );

  const CustomToolbarCountries = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <CustomExportButtonCountries />
    </GridToolbarContainer>
  );

  const CustomExportButtonLanguages = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridExportToExcel rows={languageAllData} type="Language" />
      <GridExportToPDF rows={languageAllData} type="Language" />
    </GridToolbarExportContainer>
  );

  const CustomToolbarLanguages = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <CustomExportButtonLanguages />
    </GridToolbarContainer>
  );

  const CustomExportButtonRoles = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridExportToExcel rows={rolesAllData} type="Role" />
      <GridExportToPDF rows={rolesAllData} type="Role" />
    </GridToolbarExportContainer>
  );

  const CustomToolbarRoles = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <CustomExportButtonRoles />
    </GridToolbarContainer>
  );

  const CustomExportButtonRegions = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridExportToExcel rows={regionsAllData} type="Region" />
      <GridExportToPDF rows={regionsAllData} type="Region" />
    </GridToolbarExportContainer>
  );

  const CustomToolbarRegions = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <CustomExportButtonRegions />
    </GridToolbarContainer>
  );

  GridExportToExcel.propTypes = {
    hideMenu: PropTypes.func,
  };

  GridExportToPDF.propTypes = {
    hideMenu: PropTypes.func,
  };

  const tabStyles = {
    fontSize: "12px",
    fontWeight: "700",
  };

  return (
    <ThemeProvider theme={getMuiTheme}>
      <>
        {systemLoad ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "70vh",
              }}
            >
              <BeatLoader color="#3661eb" />
            </div>
          </>
        ) : (
          <div className="content-body">
            <p className="admin-link" style={{ fontWeight: "600" }}>
              <Link to="/homepage">Home</Link>
              {" > "}
              <Link to="/system-settings">System Settings</Link>
            </p>

            <Box
              sx={{
                marginTop: "2%",
                "& .MuiDataGrid-columnHeaders": {
                  minHeight: "58px !important", 
                },
              }}
            >
              <Box sx={{ width: "35%", borderColor: "divider" }}>
                <Tabs
                  className="system-tabs"
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  variant="fullWidth"
                >
                  <Tab sx={tabStyles} label="Countries" {...a11yProps(0)} />
                  <Tab sx={tabStyles} label="Languages" {...a11yProps(1)} />
                  <Tab sx={tabStyles} label="Roles" {...a11yProps(2)} />
                  <Tab sx={tabStyles} label="Regions" {...a11yProps(3)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Row>
                  <Col md={6}>
                    <p className="sponsor-heading">System Countries</p>
                  </Col>
                  <Col sm={4}>
                    <Paper
                      component="form"
                      sx={{
                        p: "4px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <SearchSharp />
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search System Countries"
                        inputProps={{ "aria-label": "search system countries" }}
                        // onChange={handleCountrySearch}
                        onChange={(e) => setSearchInput(e.target.value)}
                      />
                    </Paper>
                  </Col>
                  <Col md={2}>
                    <div className="userButtonContainer">
                      <Link to="/system-settings/countries-add">
                        <button
                          className="addUserButton font-extrabold"
                          style={{ borderRadius: "5px" }}
                        >
                          + Add Country
                        </button>
                      </Link>
                    </div>
                  </Col>
                </Row>

                {/* <Box sx={{ height: 400, width: "100%" }}> */}
                <Box
                  sx={{
                    height: 669,
                    width: "100%",
                    "& .MuiDataGrid-columnHeaders": {
                      minHeight: "58px !important",
                    },
                  }}
                >
                  <ThemeProvider theme={getMuiTheme}>
                    <StripedDataGrid
                      // className="allUsers-grid"
                      rows={countriesRows}
                      columns={countriesColumns}
                      
                      components={{ Toolbar: CustomToolbarCountries }}
                      disableSelectionOnClick={true}
                      experimentalFeatures={{ newEditingApi: true }}
                      getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0
                          ? "even"
                          : "odd"
                      }
                      loading={isLoading}
                      columnVisibilityModel={countriesColumnVisibility}
                      onColumnVisibilityModelChange={(newModel) =>
                        setCountriesColumnVisibility(newModel)
                      }
                      filterMode="server"
                      onFilterModelChange={handleFilterModelChange}
                      sortingMode="server"
                      // sortModel={[
                      //   {
                      //     field: sortingField,
                      //     sort: sortingType === "asc" ? "asc" : "desc",
                      //   },
                      // ]}
                      sortModel={
                        sortingField && sortingType
                          ? [{ field: sortingField, sort: sortingType }]
                          : []
                      }
                      onSortModelChange={handleSortModelChange}
                    />
                    <CustomPagination
                      currentPage={state.pageNumberCountries}
                      totalCount={state.totalCountryCount}
                      pageSize={state.pageSizeCountries}
                      size="large"
                      onPageChange={handlePageChangeCountries}
                      onPageSizeChange={handlePageSizeChangeCountries}
                    />
                  </ThemeProvider>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Row>
                  <Col md={6}>
                    <p className="sponsor-heading">System Languages</p>
                  </Col>
                  <Col sm={4}>
                    <Paper
                      component="form"
                      sx={{
                        p: "4px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <SearchSharp />
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search System Languages"
                        inputProps={{ "aria-label": "search system languages" }}
                        onChange={handleLanguageSearch}
                      />
                    </Paper>
                  </Col>
                  <Col md={2}>
                    <div className="userButtonContainer">
                      <Link to="/system-settings/languages-add">
                        <button
                          className="addUserButton font-extrabold"
                          style={{ borderRadius: "5px" }}
                        >
                          + Add Language
                        </button>
                      </Link>
                    </div>
                  </Col>
                </Row>

                {/* <Box sx={{ height: 400, width: "100%" }}> */}
                <Box
                  sx={{
                    height: 669,
                    width: "100%",
                    "& .MuiDataGrid-columnHeaders": {
                      minHeight: "58px !important",
                    },
                  }}
                >
                  <ThemeProvider theme={getMuiTheme}>
                    <StripedDataGrid
                      // className="allUsers-grid"
                      rows={languagesRows}
                      columns={languagesColumns}
                      components={{ Toolbar: CustomToolbarLanguages }}
                      disableSelectionOnClick={true}
                      loading={systemLoad}
                      experimentalFeatures={{ newEditingApi: true }}
                      getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0
                          ? "even"
                          : "odd"
                      }
                      columnVisibilityModel={languagesColumnVisibility}
                      onColumnVisibilityModelChange={(newModel) =>
                        setLanguagesColumnVisibility(newModel)
                      }
                      filterMode="server"
                      onFilterModelChange={handleLanguageFilterChange}
                      sortingMode="server"
                      sortModel={[
                        {
                          field: sortingField,
                          sort: sortingType === "asc" ? "asc" : "desc",
                        },
                      ]}
                      onSortModelChange={handleSortModelChange}
                    />
                    <CustomPagination
                      currentPage={pageNumberLanguages}
                      totalCount={totalCountLanguages}
                      pageSize={pageSizeLanguages}
                      size="large"
                      onPageChange={handlePageChangeLanguages}
                      onPageSizeChange={handlePageSizeChangeLanguages}
                    />
                  </ThemeProvider>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Row>
                  <Col md={6}>
                    <p className="sponsor-heading">System Roles</p>
                  </Col>
                  <Col sm={4}>
                    <Paper
                      component="form"
                      sx={{
                        p: "4px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <SearchSharp />
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search System Roles"
                        inputProps={{ "aria-label": "search system roles" }}
                        onChange={handleRoleSearch}
                      />
                    </Paper>
                  </Col>
                  <Col md={2}>
                    <div className="userButtonContainer">
                      <Link to="/system-settings/roles-add">
                        <button
                          className="addUserButton font-extrabold"
                          style={{ borderRadius: "5px" }}
                        >
                          + Add Role
                        </button>
                      </Link>
                    </div>
                  </Col>
                </Row>

                {/* <Box sx={{ height: 400, width: "100%" }}> */}
                <Box
                  sx={{
                    height: 669,
                    width: "100%",
                    "& .MuiDataGrid-columnHeaders": {
                      minHeight: "58px !important",
                    },
                  }}
                >
                  <ThemeProvider theme={getMuiTheme}>
                    <StripedDataGrid
                      // className="allUsers-grid"
                      rows={rolesRows}
                      columns={roleColumns}
                      components={{ Toolbar: CustomToolbarRoles }}
                      disableSelectionOnClick={true}
                      experimentalFeatures={{ newEditingApi: true }}
                      getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0
                          ? "even"
                          : "odd"
                      }
                      columnVisibilityModel={rolesColumnVisibility}
                      onColumnVisibilityModelChange={(newModel) =>
                        setRolesColumnVisibility(newModel)
                      }
                      filterMode="server"
                      onFilterModelChange={handleRoleFilterChange}
                      sortingMode="server"
                      // sortModel={[
                      //   {
                      //     field: roleSortingField,
                      //     sort: roleSortingType === "asc" ? "asc" : "desc",
                      //   },
                      // ]}
                      sortModel={
                        roleSortingField && roleSortingType
                          ? [{ field: roleSortingField, sort: roleSortingType }]
                          : []
                      }
                      onSortModelChange={handleRoleSortModelChange}
                    />
                    <CustomPagination
                      currentPage={pageNumberRoles}
                      totalCount={totalCountRoles}
                      pageSize={pageSizeRoles}
                      size="large"
                      onPageChange={handlePageChangeRoles}
                      onPageSizeChange={handlePageSizeChangeRoles}
                    />
                  </ThemeProvider>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Row>
                  <Col md={6}>
                    <p className="sponsor-heading">System Regions</p>
                  </Col>
                  <Col sm={4}>
                    <Paper
                      component="form"
                      sx={{
                        p: "4px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <SearchSharp />
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search System Regions"
                        inputProps={{ "aria-label": "search system Regions" }}
                        onChange={handleRegionSearch}
                      />
                    </Paper>
                  </Col>
                  <Col md={2}>
                    <div className="userButtonContainer">
                      <Link to="/system-settings/regions-add">
                        <button
                          className="addUserButton font-extrabold"
                          style={{ borderRadius: "5px" }}
                        >
                          + Add Region
                        </button>
                      </Link>
                    </div>
                  </Col>
                </Row>

                {/* <Box sx={{ height: 400, width: "100%" }}> */}
                <Box
                  sx={{
                    height: 669,
                    width: "100%",
                    "& .MuiDataGrid-columnHeaders": {
                      minHeight: "58px !important",
                    },
                  }}
                >
                  <ThemeProvider theme={getMuiTheme}>
                    <StripedDataGrid
                      // className="allUsers-grid"
                      rows={regionRows}
                      columns={regionColumns}
                      components={{ Toolbar: CustomToolbarRegions }}
                      disableSelectionOnClick={true}
                      experimentalFeatures={{ newEditingApi: true }}
                      getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0
                          ? "even"
                          : "odd"
                      }
                      columnVisibilityModel={regionColumnsVisibility}
                      onColumnVisibilityModelChange={(newModel) =>
                        setRegionColumnsVisibility(newModel)
                      }
                      filterMode="server"
                      onFilterModelChange={handleRegionFilterChange}
                      sortingMode="server"
                      sortModel={
                        regionSortingField && regionSortingType
                          ? [
                              {
                                field: regionSortingField,
                                sort: regionSortingType,
                              },
                            ]
                          : []
                      }
                      onSortModelChange={handleRegionSortModelChange}
                    />
                    <CustomPagination
                      currentPage={pageNumberRegions}
                      totalCount={totalCountRegions}
                      pageSize={pageSizeRegions}
                      size="large"
                      onPageChange={handlePageChangeRegions}
                      onPageSizeChange={handlePageSizeChangeRegions}
                    />
                  </ThemeProvider>
                </Box>
              </TabPanel>
            </Box>
          </div>
        )}
      </>
    </ThemeProvider>
  );
};

export default SystemSettings;
