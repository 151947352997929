import { dataServer } from "./axios.config";

export const getStudyRoles = () => {
  return new Promise((resolve, reject) => {
    dataServer
      .get("/app/StudyRoles/GetAllStudyRoles")
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getStudyRoleById = (id) => {
  return new Promise((resolve, reject) => {
    dataServer
      .get("/app/StudyRoles/{id}")
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const assignStudyRoles = (data) => {
  return new Promise((resolve, reject) => {
    dataServer
      .post("/app/StudyRoles/assignStudyrole", data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

