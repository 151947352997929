import axios from "axios";

const calcServer = axios.create({
  baseURL: process.env.REACT_APP_FORM_API,
  timeout: 100000,
  // headers: { Accept: 'application/json', 'Content-Type': 'application/json' }
});

export const getCalculation = () => {
  return new Promise((resolve, reject) => {
    calcServer
      .get("/Calculation")
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getCalculationEndPoints = () => {
  return new Promise((resolve, reject) => {
    calcServer
      .get("/Calculation/get-endpointtypes")
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getCalculationById = (id) => {
  return new Promise((resolve, reject) => {
    calcServer
      .get(`/Calculation/getby-id?id=${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const postCalculation = (data) => {
  return new Promise((resolve, reject) => {
    calcServer
      .post(`/Calculation`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const deleteCalculation = (id) => {
  return new Promise((resolve, reject) => {
    calcServer
      .delete(`/Calculation?id=${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const editStudyCalculation = (id, data) => {
  return new Promise((resolve, reject) => {
    calcServer
      .put(`/Calculation?id=${id}`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
