import React, { useEffect } from "react";
import { Box, TextField } from "@mui/material";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "@mui/material/Modal";

// import { useGetRule } from "../../hooks/useRules";
// import { checkRuleValue } from "../../services/helpers";
// import "../Questionnaire/Questionnaire.css";

const HotSpot = React.forwardRef((props, ref) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [titleName, setTitleName] = React.useState("");
  const [showTitle, setShowTitle] = React.useState(false);
//   const { Rules, triggerUpdate } = useGetRule();

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleShowTitle = () => {
    if (titleName === "") {
      setShowTitle(false);
    } else {
      setShowTitle(true);
    }
  };

  const handleTitleName = (event) => {
    event.preventDefault();
    setTitleName(event.target.value);
  };

  const handleChange = (event) => {
    event.preventDefault();
    props.set({ key: props.data.id, value: event.target.value });
  };
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none !important",
    boxShadow: 24,
    borderRadius: "3px",
    p: 3,
  };

  const {
    name,
    defaultValue,
    disabled,
    border,
    borderTop,
    borderBottom,
    borderSides,
    currentPage,
    label,
  } = props;
  const [isDisabled, setDisabled] = React.useState(disabled);
  const [isVisible, setVisible] = React.useState(true);

//   useEffect(() => {
//     const { form, formResponse } = Rules;
//     if (formResponse) {
//       const myId = props?.data.id;
//       if (
//         (form[myId] && form[myId]["dependsOn"]) ||
//         (form[myId] && form[myId]["useCalculation"])
//       ) {
//         const ruleData = checkRuleValue(form[myId], formResponse);
//         const { isVisible, isDisabled } = ruleData;
//         setVisible(isVisible);
//         setDisabled(isDisabled);
//       }
//     }
//   }, [Rules]);
  return (
    <>
      {" "}
      {isVisible && (
        <div
          className={`${borderTop ? "borderTop " : ""} ${
            borderSides ? "borderSides " : ""
          } ${borderBottom ? "borderBottom " : ""} flexCenter`}
        >
          {currentPage && (
            <div className="currentPage">
              {/* Page <p>{currentPage}</p> */}
              {!showTitle ? (
                <>
                  <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={modalStyle}>
                      <Row>
                        <Col md={12}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginBottom: "2%",
                            }}
                          >
                            <h4>Enter Page Title</h4>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <TextField
                              className="muiTextInput"
                              id="outlined-basic"
                              onChange={handleTitleName}
                              placeholder="Page Title"
                              variant="outlined"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div
                            style={{
                              marginTop: "4%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              onClick={handleShowTitle}
                              className="saveTitleBtn"
                            >
                              Save
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Box>
                  </Modal>
                  {/* <button onClick={handleOpenModal} className="addTitleBtn">
                  Add Title
                </button> */}
                  {/* <div className="addTitleBtn">Container Title</div> */}
                </>
              ) : (
                <p>{titleName}</p>
              )}
            </div>
          )}
          <div className={`${disabled ? "labelDisabled" : "labelContainer"}`}>
            <p>{label}</p>
          </div>
          <div className="ageRangeInputBody">
            <div>
              <TextField
                className="muiTextInput"
                id="outlined-basic"
                type="text"
                ref={ref}
                name={name}
                placeholder="Hot Spot"
                onChange={handleChange}
                variant="outlined"
                defaultValue={defaultValue}
                // disabled={isDisabled}
                disabled={disabled}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default HotSpot;
