import React, { useEffect, useState } from "react";
import { Col, ListGroup, Row } from "react-bootstrap";
import BeatLoader from "react-spinners/BeatLoader";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallbackPrompt } from "../../../../../hooks/useCallbackPrompt";
import DialogBox from "../../../../../components/DialogBox";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import {
  addNewCountryService,
  editCountryService,
  getAllCountriesService,
  getAllNewCountriesService,
  getCountryByIdService,
  getCoutryLanguage,
  updateCountryLanguageService,
} from "./CountriesNewServices";
import { toast } from "react-toastify";
import moment from "moment";
import { Link } from "react-router-dom";

const textBoxBody = {
  marginLeft: "10px",
};

const textBoxStyles = {
  fontSize: 15,
  width: "400px",
  height: "10px",
};

const selectStyles = {
  width: "429px",
  marginTop: 1,
};

export const textSmallStyles = {
  fontSize: 13,
  width: "250px",
  height: "10px",
};

const labelStyles = {
  display: "flex",
  alignItems: "center",
  height: "20px",
};

const rowStyles = {
  marginTop: "2%",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const CountriesNew = () => {
  const [loading, setLoading] = useState(false);
  const [countriesNames, setCountriesNames] = useState([]);
  const [country, setCountry] = useState({
    countryName: "",
    countryCode: "",
  });
  const [formData, setFormData] = useState({
    formCountryCode: "",
    lastUpdated: "",
    name: "",
    notes: "",
    region: "",
    dob: "",
    recordPatient: "",
    subjectCode: "",
    recordGender: "",
    recordDob: "",
    useHour: "",
  });

  const [countryId, setCountryId] = useState(null);
  const [countryLanguages, setCountryLanguages] = useState([]);
  const [searchedCountry, setSearchedCountry] = useState("");
  const [unchangedList, setUnchangedList] = useState([]);

  const dateFormats = [
    {
      name: "dd/MMM/yyyy",
      id: 1,
    },
    {
      name: "MMM/yyyy",
      id: 2,
    },
    {
      name: "yyyy",
      id: 3,
    },
  ];

  const [value, setTabValue] = useState(0);
  const [showDialog, setShowDialog] = React.useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const [enUs, setEnUs] = useState(false);
  const [esEs, setEsEs] = useState(false);
  const [jaJp, setJaJp] = useState(false);
  const [zhCn, setZhCn] = useState(false);
  const [heIl, setHeIl] = useState(false);

  const { countryCode, countryName } = country;

  const navigate = useNavigate();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      toastId: "form-creation",
    });

  const notifyDuplicate = () =>
    toast.error("Country Already Exists !", {
      toastId: "form-creation",
    });

  const requestFailed = (msg) =>
    toast.error(`${msg}`, {
      toastId: "requestFailed",
    });

  const createCountryDataSchema = yup.object().shape({
    name: yup.string().required("This field is required"),
    notes: yup.string().required("This field is required"),
    region: yup
      .number()
      .typeError("Value must be a number")
      .required("This field is required")
      .min(0, "Value cannot be less than 0"),
    dobFormat: yup.string().required("This field is required"),
    recordPatient: yup.bool().oneOf([true, false]),
    subjectCode: yup.bool().oneOf([true, false]),
    patientGender: yup.bool().oneOf([true, false]),
    pateientDob: yup.bool().oneOf([true, false]),
    useHour: yup.bool().oneOf([true, false]),
  });

  const createLanguageSchema = yup.object().shape({
    enUs: yup.bool().oneOf([true, false]),
    esEs: yup.bool().oneOf([true, false]),
    jaJp: yup.bool().oneOf([true, false]),
    zhCn: yup.bool().oneOf([true, false]),
    heIl: yup.bool().oneOf([true, false]),
  });

  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createCountryDataSchema),
  });

  const {
    register: registerLanguage,
    handleSubmit: handleSubmitLanguage,
    reset: resetLanguage,
    formState: { errors: errorsLanguage },
  } = useForm({
    resolver: yupResolver(createLanguageSchema),
  });

  useEffect(() => {
    getAllCountriesName();
  }, []);

  useEffect(() => {
    if (value === 1 && formData?.formCountryCode) {
      getCountryLanguageListData(formData?.formCountryCode);
    }
  }, [value, formData?.formCountryCode]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getCountryLanguageListData = async (shortName) => {
    setLoading(true);
    try {
      const res = await getCoutryLanguage(shortName);
      if (res?.status) {
        setLoading(false);
        setCountryLanguages(res?.data?.result?.languages);
        res?.data?.result?.languages?.forEach((item) => {
          if (item?.name === "en-US") {
            setEnUs(item?.selected);
          }
          if (item?.name === "es-ES") {
            setEsEs(item?.selected);
          }
          if (item?.name === "ja-JP") {
            setJaJp(item?.selected);
          }
          if (item?.name === "zh-CN") {
            setZhCn(item?.selected);
          }
          if (item?.name === "he-IL") {
            setHeIl(item?.selected);
          }
        });
      }
    } catch (err) {
      setLoading(false);
      console.log("Error: ", err?.message);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      formData.id = countryId;
      formData.isActive=true
      const res = await editCountryService(formData);
      if (res?.status) {
        notifySuccess("Country Edited Successfully !");
        getCountryById(countryId);
        reset();
        setLoading(false);
      }
    } catch (err) {
      requestFailed(err?.message);
      setLoading(false);
    }
  };

  const submitLanguage = async (data) => {
    setLoading(true);
    try {
      setLoading(false);
      let tempLanguages = [];
      const languageSelections = {
        "es-ES": esEs,
        "en-US": enUs,
        "ja-JP": jaJp,
        "zh-CN": zhCn,
        "he-IL": heIl,
      };

      countryLanguages?.forEach((item) => {
        const selected = languageSelections[item?.name];
        if (selected !== undefined) {
          tempLanguages.push({
            id: item?.id,
            name: item?.name,
            selected: selected,
          });
        }
      });
      formData.id = countryId;
      const res = await updateCountryLanguageService(formData, tempLanguages);
      if (res?.status) {
        setLoading(false);
        notifySuccess("Updated Country Languages Successfully !");
        getCountryById(countryId);
      }
    } catch (err) {
      setLoading(false);
      requestFailed(err?.message);
      console.log("Error: ", err?.message);
    }
  };

  const getAllCountriesName = async () => {
    try {
      setLoading(true);
      const res = await getAllNewCountriesService();
      const list = res?.data?.result?.filter((item) => item?.isActive === true);
      if (res?.status) {
        setLoading(false);
        setCountriesNames(list);
        setUnchangedList(list);
        const countryId = list[list?.length - 1]?.id;
        setCountryId(countryId);
        getCountryById(countryId);
      }
    } catch (err) {
      console.log("Error : ", err.message);
    }
  };

  const addCountry = async () => {
    setLoading(true);
    try {
      const res = await addNewCountryService(countryCode, countryName);
      if (res?.status) {
        setLoading(false);
        if (res?.data?.status === "Failed") {
          notifyDuplicate();
        } else {
          notifySuccess("Country Added Successfully !");
        }
        getAllCountriesName();
        setCountry({
          countryCode: "",
          countryName: "",
        });
      }
    } catch (err) {
      setLoading(false);
      console.log("Error: ", err?.message);
      requestFailed(err?.message);
    }
  };

  const getCountryById = async (id) => {
    setLoading(true);
    try {
      const res = await getCountryByIdService(id);
      if (res?.status) {
        const item = res?.data?.result;
        setFormData((prevData) => ({
          ...prevData,
          dob: item?.patientDobformat,
          formCountryCode: item?.shortName,
          lastUpdated: item?.lastUpdate,
          name: item?.name,
          notes: item?.notes,
          recordDob: item?.recordPatientDob,
          recordGender: item?.recordPatientGender,
          recordPatient: item?.recordPatientInitials,
          region: item?.region,
          subjectCode: item?.useSubjectCode,
          useHour: item?.useTwentyFourHourTime,
        }));
        setLoading(false);
        reset();
        resetLanguage();
      }
    } catch (err) {
      setLoading(false);
      requestFailed(err?.message);
      console.log("Error: ", err.message);
    }
  };

  // console.log("countriesName", countriesNames);

  const handleSearch = (e) => {
    const value = e?.target?.value;
    setSearchedCountry(value);
    if (value === "") {
      setCountriesNames(unchangedList);
    } else {
      let tempValue = value.toLocaleLowerCase();
      const items = countriesNames?.filter(
        (item) =>
          item?.name?.toLocaleLowerCase()?.includes(tempValue) ||
          item?.shortName?.toLocaleLowerCase()?.includes(tempValue)
      );
      setCountriesNames(items);
    }
  };

  const resetSearch = () => {
    setSearchedCountry("");
    setCountriesNames(unchangedList);
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          <BeatLoader color="#3661eb" />
        </div>
      ) : (
        <div className="content-body">
          <p className="study-management-link" style={{ fontWeight: "600" }}>
            <Link to="/study-management">Manage Your Study</Link> |{" "}
            <Link to="/study-management/countries">Countries</Link>-{" "}
            <b style={{ color: "gray" }}>
              {sessionStorage?.getItem("studyName")}
            </b>
          </p>
          <div
            style={{
              display: "flex",
              gap: "30px",
              width: "75vw",
              padding: "20px",
            }}
          >
            <div className="" style={{ width: "25vw" }}>
              <div className="flex flex-col justify-between rounded-md border">
                <div className="px-4 py-3 rounded-t-md">
                  <h4>Added Countries</h4>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      width: "100%",
                    }}
                  >
                    <TextField
                      value={searchedCountry}
                      placeholder="Search Countries"
                      style={{
                        width: "240px",
                        marginLeft: "5px",
                        fontSize: "15px",
                      }}
                      onChange={(e) => handleSearch(e)}
                      inputProps={{
                        style: textSmallStyles,
                      }}
                    ></TextField>
                    {searchedCountry !== "" && (
                      <Button
                        variant="outlined"
                        className="pt-3"
                        onClick={resetSearch}
                      >
                        Clear
                      </Button>
                    )}
                  </div>
                </div>
                <div className="country-sidebar" style={{ height: "55vh" }}>
                  <ListGroup className={`!px-[10px] py-3 pt-5`}>
                    {countriesNames &&
                      countriesNames?.length > 0 &&
                      countriesNames.map((item, index) => (
                        <ListGroup.Item
                          key={index}
                          onClick={() => {
                            setCountryId(item?.id);
                            getCountryById(item?.id);
                          }}
                          style={{
                            backgroundColor:
                              countryId === item.id ? "#bfdbfe" : "transparent",
                          }}
                          className={`mt-4 bg-blue cursor-pointer select-none hover:bg-blue-100 transition-colors duration-300 `}
                        >
                          {item?.name}
                        </ListGroup.Item>
                      ))}
                  </ListGroup>
                </div>
                <div>
                  <DialogBox
                    showDialog={showPrompt}
                    confirmNavigation={confirmNavigation}
                    cancelNavigation={cancelNavigation}
                  />
                </div>
                {/* <hr /> */}
                {/* <div className="p-3 ms-1">
                  <p className="ms-4">Country Code</p>
                  <TextField
                    style={textBoxBody}
                    onChange={(e) => {
                      setCountry((prev) => ({
                        ...prev,
                        countryCode: e.target.value,
                      }));
                    }}
                    inputProps={{
                      style: textSmallStyles,
                    }}
                  />
                </div>
                <div className="p-3 ms-1">
                  <p className="ms-4">Country Name</p>
                  <TextField
                    style={textBoxBody}
                    onChange={(e) => {
                      setCountry((prev) => ({
                        ...prev,
                        countryName: e.target.value,
                      }));
                    }}
                    inputProps={{
                      style: textSmallStyles,
                    }}
                  />
                </div>

                <div className="m-auto flex flex-col px-4 py-2 mb-2 mt-4">
                  <button
                    onClick={addCountry}
                    className={
                      countryName === "" || countryCode === ""
                        ? "create-btn-disabled"
                        : "create-btn"
                    }
                    disabled={
                      countryName === "" || countryCode === "" ? true : false
                    }
                  >
                    Add Country
                  </button>
                </div> */}
              </div>
            </div>
            <div
              className="forms-tab"
              style={{
                width: "47vw",
              }}
            >
              <Tabs
                value={value}
                onChange={handleTabChange}
                aria-label="basic tabs example"
                style={{
                  width: "fit-content",
                }}
              >
                <Tab label="Configuration" {...a11yProps(0)} />
                <Tab label="Languages" {...a11yProps(1)} />
                <Tab label="PMS Countries" {...a11yProps(2)} />
              </Tabs>
              <TabPanel value={value} index={0}>
                <Box sx={{ width: "100%" }}>
                  <h4 className="mb-2" style={{ color: "grey" }}>
                    Country Code : {formData?.formCountryCode}
                  </h4>
                  <div className="customDivider"></div>
                  <h5 className="mb-3" style={{ color: "gray" }}>
                    Last Updated :{" "}
                    {moment(formData?.lastUpdated).format("DD/MM/YYYY hh:mma")}
                  </h5>
                  <Box
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                    autoComplete="false"
                  >
                    <div className="createVisitNewInput">
                      <p>
                        Name
                        <span className="error-highlight">*</span>
                      </p>
                      <TextField
                        value={formData?.name}
                        style={textBoxBody}
                        inputProps={{
                          style: textBoxStyles,
                        }}
                        {...register("name", {
                          onChange: (e) => {
                            setFormData((prevData) => ({
                              ...prevData,
                              name: e.target?.value,
                            }));
                            setShowDialog(true);
                          },
                        })}
                      />
                    </div>
                    {errors.name && (
                      <>
                        <div className="createVisitFlexEnd">
                          <div className="createVisitError">
                            <span
                              className="error-text"
                              style={{ color: "red", marginTop: "35px" }}
                            >
                              {errors.name.message}
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="createVisitNewInput mt-5">
                      <p>
                        Notes
                        <span className="error-highlight">*</span>
                      </p>
                      <TextField
                        value={formData?.notes}
                        style={textBoxBody}
                        inputProps={{
                          style: textBoxStyles,
                        }}
                        {...register("notes", {
                          onChange: (e) => {
                            setFormData((prevData) => ({
                              ...prevData,
                              notes: e.target.value,
                            }));
                            setShowDialog(true);
                          },
                        })}
                      />
                    </div>
                    {errors.notes && (
                      <>
                        <div className="createVisitFlexEnd">
                          <div className="createVisitError">
                            <span
                              className="error-text"
                              style={{ color: "red", marginTop: "35px" }}
                            >
                              {errors.notes.message}
                            </span>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="createVisitNewInput mt-5">
                      <p>
                        Region
                        <span className="error-highlight">*</span>
                      </p>
                      <TextField
                        type="number"
                        value={formData?.region}
                        style={textBoxBody}
                        inputProps={{
                          style: textBoxStyles,
                        }}
                        {...register("region", {
                          onChange: (e) => {
                            setFormData((prevData) => ({
                              ...prevData,
                              region: e.target?.value,
                            }));
                            setShowDialog(true);
                          },
                        })}
                      />
                    </div>
                    {errors.region && (
                      <>
                        <div className="createVisitFlexEnd">
                          <div className="createVisitError">
                            <span
                              className="error-text"
                              style={{ color: "red", marginTop: "35px" }}
                            >
                              {errors.region.message}
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="createVisitNewInput">
                      <p style={labelStyles}>
                        DOB Format {/* {errors.name?.message ? ( */}
                        <span className="error-highlight">*</span>
                        {/* ) : (
                      <></>
                    )} */}
                      </p>
                      <FormControl sx={selectStyles}>
                        <Select
                          name="dobFormat"
                          // defaultValue={""}
                          value={
                            dateFormats?.find(
                              (item) => item?.id == formData?.dob
                            )?.id
                          }
                          inputProps={{
                            style: textBoxStyles,
                            "aria-label": "Without label",
                          }}
                          {...register("dobFormat", {
                            onChange: (e) => {
                              setFormData((prevData) => ({
                                ...prevData,
                                dob: e.target?.value,
                              }));
                              setValue("dobFormat", e.target.value, {
                                shouldValidate: true,
                              });
                              setShowDialog(true);
                            },
                          })}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {dateFormats?.map((item, index) => (
                            <MenuItem value={item.id} key={index}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    {errors.dobFormat && (
                      <>
                        <div className="createVisitFlexEnd">
                          <div className="createVisitError">
                            <span
                              className="error-text"
                              style={{ color: "red", marginTop: "35px" }}
                            >
                              {errors.dobFormat.message}
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="createVisitNewCheckbox mt-4">
                      <p>Record Patient Initials</p>
                      <Checkbox
                        className="ms-5"
                        checked={formData?.recordPatient}
                        style={{ fontSize: "15px" }}
                        inputProps={{ "aria-label": "controlled" }}
                        {...register("recordPatient", {
                          onChange: (e) => {
                            setShowDialog(true);
                            setFormData((prevData) => ({
                              ...prevData,
                              recordPatient: e.target?.checked,
                            }));
                          },
                        })}
                      />
                    </div>

                    {errors.recordPatient && (
                      <>
                        <div className="createVisitFlexEnd">
                          <div className="createVisitError">
                            <span
                              className="error-text"
                              style={{ color: "red", marginTop: "35px" }}
                            >
                              {errors.recordPatient.message}
                            </span>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="createVisitNewCheckbox mt-4">
                      <p>Use Subject Code</p>
                      <Checkbox
                        style={{ fontSize: "15px" }}
                        checked={formData?.subjectCode}
                        inputProps={{ "aria-label": "controlled" }}
                        {...register("subjectCode", {
                          onChange: (e) => {
                            setShowDialog(true);
                            setFormData((prevData) => ({
                              ...prevData,
                              subjectCode: e.target?.checked,
                            }));
                          },
                        })}
                      />
                    </div>

                    {errors.subjectCode && (
                      <>
                        <div className="createVisitFlexEnd">
                          <div className="createVisitError">
                            <span
                              className="error-text"
                              style={{ color: "red", marginTop: "35px" }}
                            >
                              {errors.subjectCode.message}
                            </span>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="createVisitNewCheckbox mt-4">
                      <p>Record Patient Gender</p>
                      <Checkbox
                        checked={formData?.recordGender}
                        style={{ fontSize: "15px" }}
                        inputProps={{ "aria-label": "controlled" }}
                        {...register("patientGender", {
                          onChange: (e) => {
                            setShowDialog(true);
                            setFormData((prevData) => ({
                              ...prevData,
                              recordGender: e.target?.checked,
                            }));
                          },
                        })}
                      />
                    </div>

                    {errors.patientGender && (
                      <>
                        <div className="createVisitFlexEnd">
                          <div className="createVisitError">
                            <span
                              className="error-text"
                              style={{ color: "red", marginTop: "35px" }}
                            >
                              {errors.patientGender.message}
                            </span>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="createVisitNewCheckbox mt-4">
                      <p>Record Patient DOB</p>
                      <Checkbox
                        style={{ fontSize: "15px" }}
                        checked={formData?.recordDob}
                        inputProps={{ "aria-label": "controlled" }}
                        {...register("patientDob", {
                          onChange: (e) => {
                            setShowDialog(true);
                            setFormData((prevData) => ({
                              ...prevData,
                              recordDob: e.target?.checked,
                            }));
                          },
                        })}
                      />
                    </div>

                    {errors.patientDob && (
                      <>
                        <div className="createVisitFlexEnd">
                          <div className="createVisitError">
                            <span
                              className="error-text"
                              style={{ color: "red", marginTop: "35px" }}
                            >
                              {errors.patientDob.message}
                            </span>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="createVisitNewCheckbox mt-4">
                      <p>Use 24 Hour Time</p>
                      <Checkbox
                        checked={formData?.useHour}
                        style={{ fontSize: "15px" }}
                        inputProps={{ "aria-label": "controlled" }}
                        {...register("useHour", {
                          onChange: (e) => {
                            setShowDialog(true);
                            setFormData((prevData) => ({
                              ...prevData,
                              useHour: e.target?.checked,
                            }));
                          },
                        })}
                      />
                    </div>

                    {errors.useHour && (
                      <>
                        <div className="createVisitFlexEnd">
                          <div className="createVisitError">
                            <span
                              className="error-text"
                              style={{ color: "red", marginTop: "35px" }}
                            >
                              {errors.useHour.message}
                            </span>
                          </div>
                        </div>
                      </>
                    )}

                    <Row style={rowStyles}>
                      <Col md={8}></Col>
                      <Col md={2}>
                        <div className="study-management-head-end">
                          <button
                            onClick={(e) => {
                              setShowDialog(true);
                              if (showDialog) {
                                const confirm = window?.confirm(
                                  "You have unsaved changes,Are you sure want to leave ?"
                                );
                                if (confirm) {
                                  navigate("/study-management");
                                } else {
                                  e.preventDefault();
                                }
                              } else {
                                navigate("/study-management");
                              }
                            }}
                            className="study-management-cancel-btn"
                          >
                            Cancel
                          </button>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="">
                          <button
                            type="submit"
                            className="study-management-create-btn-md"
                          >
                            Save
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Box
                  sx={{ width: "100%" }}
                  component="form"
                  autoComplete="off"
                  onSubmit={handleSubmitLanguage(submitLanguage)}
                >
                  <div className="createVisitCheckbox mt-4">
                    <p>en-Us</p>
                    <Checkbox
                      name="enUs"
                      checked={enUs}
                      style={{ fontSize: "15px" }}
                      inputProps={{ "aria-label": "controlled" }}
                      {...registerLanguage("enUs", {
                        onChange: (e) => {
                          setShowDialog(true);
                          setEnUs(e?.target?.checked);
                        },
                      })}
                    />
                    {errorsLanguage.enUs && (
                      <div className="createVisitFlexEnd">
                        <div className="createVisitError">
                          <span
                            className="error-text"
                            style={{ color: "red", marginTop: "35px" }}
                          >
                            {errorsLanguage.enUs.message}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="createVisitCheckbox mt-4">
                    <p>es-Es</p>
                    <Checkbox
                      name="esEs"
                      checked={esEs}
                      style={{ fontSize: "15px" }}
                      inputProps={{ "aria-label": "controlled" }}
                      {...registerLanguage("esEs", {
                        onChange: (e) => {
                          setShowDialog(true);
                          setEsEs(e?.target?.checked);
                        },
                      })}
                    />
                    {errorsLanguage.esEs && (
                      <div className="createVisitFlexEnd">
                        <div className="createVisitError">
                          <span
                            className="error-text"
                            style={{ color: "red", marginTop: "35px" }}
                          >
                            {errorsLanguage.esEs.message}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="createVisitCheckbox mt-4">
                    <p>ja-JP</p>
                    <Checkbox
                      name="jaJp"
                      checked={jaJp}
                      style={{ fontSize: "15px" }}
                      inputProps={{ "aria-label": "controlled" }}
                      {...registerLanguage("jaJp", {
                        onChange: (e) => {
                          setShowDialog(true);
                          setJaJp(e?.target?.checked);
                        },
                      })}
                    />
                    {errorsLanguage.jaJp && (
                      <div className="createVisitFlexEnd">
                        <div className="createVisitError">
                          <span
                            className="error-text"
                            style={{ color: "red", marginTop: "35px" }}
                          >
                            {errorsLanguage.jaJp.message}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="createVisitCheckbox mt-4">
                    <p>zh-CN</p>
                    <Checkbox
                      name="zhCn"
                      checked={zhCn}
                      style={{ fontSize: "15px" }}
                      inputProps={{ "aria-label": "controlled" }}
                      {...registerLanguage("zhCn", {
                        onChange: (e) => {
                          setShowDialog(true);
                          setZhCn(e?.target?.checked);
                        },
                      })}
                    />
                    {errorsLanguage.zhCn && (
                      <div className="createVisitFlexEnd">
                        <div className="createVisitError">
                          <span
                            className="error-text"
                            style={{ color: "red", marginTop: "35px" }}
                          >
                            {errorsLanguage.zhCn.message}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="createVisitCheckbox mt-4">
                    <p>he-IL</p>
                    <Checkbox
                      name="heIl"
                      checked={heIl}
                      style={{ fontSize: "15px" }}
                      inputProps={{ "aria-label": "controlled" }}
                      {...registerLanguage("heIl", {
                        onChange: (e) => {
                          setShowDialog(true);
                          setHeIl(e?.target?.checked);
                        },
                      })}
                    />
                    {errorsLanguage.heIl && (
                      <div className="createVisitFlexEnd">
                        <div className="createVisitError">
                          <span
                            className="error-text"
                            style={{ color: "red", marginTop: "35px" }}
                          >
                            {errorsLanguage.heIl.message}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>

                  <Row style={rowStyles}>
                    <Col md={8}></Col>
                    <Col md={2}>
                      <div className="study-management-head-end">
                        <button
                          onClick={(e) => {
                            navigate("/study-management");
                          }}
                          className="study-management-cancel-btn"
                        >
                          Cancel
                        </button>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="">
                        <button
                          type="submit"
                          className="study-management-create-btn-md"
                        >
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={2}></TabPanel>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CountriesNew;
