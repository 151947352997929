import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useCallbackPrompt } from "../../../../hooks/useCallbackPrompt";
import DialogBox from "../../../../components/DialogBox";
import { linkButton } from "../Visits/ConfigureNavigations";
import RichTextEditor from "../../../../components/RichTextEditor";
import {
  createQuestionnaireService,
  getAllQuestionnaireDropdownsDataService,
  getBusinessRulesDropdownService,
} from "./Questionnaire";
import SubmitActionDragDrop from "../../../../components/DndComponent";
import parse from "html-react-parser";

export const selectStyles = {
  width: 530,
  marginTop: 1,
};

const CreateQuestionnaire = () => {
  const navigate = useNavigate();

  const [load, setLoad] = useState(false);

  const [showDialog, setShowDialog] = useState(false);

  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const [editorContent, setEditorContent] = useState("");
  const [translationKey, setTranslationKey] = useState("n/a");
  const [showEditorModal, setShowEditorModal] = useState(false);
  const [questionnaireTypeData, setQuestionnaireTypeData] = useState([]);
  const [visibleBusinessData, setVisibleBusinessData] = useState([]);
  const [enableBusinessData, setEnableBusinessData] = useState([]);
  const [openEndedData, setOpenEndedData] = useState([]);
  const [visitQuestionnaireData, setVisitQuestionnaireData] = useState([]);
  const [allSubmitActions, setAllSubmitActions] = useState([]);
  const [assignedSubmitActions, setAssignedSubmitActions] = useState([
    { id: "-1", displayName: "" },
  ]);

  const notify = () =>
    toast.success("Questionnaire Created Successfully", {
      toastId: "form-creation",
    });

  const requestFailed = (msg) =>
    toast.error(`${msg}`, {
      toastId: "requestFailed",
    });

  const createQuestionnaireSchema = yup.object().shape({
    // translationKey: yup.string().required("This field is required"),
    internalName: yup.string().required("This field is required"),
    questionnaireTypeId: yup.string().required("This field is required"),
    sequence: yup
      .number()
      .typeError("Value must be a number")
      .required("This field is required")
      .min(0, "Value cannot be less than 0")
      .integer("Value must be an integer"),
    isBlinded: yup.bool().oneOf([true, false]),
    displaySummaryScore: yup.bool().oneOf([true, false]),
    isDropdownNavigationEnabled: yup.bool().oneOf([true, false]),
    validateQuestionnaireOnSave: yup.bool().oneOf([true, false]),
    promptIncompletePagesOnSave: yup.bool().oneOf([true, false]),
    visibleBusinessRuleId: yup.string().required("This field is required"),
    enableBusinessRuleId: yup.string().required("This field is required"),
    openEndedBusinessRuleId: yup.string().required("This field is required"),
    enableVisitQuestionnaireId: yup.string().required("This field is required"),
    previousDaysEntry: yup
      .number()
      .typeError("Value must be a number")
      .required("This field is required")
      .min(0, "Value cannot be less than 0")
      .integer("Value must be an integer"),
    enforcePreviousDaysEntry: yup.bool().oneOf([true, false]),
    allowEdit: yup.bool().oneOf([true, false]),
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(createQuestionnaireSchema),
  });

  useEffect(() => {
    getAllDropdownData();
    getBusinessRuleData();
  }, []);

  const stripPTags = (html) => {
    // Use regex to replace <p> and </p> tags with empty string
    const cleanHtml = html.replace(/<\/?p[^>]*>/gi, ""); // Remove <p> and </p> tags
    return cleanHtml;
  };

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  const getAllDropdownData = async () => {
    setLoad(true);
    try {
      const res = await getAllQuestionnaireDropdownsDataService();
      if (res?.status) {
        setLoad(false);
        setAllSubmitActions(res?.data?.result?.allSubmitActions);
        setQuestionnaireTypeData(res?.data?.result?.allQuestionnaireTypes);
        // setVisibleBusinessData(res?.data?.result?.allBusinessRules);
        // setOpenEndedData(res?.data?.result?.allBusinessRules);
        // setEnableBusinessData(res?.data?.result?.allBusinessRules);
        setVisitQuestionnaireData(res?.data?.result?.allVisitQuestionnaires);
      }
    } catch (err) {
      setLoad(false);
      console.log("Error :", err?.message);
    }
  };

  const getBusinessRuleData = async () => {
    setLoad(true);
    try {
      const res = await getBusinessRulesDropdownService();
      if (res?.status) {
        setLoad(false);
        setVisibleBusinessData(res?.data?.result);
        setOpenEndedData(res?.data?.result);
        setEnableBusinessData(res?.data?.result);
      }
    } catch (err) {
      setLoad(false);
      console.log("Error :", err?.message);
    }
  };

  const getSubmitActionString = (array) => {
    return array
      .slice(1) // Skip the first item
      .map((item) => item.id) // Extract id
      .join(", "); // Join with commas
  };

  const onSubmit = async (data) => {
    let newData = {
      internalName: data?.internalName,
      questionnaireTypeId: data?.questionnaireTypeId,
      translationKey: translationKey,
      url: null,
      visibleBusinessRuleId: data?.visibleBusinessRuleId,
      enableBusinessRuleId: data?.enableBusinessRuleId,
      openEndedBusinessRuleId: data?.openEndedBusinessRuleId,
      isBlinded: data?.isBlinded,
      displaySummaryScore: data?.displaySummaryScore,
      isDropdownNavigationEnabled: data?.isDropdownNavigationEnabled,
      validateQuestionnaireOnSave: data?.validateQuestionnaireOnSave,
      promptIncompletePagesOnSave: data?.promptIncompletePagesOnSave,
      enableVisitQuestionnaireId: data?.enableVisitQuestionnaireId,
      previousDaysEntry: data?.previousDaysEntry,
      previousDaysEdit: 0,
      enforcePreviousDaysEntry: data?.enforcePreviousDaysEntry,
      allowEdit: data?.allowEdit,
      sequence: data?.sequence,
      syncVersion: 0,
      isDirty: true,
      submitActions: getSubmitActionString(assignedSubmitActions),
    };

    try {
      setLoad(true);
      setShowDialog(false);
      const res = await createQuestionnaireService(newData);

      if (res.status) {
        setLoad(false);
        notify();
        navigate("/study-management/questionnaires");
      }
    } catch (err) {
      setLoad(false);
      console.log("Error: ", err.message);
      requestFailed(err.message);
    }
  };
  const handleCancel = (e) => {
    e.preventDefault();
    navigate("/study-management/questionnaires");
  };

  const rowStyles = {
    marginTop: "2%",
    marginBottom: "2%",
  };

  const labelStyles = {
    display: "flex",
    alignItems: "center",
    height: "30px",
  };

  const textBoxBody = {
    marginLeft: "2px",
  };

  const textBoxStyles = {
    fontSize: 15,
    width: "500px",
    height: "10px",
  };

  return (
    <>
      {load ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <BeatLoader color="#3661eb" />
          </div>
        </>
      ) : (
        <>
          <div className="content-body">
            <p className="study-management-link" style={{ fontWeight: "600" }}>
              <Link to="/study-management">Manage Your Study</Link> |{" "}
              <Link to="/study-management/questionnaires">Questionnaire</Link> |{" "}
              <Link to="/study-management/questionnaires/create-questionnaire">
                Create Questionnaire
              </Link>{" "}
              -
              <b style={{ color: "gray" }}>
                {sessionStorage?.getItem("studyName")}
              </b>
            </p>
            <Row style={{ marginTop: "2%", marginBottom: "2%" }}>
              <Col md={6}>
                <div className="study-management-head-start">
                  <p className="study-management-heading">
                    Create Questionnaire
                  </p>
                </div>
              </Col>
              <Col md={6}></Col>
            </Row>

            <div>
              <DialogBox
                showDialog={showPrompt}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
              />
            </div>

            <Box
              component="form"
              sx={{
                height: "auto",
                width: "100%",
              }}
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              autoComplete="off"
            >
              <Row>
                <Col md={2}>
                  <p className="descriptionLabel" style={labelStyles}>
                    Translation Key
                    <span className="error-highlight">*</span>
                  </p>
                </Col>
                <Col md={10}>
                  <div>
                    <b>Current Translated Value</b>
                  </div>
                  {/* <div dangerouslySetInnerHTML={{ __html: editorContent }} /> */}
                  {/* <TextField
                    dangerouslySetInnerHTML={{ __html: editorContent }}
                    style={textBoxBody}
                    multiline
                    rows={2}
                    value={parse(stripPTags(editorContent)) || "n/a"}
                    inputProps={{
                      style: textBoxStyles,
                    }}
                    {...register("translationKey", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  /> */}
                  <div
                    style={{
                      border: "1px solid #e0e0e0",
                      borderRadius: "5px",
                      padding: "10px",
                      minHeight: "80px",
                      marginBottom: "10px",
                      width: "530px",
                      backgroundColor: "#fff", // Styling to make it look more like a "field"
                    }}
                  >
                    {translationKey}
                  </div>
                  {/* {errors.translationKey && (
                    <>
                      <div className="createCaregiverFlexEnd">
                        <div className="createCaregiverError">
                          <span className="error-text">
                            {errors.translationKey.message}
                          </span>
                        </div>
                      </div>
                    </>
                  )} */}
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col md={2}></Col>
                <Col md={10}>
                  <button
                    style={linkButton}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowEditorModal(true);
                    }}
                  >
                    Edit Translation
                  </button>
                </Col>
              </Row>

              <Row>
                <Col md={2}>
                  <p className="descriptionLabel" style={labelStyles}>
                    Internal Name
                    <span className="error-highlight">*</span>
                  </p>
                </Col>
                <Col md={10}>
                  <TextField
                    style={textBoxBody}
                    inputProps={{
                      style: textBoxStyles,
                    }}
                    {...register("internalName", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                  {errors.internalName && (
                    <>
                      <div className="createCaregiverFlexEnd">
                        <div className="createCaregiverError">
                          <span className="error-text">
                            {errors.internalName.message}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col md={2}>
                  <p className="descriptionLabel" style={labelStyles}>
                    Questionnaire Type Id
                    <span className="error-highlight">*</span>
                  </p>
                </Col>
                <Col md={10}>
                  <FormControl sx={selectStyles}>
                    <Select
                      name="questionnaireTypeId"
                      defaultValue={""}
                      inputProps={{
                        style: textBoxStyles,
                        "aria-label": "Without label",
                      }}
                      {...register("questionnaireTypeId", {
                        onChange: (e) => {
                          setValue("questionnaireTypeId", e.target.value, {
                            shouldValidate: true,
                          });
                          setShowDialog(true);
                        },
                      })}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {questionnaireTypeData &&
                        questionnaireTypeData?.map((item) => (
                          <MenuItem key={item?.id} value={item?.id}>
                            {item?.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {errors.questionnaireTypeId && (
                    <>
                      <div className="createCaregiverFlexEnd">
                        <div className="createCaregiverError">
                          <span className="error-text">
                            {errors.questionnaireTypeId.message}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col md={2}>
                  <p className="descriptionLabel" style={labelStyles}>
                    Sequence <span className="error-highlight">*</span>
                  </p>
                </Col>
                <Col md={10}>
                  <div>
                    <b>
                      This is used to order questionnaires on handheld that are
                      NOT associated with a Visit.
                    </b>
                  </div>
                  <TextField
                    type="number"
                    inputProps={{
                      style: textBoxStyles,
                    }}
                    {...register("sequence", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                  {errors.sequence && (
                    <>
                      <div className="createCaregiverFlexEnd">
                        <div className="createCaregiverError">
                          <span className="error-text">
                            {errors.sequence.message}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col md={2}>
                  <p className="descriptionLabel" style={labelStyles}>
                    IsBlinded <span className="error-highlight">*</span>
                  </p>
                </Col>
                <Col md={10}>
                  <Checkbox
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 22 },
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                    {...register("isBlinded", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                  {errors.isBlinded && (
                    <>
                      <div className="createCaregiverFlexEnd">
                        <div className="createCaregiverError">
                          <span className="error-text">
                            {errors.isBlinded.message}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col md={2}>
                  <p className="descriptionLabel" style={labelStyles}>
                    Display Summary Score{" "}
                    <span className="error-highlight">*</span>
                  </p>
                </Col>
                <Col md={10}>
                  <Checkbox
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 22 },
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                    {...register("displaySummaryScore", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                  {errors.displaySummaryScore && (
                    <>
                      <div className="createCaregiverFlexEnd">
                        <div className="createCaregiverError">
                          <span className="error-text">
                            {errors.displaySummaryScore.message}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col md={2}>
                  <p className="descriptionLabel" style={labelStyles}>
                    Is Dropdown Navigation Enabled{" "}
                    <span className="error-highlight">*</span>
                  </p>
                </Col>
                <Col md={10}>
                  <Checkbox
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 22 },
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                    {...register("isDropdownNavigationEnabled", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                  {errors.isDropdownNavigationEnabled && (
                    <>
                      <div className="createCaregiverFlexEnd">
                        <div className="createCaregiverError">
                          <span className="error-text">
                            {errors.isDropdownNavigationEnabled.message}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col md={2}>
                  <p className="descriptionLabel" style={labelStyles}>
                    Validate Questionnaire On Save{" "}
                    <span className="error-highlight">*</span>
                  </p>
                </Col>
                <Col md={10}>
                  <Checkbox
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 22 },
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                    {...register("validateQuestionnaireOnSave", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                  {errors.validateQuestionnaireOnSave && (
                    <>
                      <div className="createCaregiverFlexEnd">
                        <div className="createCaregiverError">
                          <span className="error-text">
                            {errors.validateQuestionnaireOnSave.message}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col md={2}>
                  <p className="descriptionLabel" style={labelStyles}>
                    Prompt Incomplete Pages On Save{" "}
                    <span className="error-highlight">*</span>
                  </p>
                </Col>
                <Col md={10}>
                  <Checkbox
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 22 },
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                    {...register("promptIncompletePagesOnSave", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                  {errors.promptIncompletePagesOnSave && (
                    <>
                      <div className="createCaregiverFlexEnd">
                        <div className="createCaregiverError">
                          <span className="error-text">
                            {errors.promptIncompletePagesOnSave.message}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col md={2}>
                  <p className="descriptionLabel" style={labelStyles}>
                    Visible Business Rule Id
                    <span className="error-highlight">*</span>
                  </p>
                </Col>
                <Col md={10}>
                  <FormControl sx={selectStyles}>
                    <Select
                      name="visibleBusinessRuleId"
                      defaultValue={""}
                      inputProps={{
                        style: textBoxStyles,
                        "aria-label": "Without label",
                      }}
                      {...register("visibleBusinessRuleId", {
                        onChange: (e) => {
                          setValue("visibleBusinessRuleId", e.target.value, {
                            shouldValidate: true,
                          });
                          setShowDialog(true);
                        },
                      })}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {visibleBusinessData &&
                        visibleBusinessData?.map((item) => (
                          <MenuItem value={item?.id} key={item?.id}>
                            {item?.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {errors.visibleBusinessRuleId && (
                    <>
                      <div className="createCaregiverFlexEnd">
                        <div className="createCaregiverError">
                          <span className="error-text">
                            {errors.visibleBusinessRuleId.message}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col md={2}>
                  <p className="descriptionLabel" style={labelStyles}>
                    Enable Business Rule Id
                    <span className="error-highlight">*</span>
                  </p>
                </Col>
                <Col md={10}>
                  <FormControl sx={selectStyles}>
                    <Select
                      name="enableBusinessRuleId"
                      defaultValue={""}
                      inputProps={{
                        style: textBoxStyles,
                        "aria-label": "Without label",
                      }}
                      {...register("enableBusinessRuleId", {
                        onChange: (e) => {
                          setValue("enableBusinessRuleId", e.target.value, {
                            shouldValidate: true,
                          });
                          setShowDialog(true);
                        },
                      })}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {enableBusinessData &&
                        enableBusinessData?.map((item) => (
                          <MenuItem value={item?.id} key={item?.id}>
                            {item?.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {errors.enableBusinessRuleId && (
                    <>
                      <div className="createCaregiverFlexEnd">
                        <div className="createCaregiverError">
                          <span className="error-text">
                            {errors.enableBusinessRuleId.message}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col md={2}>
                  <p className="descriptionLabel" style={labelStyles}>
                    Open Ended Business Rule Id
                    <span className="error-highlight">*</span>
                  </p>
                </Col>
                <Col md={10}>
                  <FormControl sx={selectStyles}>
                    <Select
                      name="openEndedBusinessRuleId"
                      defaultValue={""}
                      inputProps={{
                        style: textBoxStyles,
                        "aria-label": "Without label",
                      }}
                      {...register("openEndedBusinessRuleId", {
                        onChange: (e) => {
                          setValue("openEndedBusinessRuleId", e.target.value, {
                            shouldValidate: true,
                          });
                          setShowDialog(true);
                        },
                      })}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {openEndedData &&
                        openEndedData?.map((item) => (
                          <MenuItem value={item?.id} key={item?.id}>
                            {item?.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {errors.openEndedBusinessRuleId && (
                    <>
                      <div className="createCaregiverFlexEnd">
                        <div className="createCaregiverError">
                          <span className="error-text">
                            {errors.openEndedBusinessRuleId.message}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col md={2}>
                  <p className="descriptionLabel" style={labelStyles}>
                    Enable Visit Questionnaire Id
                    <span className="error-highlight">*</span>
                  </p>
                </Col>
                <Col md={10}>
                  <div>
                    <b>
                      This diary will not be available until the specified Visit
                      / Questionnaire is completed.
                    </b>
                  </div>
                  <FormControl sx={selectStyles}>
                    <Select
                      name="enableVisitQuestionnaireId"
                      defaultValue={""}
                      inputProps={{
                        style: textBoxStyles,
                        "aria-label": "Without label",
                      }}
                      {...register("enableVisitQuestionnaireId", {
                        onChange: (e) => {
                          setValue(
                            "enableVisitQuestionnaireId",
                            e.target.value,
                            {
                              shouldValidate: true,
                            }
                          );
                          setShowDialog(true);
                        },
                      })}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {visitQuestionnaireData &&
                        visitQuestionnaireData?.map((item) => (
                          <MenuItem value={item?.id} key={item?.id}>
                            {item?.visitQuestionnaireName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {errors.enableVisitQuestionnaireId && (
                    <>
                      <div className="createCaregiverFlexEnd">
                        <div className="createCaregiverError">
                          <span className="error-text">
                            {errors.enableVisitQuestionnaireId.message}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col md={2}>
                  <p className="descriptionLabel" style={labelStyles}>
                    Previous Days Entry
                    <span className="error-highlight">*</span>
                  </p>
                </Col>
                <Col md={10}>
                  <div>
                    <b>
                      This indicates how many days previous MUST be completed in
                      the scale. 0 indicates just today. This would typically
                      NOT have an available businessrule id.
                    </b>
                  </div>
                  <TextField
                    type="number"
                    style={textBoxBody}
                    inputProps={{
                      style: textBoxStyles,
                    }}
                    {...register("previousDaysEntry", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                  {errors.previousDaysEntry && (
                    <>
                      <div className="createCaregiverFlexEnd">
                        <div className="createCaregiverError">
                          <span className="error-text">
                            {errors.previousDaysEntry.message}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col md={2}>
                  <p className="descriptionLabel" style={labelStyles}>
                    Enforce Previous Days Entry{" "}
                    <span className="error-highlight">*</span>
                  </p>
                </Col>
                <Col md={10}>
                  <Checkbox
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 22 },
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                    {...register("enforcePreviousDaysEntry", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                  {errors.enforcePreviousDaysEntry && (
                    <>
                      <div className="createCaregiverFlexEnd">
                        <div className="createCaregiverError">
                          <span className="error-text">
                            {errors.enforcePreviousDaysEntry.message}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col md={2}>
                  <p className="descriptionLabel" style={labelStyles}>
                    Allow Edit <span className="error-highlight">*</span>
                  </p>
                </Col>
                <Col md={10}>
                  <Checkbox
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 22 },
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                    {...register("allowEdit", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                  {errors.allowEdit && (
                    <>
                      <div className="createCaregiverFlexEnd">
                        <div className="createCaregiverError">
                          <span className="error-text">
                            {errors.allowEdit.message}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col md={2}>
                  <p className="descriptionLabel" style={labelStyles}>
                    Submit Actions
                  </p>
                </Col>
                <Col md={10}>
                  {allSubmitActions && allSubmitActions?.length > 0 && (
                    <SubmitActionDragDrop
                      initialUnassigned={allSubmitActions}
                      assignedTasks={assignedSubmitActions}
                      setAssignedTasks={setAssignedSubmitActions}
                      gotData={true}
                    />
                  )}
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col md={8}></Col>
                <Col md={2}>
                  <div className="study-management-head-end">
                    <button
                      onClick={(e) => {
                        handleCancel(e);
                      }}
                      className="study-management-cancel-btn"
                    >
                      Cancel
                    </button>
                  </div>
                </Col>
                <Col md={2}>
                  <div className="study-management-head-end">
                    <button
                      type="submit"
                      className="study-management-create-btn-md"
                    >
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </Box>
          </div>
          {showEditorModal && (
            <div style={{ width: "400px", height: "400px", fontWeight: "600" }}>
              <Dialog open={showEditorModal} onClose={() => {}}>
                <DialogContent>
                  <div style={{ height: "500px" }}>
                    <RichTextEditor
                      value={editorContent}
                      onChange={handleEditorChange}
                    />
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    size="large"
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      setTranslationKey(parse(stripPTags(editorContent)));
                      setShowEditorModal(false);
                    }}
                  >
                    Save
                  </Button>
                  <Button size="large" variant="contained" onClick={() => {}}>
                    Save as Copy
                  </Button>
                  <Button
                    variant="outlined"
                    size="large"
                    color="error"
                    onClick={() => {
                      setEditorContent("");
                      setShowEditorModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CreateQuestionnaire;
