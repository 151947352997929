import React, { useEffect, useState } from "react";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableItem } from "./SortableItemComponent";

const SubmitActionDragDrop = ({
  initialUnassigned,
  assignedTasks,
  setAssignedTasks,
  gotData,
}) => {
  const [unassignedTasks, setUnassignedTasks] = useState([]);
  const [activeId, setActiveId] = useState(null);

  useEffect(() => {
    if (gotData) {
      setUnassignedTasks(initialUnassigned);
    }
  }, [gotData]);

  const sensors = useSensors(useSensor(PointerSensor));

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    setActiveId(null);

    if (!over) return;

    const isActiveInUnassigned = unassignedTasks.some(
      (task) => task.id === active.id
    );
    const isActiveInAssigned = assignedTasks.some(
      (task) => task.id === active.id
    );

    const isOverInUnassigned = unassignedTasks.some(
      (task) => task.id === over.id
    );
    const isOverInAssigned = assignedTasks.some((task) => task.id === over.id);

    if (isActiveInUnassigned && isOverInUnassigned) {
      const newUnassignedTasks = arrayMove(
        unassignedTasks,
        unassignedTasks.findIndex((task) => task.id === active.id),
        unassignedTasks.findIndex((task) => task.id === over.id)
      );
      setUnassignedTasks(newUnassignedTasks);
    } else if (isActiveInAssigned && isOverInAssigned) {
      const newAssignedTasks = arrayMove(
        assignedTasks,
        assignedTasks.findIndex((task) => task.id === active.id),
        assignedTasks.findIndex((task) => task.id === over.id)
      );
      setAssignedTasks(newAssignedTasks);
    }

    if (isActiveInUnassigned && !isOverInUnassigned) {
      const taskToMove = unassignedTasks.find((task) => task.id === active.id);
      setUnassignedTasks((prev) =>
        prev.filter((task) => task.id !== active.id)
      );
      setAssignedTasks((prev) => [...prev, taskToMove]);
    } else if (isActiveInAssigned && !isOverInAssigned) {
      const taskToMove = assignedTasks.find((task) => task.id === active.id);

      // Find the index in the unassignedTasks list where the item should be dropped
      const dropIndex = unassignedTasks.findIndex(
        (task) => task.id === over.id
      );

      const newUnassignedTasks = [
        ...unassignedTasks.slice(0, dropIndex),
        taskToMove,
        ...unassignedTasks.slice(dropIndex),
      ];

      setAssignedTasks((prev) => prev.filter((task) => task.id !== active.id));
      setUnassignedTasks(newUnassignedTasks);
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <div style={{ display: "flex", gap: "2rem", padding: "2rem" }}>
        {/* Unassigned Tasks */}
        <div
          style={{
            border: "1px solid #ccc",
            borderRadius: "5px",
            padding: "1rem",
            width: "500px",
          }}
        >
          <h3>Unassigned</h3>
          <hr
            style={{ height: "2px", marginTop: "-1px", background: "#e0e0e0" }}
          />
          <SortableContext
            items={unassignedTasks.map((task) => task.id)}
            strategy={verticalListSortingStrategy}
          >
            {unassignedTasks.map((task) => (
              <SortableItem
                key={task.id}
                id={task.id}
                displayName={task.displayName}
              />
            ))}
          </SortableContext>
        </div>

        {/* Assigned Tasks */}
        <div
          style={{
            border: "1px solid #ccc",
            borderRadius: "5px",
            padding: "1rem",
            width: "500px",
          }}
        >
          <h3>Assigned</h3>
          <SortableContext
            items={assignedTasks.map((task) => task.id)}
            strategy={verticalListSortingStrategy}
          >
            {assignedTasks.map((task) => (
              <SortableItem
                key={task.id}
                id={task.id}
                displayName={task.displayName}
              />
            ))}
          </SortableContext>
        </div>
      </div>

      <DragOverlay>
        {activeId ? (
          <SortableItem
            id={activeId}
            displayName={
              unassignedTasks.find((task) => task.id === activeId)
                ?.displayName ||
              assignedTasks.find((task) => task.id === activeId)?.displayName
            }
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export default SubmitActionDragDrop;
