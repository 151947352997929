import React from 'react'

const HorizontalLine = () => {
    const [count, setCount] = React.useState(0);

    React.useEffect(() => {
        var element = document.getElementById(`horizontalLine${count}`);
        element.parentNode.classList.add("active-horizontal");

        Object.keys(element).map(() => {
            setCount(count + 1);
            element.parentNode.classList.add("active-horizontal");
        });

        // var childBreaker1 = document.getElementById(`childBreaker1`);
        // if (childBreaker1) {
        //   childBreaker1.parentNode.classList.add("active");

        //   Object.keys(childBreaker1).map(() => {
        //     setCount(count + 1);
        //     childBreaker1.classList.add(`childBreaker${count}`);
        //     childBreaker1.parentNode.classList.add("active");
        //   });
        // }
    }, []);

    return (
        <div className='horizontalLine' id={`horizontalLine${count}`}></div>
    )
}

export default HorizontalLine