import * as React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import adminLogo from "../../assets/svgs/admin_logo.svg";
import arrowDown from "../../assets/svgs/arrow_down.svg";
import useClaims from "../../pages/auth/claims";
import { useParams } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";
import { useContext } from "react";
import AuthContext from "../../context/auth/AuthContext";
import { toast } from "react-toastify";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Box,
  AppBar,
  Modal,
  Badge,
  FormControl,
  InputLabel,
  Select,
  MenuItem as MuiMenuItem,
} from "@mui/material"; // Import Modal from MUI
import ChangePasswordForm from "./ChangePasswordForm";
import Avatar from "@mui/material/Avatar";
import { Button } from "@material-ui/core";
import { fontWeight } from "@mui/system";
import { ArrowBack } from "@mui/icons-material";

const tempImg = "https://xsgames.co/randomusers/assets/avatars/male/5.jpg";

const styles = {
  adminImg: {
    backgroundImage: `url(${tempImg})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
};
function Layout(props) {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [logoutUrl, setLogoutUrl] = React.useState("");
  const [userName, setUserName] = React.useState("");
  const [userRole, setUserRole] = React.useState("");
  const [openModal, setOpenModal] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedMenu, setSelectedMenu] = React.useState("");
  // const {getUserLoggedIn} = useContext(AuthContext)
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleLogout = () => {
    localStorage.removeItem("token");
    dispatch({ type: "LOGOUT" });
    navigate("/login"); // Navigate to the login page
    toast.success("Logged out successfully!");
  };

  // const { data: claims, isLoading } = useClaims();
  // let nameDict =
  //   claims?.find((claim) => claim.type === "name") ||
  //   claims?.find((claim) => claim.type === "sub");
  // let username = nameDict?.value;

  let state = useParams();
  const email = localStorage.getItem("email");
  const displayEmail = email;

  const navbarLinks = [
    { text: "Home", link: "/study-management" },
    { text: "Visits", link: "/study-management/visits" },
    { text: "Questionnaires", link: "/study-management/questionnaires" },
    { text: "Submit Actions", link: "/study-management/submit-actions" },
    {
      text: "Study Setup",
      dropdown: true,
      items: [
        { text: "Study Attributes", link: "/study-management/study-settings" },
        {
          text: "Subject Attributes",
          link: "/study-management/subject-attributes",
        },
        { text: "Countries", link: "/study-management/countries" },
        { text: "Business Rules", link: "/study-management/business-rules" },
        { text: "Calculations", link: "/study-management/calculations" },
        { text: "Mobile Alarms", link: "#" },
        { text: "Study Files", link: "#" },
        { text: "Widgets", link: "#" },
        { text: "Data Correction Workflow", link: "#" },
        // { text: "Caregiver Types", link: "#" },
        { text: "Translation Import/Export", link: "#" },
        { text: "Report Layout", link: "#" },
      ],
    },
    {
      text: "Drugs",
      dropdown: true,
      items: [
        { text: "Dispensation", link: "/study-management/dispensations" },
        { text: "Drug Type", link: "/study-management/drug-types" },
        {
          text: "Drug Return Configuration",
          link: "/study-management/drug-return",
        },
        { text: "Treatment", link: "/study-management/treatment" },
        { text: "Dose Levels", link: "/study-management/dose-levels" },
      ],
    },
    { text: "Email Builder", link: "#" },
  ];

  const location = useLocation();

  // Get role from localStorage
  const role = localStorage.getItem("role");

  // Check if the role is 'Admin' and if the URL starts with '/study-management'
  const canRenderComponent =
    role === "Admin" && location.pathname.startsWith("/study-management");

  const isStudyManagement = location.pathname.startsWith("/study-management");

  return (
    <>
      <div className="layout-body">
        <div className="layout-header">
          <Box sx={{ flexGrow: 1 }}>
            <AppBar
              position="static"
              style={{
                backgroundColor: "#3E4CF4",
                color: "black",
                // height: "110px",
                boxShadow: "none",
              }}
            >
              <div className="nav-content">
                <Row>
                  <Col
                    xs={3}
                    sm={3}
                    md={2}
                    lg={2}
                    xl={2}
                    className="flexCenterAlign"
                  >
                    {/* <Link to="/">
                      <img
                        className="admin-logo"
                        src={adminLogo}
                        alt="Genesis Logo"
                        style={{ marginTop: 5 }}
                      />
                    </Link> */}
                    {isStudyManagement ? (
                      <Link to="/study-management">
                        <img
                          className="admin-logo"
                          src={adminLogo}
                          alt="Genesis Logo"
                          style={{ marginTop: 5 }}
                        />
                      </Link>
                    ) : (
                      <Link to="/">
                        <img
                          className="admin-logo"
                          src={adminLogo}
                          alt="Genesis Logo"
                          style={{ marginTop: 5 }}
                        />
                      </Link>
                    )}
                  </Col>

                  <Col
                    xs={12} // Full-width on extra small screens
                    sm={6} // Half-width on small screens
                    md={5} // Adjust width for medium screens
                    lg={7} // As you already have for larger screens
                    xl={7}
                    className="flexCenterAlign ms-5"
                  >
                    {canRenderComponent && (
                      <div
                        className="nav-links"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontFamily: "Poppins",
                          fontWeight: "600",
                          gap: "30px", // Adjust spacing between links
                          height: "100%", // Ensure it takes up the full height of the navbar
                          paddingTop: "6px",
                          color: "white",
                          // fontWeight: "19px",
                          // fontWeight: "bolder",
                        }}
                      >
                        {navbarLinks.map((link, index) =>
                          link.dropdown ? (
                            <FormControl
                              key={index}
                              variant="outlined"
                              sx={{ marginTop: "-8px" }}
                              style={
                                link?.text === "Study Setup"
                                  ? {
                                      minWidth: 130,
                                    }
                                  : {
                                      minWidth: "auto",
                                    }
                              }
                            >
                              <InputLabel
                                shrink={false}
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "",
                                  color: "white",
                                  marginTop: "1px",
                                  fontFamily: "Poppins",
                                  fontWeight: "600",
                                }}
                              >
                                {link.text}
                              </InputLabel>
                              <Select
                                IconComponent={(props) => (
                                  <ArrowDropDownIcon
                                    sx={{
                                      color: "white",
                                      width: "40px",
                                      height: "20px",
                                      marginTop: "8px",
                                    }}
                                  />
                                )}
                                sx={{
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                  },
                                  "& .Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      border: "none", // Remove border on focus state
                                    },
                                  "& .MuiMenuItem-root:hover": {
                                    backgroundColor: "transparent", // Optional: Remove hover background color if needed
                                  },
                                }}
                                value={selectedMenu}
                                onChange={() => {}}
                                label={link.text}
                              >
                                {link.items.map((item, idx) => (
                                  <MuiMenuItem
                                    sx={{
                                      "&:focus": {
                                        outline: "none", // Removes black outline when the item is focused
                                        backgroundColor: "transparent", // Optional: Keep background unchanged on focus
                                        color: "#161616",
                                        textDecoration: "none",
                                      },
                                      "&:hover": {
                                        background: "#f9f9f9",
                                        color: "#3661EB",
                                      },
                                      margin: "5px",
                                      padding: "0px 10px",
                                      color: "#161616",
                                    }}
                                    key={idx}
                                    value={item.link}
                                    component={Link}
                                    to={item.link}
                                  >
                                    <h6>{item.text}</h6>
                                  </MuiMenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ) : (
                            <button
                              style={{ minWidth: "fit-content" }}
                              key={index}
                              onClick={() => {
                                navigate(link?.link);
                              }}
                              // component={Link}
                              // to={link.link}
                              // color="inherit"
                            >
                              {link.text}
                            </button>
                          )
                        )}
                      </div>
                    )}
                  </Col>

                  <Col
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    className="flexCenterAlign"
                  >
                    <div className="admin-info">
                      <div className="adminProfileDropDown">
                        <div className="admin-username">
                          <p className="admin-name">{userName}</p>
                          <p className="admin-role">{userRole}</p>
                        </div>

                        {/* <div
                          style={styles.adminImg}
                          className="admin-img"
                          onClick={handleClick}
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                        ></div> */}
                        <div></div>
                        <div
                          className="arrow"
                          onClick={handleClick}
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                        >
                          <Avatar
                            src="/broken-image.jpg"
                            style={{ width: 30, height: 30 }}
                          />
                        </div>
                        {/* <Badge
                          badgeContent={
                            localStorage.getItem("role") === "Admin"
                              ? "Admin"
                              : "User"
                          }
                          color="primary"
                          sx={{ cursor: "pointer" }}
                          className="badge_wrp capitalize"
                        ></Badge> */}
                      </div>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <div className="dropdown-body">
                          <div className="dropdown-head">
                            <Row
                              className="d-flex justify-content-center align-items-center"
                              style={{ padding: "5%" }}
                            >
                              <Col md={5}>
                                <div className="profileimg-body flex gap-6 items-center">
                                  {/* <img
                                    className="profile-img"
                                    src={tempImg}
                                    alt="Profile Image"
                                  /> */}
                                  <Avatar src="/broken-image.jpg" />
                                  <div className="flex-column">
                                    <h6 className="profile-name text-xl">
                                      {displayEmail}
                                    </h6>
                                    <h5>{localStorage?.getItem("role")}</h5>
                                  </div>
                                </div>
                              </Col>
                              <Col md={7}>
                                <div className="profile-info">
                                  <p className="profile-name">{userName}</p>
                                  <p className="profile-role">{userRole}</p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <div className="dropdown-content">
                            <button className="dropdownBtn">
                              Privacy Policy
                            </button>
                            <button
                              className="dropdownBtn"
                              onClick={handleOpenModal}
                            >
                              Change Password
                            </button>

                            <Modal
                              open={openModal}
                              onClose={handleCloseModal}
                              aria-labelledby="change-password-modal"
                              aria-describedby="change-password-form"
                            >
                              <div
                                style={{
                                  padding: "27px",
                                  backgroundColor: "white",
                                  margin: "150px auto",
                                  width: "35%",
                                  borderRadius: "10px",
                                }}
                              >
                                <ChangePasswordForm />
                              </div>
                            </Modal>
                            <button
                              className="dropdownBtn"
                              onClick={handleLogout}
                            >
                              Logout
                            </button>
                          </div>
                        </div>
                      </Menu>
                    </div>
                  </Col>
                </Row>
              </div>
            </AppBar>
          </Box>
        </div>
        <div className="body-wrapper">
          <Row>
            <Col md={12}>{props.children}</Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default Layout;
