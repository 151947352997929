import { dataServer } from "./axios.config";

// export const getAllSponsors = (pageNumber, pageSize, sortingType, sortingField, debouncedSearchString) => {
//   return new Promise((resolve, reject) => {
//     dataServer
//     .get(pageNumber && pageSize ? `app/Sponsor/all?pageNumber=${pageNumber}&pageSize=${pageSize}&sorting=%5B%7B"Field"%3A"${sortingField}"%2C"Order"%3A"${sortingType}"%7D%5D%20&filter=%7B"items"%3A%5B%7B"Id"%3A1%2C"columnField"%3A"Name"%2C"operatorValue"%3A"contains"%2C"value"%3A"${debouncedSearchString}"%7D%5D%2C"linkOperator"%3A"And"%2C"quickFilterLogicOperator"%3A"And"%7D` : "app/Sponsor/all")
//       .then((res) => resolve(res.data))
//       .catch((err) => reject(err));
//   });
// };

export const getAllSponsors = (pageNumber, pageSize, sortingType, sortingField, debouncedSearchString, filters) => {
  return new Promise((resolve, reject) => {
    const filterItems = (Array.isArray(filters) ? filters : []).map((filter, index) => ({
      Id: index + 1,
      columnField: filter.columnField,
      operatorValue: filter.operatorValue,
      value: filter.value,
    }));
    const filterPayload = encodeURIComponent(JSON.stringify({
      items: filterItems,
      linkOperator: "or",
      quickFilterLogicOperator: "or",
    }));
    const sortingPayload = encodeURIComponent(JSON.stringify([{
      Field: sortingField,
      sort: sortingType
    }]));
    const url = `app/Sponsor/all?pageNumber=${pageNumber}&pageSize=${pageSize}&sorting=${sortingPayload}&filter=${filterPayload}`;
    
    dataServer
      .get(url)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        const status = err.response?.status || 'Unknown Status';
        const message = err.response?.data?.message || err.message || 'An unknown error occurred';
        console.error(`API Error: Status ${status} - ${message}`);
        reject({ status, message });
      });
  });
};


export const getAllExportSponsors = () => {
  return new Promise((resolve, reject) => {
    dataServer
    .get(`app/Sponsor/SponsorList`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const deleteSponsor = (id) => {
  return new Promise((resolve, reject) => {
    dataServer
      .delete(`/Sponsor/delete-sponsor?id=${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const createSponsor = (data) => {
  var name = data.name;
  var fileUrl = data.fileUrl;

  const formData = new FormData();

  formData.append("name", name);
  formData.append("fileUrl", fileUrl);

  return new Promise((resolve, reject) => {
    dataServer
      .post("app/Sponsor", formData)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const editSponsor = (id, data) => {
  var name = data.name;
  var fileUrl = data.fileUrl;

  const formData = new FormData();

  formData.append("name", name);
  formData.append("fileUrl", fileUrl);

  return new Promise((resolve, reject) => {
    dataServer
      .put(`app/Sponsor/?id=${id}`, formData)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getSponsorImage = (img) => {
  const formData = new FormData();

  formData.append("path", img);

  return new Promise((resolve, reject) => {
    dataServer
      .get(`/app/Sponsor/image?path=${img}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const editSponsorStatus = (id, status) => {
  return new Promise((resolve, reject) => {
    dataServer
      .patch(`/app/Sponsor/status?id=${id}&status=${status}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getStudyTypes = () => {
  return new Promise((resolve, reject) => {
    dataServer
      .get(`/app/Study/studytypes`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const postExportExcel = (data) => {
  return new Promise((resolve, reject) => {
    dataServer
      .post(`/app/Export/exportExcel`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const postExportPDF = (data) => {
  return new Promise((resolve, reject) => {
    dataServer
      .post(`/app/Export/exportPdf`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getDownloadReport = (path) => {
  return new Promise((resolve, reject) => {
    dataServer
      .get(`/app/Export/downloadReport?FullFilePath=${path}`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        responseType: "arraybuffer",
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
