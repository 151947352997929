import React, { createContext, useReducer, useContext } from "react";
import doseManagementReducer, { initialState as doseInitialState } from "./DoseManagementReducer";


const DoseManagementContext = createContext();

export const DoseManagementProvider = ({ children }) => {
  const [state, dispatch] = useReducer(doseManagementReducer, doseInitialState);

  const setPageNumber = (pageNumber) => {
    dispatch({ type: "SET_PAGE_NUMBER", payload: pageNumber });
  };

  const setPageSize = (pageSize) => {
    dispatch({ type: "SET_PAGE_SIZE", payload: pageSize });
  };

  const setSorting = (sortingType, sortingField) => {
    dispatch({ type: "SET_SORTING", payload: { sortingType, sortingField } });
  };

  const setFilters = (filters) => {
    dispatch({ type: "SET_FILTERS", payload: filters });
  };

  return (
    <DoseManagementContext.Provider value={{ state, dispatch, setFilters, setPageNumber, setSorting , setPageSize }}>
      {children}
    </DoseManagementContext.Provider>
  );
};

export const useDoseManagement = () => useContext(DoseManagementContext);

export default DoseManagementContext;
