import { useQuery, useQueryClient } from "react-query";
import { userApiInstance } from "../../services";
import { useParams } from "react-router";
import { editUser, getUserById } from "../../services/users";

const useUserDetails = () => {
  const { id } = useParams(); // Extract id from route params using useParams
  const queryClient = useQueryClient();

  const key = ["user-details"];
  const { data, isLoading: isLoadingAllUsers } = useQuery(key, async () => {
    const response = await userApiInstance.getAllUsers({
      pageSize: 10,
      pageNumber: 1,
    });
    return response.result;
  });

  const { data: singleUserData, isLoading: isLoadingSingleUser } = useQuery(
    ["user", id],
    async () => {
      const response = await getUserById(id);
      return response.data.result;
    },
    {
      enabled: !!id,
    }
  );
  const updateUser = async (id, userData) => {
    try {
      await userApiInstance.updateUser({ id, data: userData });
      queryClient.invalidateQueries("user-details");
      queryClient.invalidateQueries("user");
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const totalCount = data ? data.totalCount : 0;

  return {
    singleUserData,
    userData: data,
    totalCount,
    isLoading: isLoadingAllUsers,
    isLoadingSingleUser,
    updateUser
  };
};

export default useUserDetails;
