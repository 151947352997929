// SortableItem.jsx
import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export const SortableItem = ({ id, displayName }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    padding: id !== "-1" ? "1rem" : "0px",
    margin: id !== "-1" ? "0.5rem 0" : "0px",
    backgroundColor: isDragging ? "#e0e0e0" : "#fff",
    border: "1px solid #ddd",
    borderRadius: "4px",
    cursor: id !== "-1" ? "grab" : "default",
    height: id !== "-1" ? "" : "0px",
  };

  return (
    <>
      <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
        {displayName}
      </div>
    </>
  );
};
