import React from "react";
import { DoseManagementProvider } from "./DoseManagement/DoseManagementContext";
import { DrugManagementProvider } from "./DrugManagement/DrugManagementContext";
import { TreatmentProvider } from "./Treatment/TreatmentContext";
import { SubjectAttributeManagementProvider } from "./SubjectAttribute/SubjectAttributemanagementContext";

export const StudyManagementProvider = ({ children }) => {
    return (
        <DoseManagementProvider>
            <DrugManagementProvider>
                <TreatmentProvider>
                    <SubjectAttributeManagementProvider>
                        {children}
                    </SubjectAttributeManagementProvider>
                </TreatmentProvider>
            </DrugManagementProvider>
        </DoseManagementProvider>
    );
};
