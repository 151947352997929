import { studyServer } from "../../../../services/study_axios";

export const getAllDataTypesService = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get("app/PatientAttributeConfiguration/GetDataTypes")
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getAllDrugReturnConfigService = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get("app/DrugReturnControl/GetDrugReturnControlList")
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const saveAttributesService = (data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post(
        `app/DrugReturnControl/AddAttribute?AttributeName=${data?.attributeName}&DrugReturnControlId=${data?.drugReturnControlId}&AttributeErrorMessage=${data?.attributeErrorMessage}&AttributeMinimumValue=${data?.attributeMinimumValue}&AttributeMaximumValue=${data?.attributeMaximumValue}&AttributeMaximumLength=${data?.attributeMaximumLength}&DataTypeId=${data?.dataTypeId}`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getAllDrugReturnDataService = (drugControlId) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(
        `app/DrugReturnControl/GetEditData?DrugReturnControlId=${drugControlId}&StudyRoleId=${""}`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const deleteDrugReturnAttributeService = (drugControlId, deleteId) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post(
        `app/DrugReturnControl/RemoveAttribute?DrugReturnControlId=${drugControlId}&AttributeId=${deleteId}`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const addNewNonRequestStatusServive = (data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post(`app/DrugReturnControl/AddNonRequestDetailList`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const deleteNewNonRequestStatusServive = (data) => {
  return new Promise((resolve, reject) => {
    const {
      drugReturnTypeId,
      drugStatusTypeId,
      drugReturnGroupId,
      studyRoleId,
    } = data;
    studyServer
      .get(
        `app/DrugReturnControl/RemoveStatusTypeDetail?DrugReturnTypeId=${drugReturnTypeId}&DrugStatusTypeId=${drugStatusTypeId}&StudyRoleId=${studyRoleId}&DrugReturnGroupId=${drugReturnGroupId}`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const updatePillCountService = (data) => {
  return new Promise((resolve, reject) => {
    const { drugReturnTypeId, drugStatusTypeId, enablePillCount } = data;
    studyServer
      .post(
        `app/DrugReturnControl/UpdateEnablePillCount?DrugReturnTypeId=${drugReturnTypeId}&DrugStatusTypeId=${drugStatusTypeId}&EnablePillCount=${enablePillCount}`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const updateEnableAttributesService = (data) => {
  return new Promise((resolve, reject) => {
    const { drugReturnTypeId, drugStatusTypeId, enableAttributes } = data;
    studyServer
      .post(
        `app/DrugReturnControl/UpdateEnableAttributes?DrugReturnTypeId=${drugReturnTypeId}&DrugStatusTypeId=${drugStatusTypeId}&EnableAttributes=${enableAttributes}`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const addNewRequestStatusServive = (data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post(`app/DrugReturnControl/AddRequestDetailList`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
