export const initialState = {
    doseData: [],
    pageNumber: 1,
    pageSize: 10,
    sortingType: null,
    sortingField: '',
    filters: [],
    filterEnabled: false,
    createDoseData: [],
    totalCount: 0,
    isLoading: false,
    deleteDosage: false
};

const doseManagementReducer = (state, action) => {
    switch (action.type) {
        case "SET_DOSE_DATA":
            return {
                ...state,
                doseData: action.payload,
            };
        case "SET_TOTAL_COUNT":
            return {
                ...state,
                totalCount: action.payload,
            };
        case "CREATE_DOSE_DATA":
            return {
                ...state,
                createDoseData: action.payload
            };
        case "DISABLE_LOADING":
            return {
                ...state,
                isLoading: false,
            };
        case "SET_PAGE_NUMBER":
            return {
                ...state,
                pageNumber: action.payload,
            };
        case "SET_PAGE_SIZE":
            return {
                ...state,
                pageSize: action.payload,
                pageNumber: 1,
            };
        case "SET_SORTING":
            return {
                ...state,
                sortingType: action.payload.sortingType,
                sortingField: action.payload.sortingField,
            };
        case "SET_FILTERS":
            return {
                ...state,
                filters: action.payload,
                pageNumber: 1,
            };
        case "DELETE_DOSAGE_DATA":
            return {
                ...state,
                deleteDosage: action.payload
            }
        default:
            return state;
    }
};

export default doseManagementReducer;
