import { studyServer } from "./study_axios";

export const getAllDrugType = (pageNumber, pageSize, sortingType, sortingField, filters) => {
  return new Promise((resolve, reject) => {
    const filterItems = (Array.isArray(filters) ? filters : []).map((filter, index) => ({
      Id: index + 1,
      columnField: filter.columnField,
      operatorValue: filter.operatorValue,
      value: filter.value,
    }));

    const filterPayload = encodeURIComponent(JSON.stringify({
      items: filterItems,
      linkOperator: "or",
      quickFilterLogicOperator: "or",
    }));

    let sortingPayload = "";
    if (sortingField) {
      sortingPayload = encodeURIComponent(JSON.stringify([{
        Field: sortingField,
        sort: sortingType
      }]));
    }

    const url = `/app/DrugTypes/GetDrugTypes?pageNumber=${pageNumber}&pageSize=${pageSize}` +
      (sortingPayload ? `&sorting=${sortingPayload}` : '') +
      `&filter=${filterPayload}`;

    studyServer
      .get(url)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        const status = err.response?.status || 'Unknown Status';
        const message = err.response?.data?.message || err.message || 'An unknown error occurred';
        console.error(`API Error: Status ${status} - ${message}`);
        reject({ status, message });
      });
  });
};

export const getAllExportDrugs = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`/app/DrugTypes/GetDrugTypeList`,)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const deleteDrugs = (id) => {
  return new Promise((resolve, reject) => {
    studyServer.get(`/app/DrugTypes/DeleteDrugType`, {
      params: { id }
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err))
  })
}