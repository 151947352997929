import React from "react";
import { SystemCountryProvider } from "./SystemCountries/SystemCountryContext";

export const SystemSettingsProvider = ({ children }) => {
    return (
        <SystemCountryProvider>
            {children}
        </SystemCountryProvider>
    );
};
