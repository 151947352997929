import { studyServer } from "./study_axios";

export const getAllSubmitActions = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get("/app/SubmitAction/GetAllSubjectActionData")
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getSubmitActionById = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`/app/SubmitAction/GetById?Id=${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const createSubmitAction = (data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post(`/app/SubmitAction/PostSubmitAction`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const editSubmitAction = (id, data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post(`/app/SubmitAction/PutSubmitAction?Id=${id}`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const deleteSubmitAction = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`/app/SubmitAction/DeleteById?Id=${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getTypeNamesDataService = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get("/app/SubmitAction/GetTypeNames")
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
