import { dataServer } from "./axios.config";

// export const getAllUsers = (pageNumber, pageSize, sortingType, sortingField, debouncedSearchString) => {
//   return new Promise((resolve, reject) => {
//     dataServer
//     .get(`/app/User/getUsers?pageNumber=${pageNumber}&pageSize=${pageSize}&sorting=%5B%7B"Field"%3A"${sortingField}"%2C"Order"%3A"${sortingType}"%7D%5D%20&filter=%7B"items"%3A%5B%7B"Id"%3A1%2C"columnField"%3A"firstName"%2C"operatorValue"%3A"contains"%2C"value"%3A"${debouncedSearchString}"%7D%5D%2C"linkOperator"%3A"And"%2C"quickFilterLogicOperator"%3A"And"%7D`)
//       .then((res) => resolve(res))
//       .catch((err) => reject(err));
//   });
// };

export const getAllUsers = (
  pageNumber,
  pageSize,
  sortingType,
  sortingField,
  debouncedSearchString,
  filters
) => {
  return new Promise((resolve, reject) => {
    const filterItems = (Array.isArray(filters) ? filters : []).map(
      (filter, index) => ({
        Id: index + 1,
        columnField: filter.columnField,
        operatorValue: filter.operatorValue,
        value: filter.value,
      })
    );
    const filterPayload = encodeURIComponent(
      JSON.stringify({
        items: filterItems,
        linkOperator: "or",
        quickFilterLogicOperator: "or",
      })
    );
    const sortingPayload = encodeURIComponent(
      JSON.stringify([
        {
          Field: sortingField,
          sort: sortingType,
        },
      ])
    );
    const url = `/app/User/getUsers?pageNumber=${pageNumber}&pageSize=${pageSize}&sorting=${sortingPayload}&filter=${filterPayload}`;
    dataServer
      .get(url)
      .then((res) => {
        console.log("API Response:", res);
        resolve(res.data);
      })
      .catch((err) => {
        console.error("API Error:", err);
        reject(err);
      });
  });
};

export const getAllExportUsers = () => {
  return new Promise((resolve, reject) => {
    dataServer
      .get(`app/User/UserList`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getUserById = (id) => {
  return new Promise((resolve, reject) => {
    dataServer
      .get(`/app/User/getUserById?id=${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const registerUser = (data) => {
  return new Promise((resolve, reject) => {
    dataServer
      .post("/app/User/createUser", data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const registerAdminNew = (data) => {
  return new Promise((resolve, reject) => {
    dataServer
      .post("/app/User/createUser", data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const editUser = (id, data) => {
  return new Promise((resolve, reject) => {
    dataServer
      .put(`/app/User/updateUser?id=${id}`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const deleteUser = (id) => {
  return new Promise((resolve, reject) => {
    dataServer
      .delete(`/Users/delete-user?id=${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const editUserStatus = (id, status) => {
  const isActive = status === null ? true : false;

  return new Promise((resolve, reject) => {
    dataServer
      .patch(`/app/User/userStatus?id=${id}&status=${isActive}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getSponsorBasedStudy = (id) => {
  return new Promise((resolve, reject) => {
    dataServer
      .get(`/app/Sponsor/${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getStudyName = (id) => {
  return new Promise((resolve, reject) => {
    dataServer
      .get(`/app/Study/${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getEnrolledStudiesDataService = (id) => {
  return new Promise((resolve, reject) => {
    dataServer
      .get(`/app/User/GetStudyRoleAndSiteData?UserId=${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const saveSponsorsAndGetStudiesService = (id, sponsors) => {
  return new Promise((resolve, reject) => {
    dataServer
      .post(`/app/User/SaveSponsorsAndGetStudies?UserId=${id}`, sponsors)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getSiteAndRoleDataService = (id, studyAPiUrl) => {
  return new Promise((resolve, reject) => {
    dataServer
      .get(
        `/app/User/GetStudyRoleAndSiteData?UserGuid=${id}&studyAPiUrl=${studyAPiUrl}`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const updateStudyAndSiteDataService = (id, data) => {
  return new Promise((resolve, reject) => {
    dataServer
      .put(`/app/User/updateUser?Id=${id}`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
