import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { Box, TextField, Checkbox, Select, MenuItem } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useCallbackPrompt } from "../../../../hooks/useCallbackPrompt";
import DialogBox from "../../../../components/DialogBox";
import { makeStyles } from "@mui/styles";
import TimeSelector from "./TimeSelector";
import "../StudySetup.css";
import { getAllBlindingGroup } from "../../../../services/drug-types-services/blinding-group/blinding-group";
import {
  createDrugType,
  getAllDrugPackages,
} from "../../../../services/drug-types-services/drug-types";
import { drugSchema, useStyles } from "./DrugUtils";

const CreateDrugTypes = () => {
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [blindingData, setBlindingData] = useState([]);
  const [packageData, setPackageData] = useState([]);

  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const [selectedDays, setSelectedDays] = useState("");
  const [selectedHours, setSelectedHours] = useState("");
  const [selectedMinutes, setSelectedMinutes] = useState("");

  const handleDaysChange = (event) => {
    setSelectedDays(event.target.value);
  };

  const handleHoursChange = (event) => {
    setSelectedHours(event.target.value);
  };

  const handleMinutesChange = (event) => {
    setSelectedMinutes(event.target.value);
  };

  const [selectedDaysTwo, setSelectedDaysTwo] = useState("");
  const [selectedHoursTwo, setSelectedHoursTwo] = useState("");
  const [selectedMinutesTwo, setSelectedMinutesTwo] = useState("");

  const handleDaysChangeTwo = (event) => {
    setSelectedDaysTwo(event.target.value);
  };

  const handleHoursChangeTwo = (event) => {
    setSelectedHoursTwo(event.target.value);
  };

  const handleMinutesChangeTwo = (event) => {
    setSelectedMinutesTwo(event.target.value);
  };

  const fetchBlindingGroup = async () => {
    try {
      setLoad(true);
      const res = await getAllBlindingGroup();
      setBlindingData(res?.data?.result);
    } catch (err) {
      console.log("Error: ", err);
    } finally {
      setLoad(false);
    }
  };

  const fetchDrugPackage = async () => {
    try {
      setLoad(true);
      const res = await getAllDrugPackages();
      setPackageData(res?.data?.result);
    } catch (err) {
      console.log("Error: ", err);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    fetchBlindingGroup();
    fetchDrugPackage();
  }, []);

  const classes = useStyles();

  const notify = () =>
    toast.success("Drug Type Created Successfully", {
      // theme: "colored",
      toastId: "form-creation",
    });

  const isRequired = (data) =>
    toast.warn(`${data} Are Required`, {
      // theme: "colored",
      toastId: "is-required",
    });

  const requestFailed = (msg) =>
    toast.error(`${msg}`, {
      // theme: "colored",
      toastId: "requestFailed",
    });

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(drugSchema),
  });

  const onSubmit = async (data) => {
    try {
      setLoad(true);
      setShowDialog(false);

      if (!selectedDays || !selectedDaysTwo) {
        isRequired("Days");
        return;
      } else if (!selectedHours || !selectedHoursTwo) {
        isRequired("Hours");
        return;
      } else if (!selectedMinutes || !selectedMinutesTwo) {
        isRequired("Minutes");
        return;
      }

      const newData = {
        ...data,
        allowedDurationBelowMin: {
          days: selectedDays || 0,
          hours: selectedHours || 0,
          minutes: selectedMinutes || 0,
        },
        allowedDurationAboveMax: {
          days: selectedDaysTwo || 0,
          hours: selectedHoursTwo || 0,
          minutes: selectedMinutesTwo || 0,
        },
        notes: "Test Notes",
        packageId: watch("packageId"),
      };
      const res = await createDrugType(newData);
      if (res.status) {
        setLoad(false);
        notify();
        navigate("/study-management/drug-types");
      }
    } catch (err) {
      setLoad(false);
      setShowDialog(false);
      console.log("Error: ", err.message);
      requestFailed(err.message);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    navigate("/drug-types");
  };

  const loadContainerStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70vh",
  };

  const rowStyles = {
    marginTop: "2%",
    marginBottom: "2%",
  };

  const labelStyles = {
    display: "flex",
    alignItems: "center",
    height: "30px",
  };

  const textBoxBody = {
    // marginLeft: "2px",
  };

  const textBoxStyles = {
    fontSize: 15,
    width: "100% !important",
    height: "5px",
  };

  return (
    <>
      {load ? (
        <>
          <div style={loadContainerStyles}>
            <BeatLoader color="#3661eb" />
          </div>
        </>
      ) : (
        <div className="content-body">
          <p className="study-management-link" style={{ fontWeight: "600" }}>
            <Link to="/study-management">Manage Your Study</Link> |{" "}
            <Link to="/study-management/drug-types">Drug Types</Link> |{" "}
            <Link to="/study-management/create-drug-types">Create</Link> -
            <b style={{ color: "gray" }}>
              {sessionStorage?.getItem("studyName")}
            </b>
          </p>
          <Row style={rowStyles}>
            <Col md={6}>
              <div className="study-management-head-start">
                <p className="study-management-heading">Create Drug Type</p>
              </div>
            </Col>
            <Col md={6}></Col>
          </Row>

          <div>
            <DialogBox
              showDialog={showPrompt}
              confirmNavigation={confirmNavigation}
              cancelNavigation={cancelNavigation}
            />
          </div>

          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ height: "auto", width: "100%" }}
            autoComplete="off"
          >
            <Row style={rowStyles}>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Name
                  <span className="error-highlight">*</span>
                </p>
              </Col>
              <Col md={4}>
                <TextField
                  style={textBoxBody}
                  inputProps={{
                    style: textBoxStyles,
                  }}
                  {...register("name", {
                    onChange: (e) => {
                      setShowDialog(true);
                    },
                  })}
                />
                {errors.name && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.name.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>

            <Row style={rowStyles}>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Code
                  <span className="error-highlight">*</span>
                </p>
              </Col>
              <Col md={4}>
                <TextField
                  style={textBoxBody}
                  inputProps={{
                    style: textBoxStyles,
                  }}
                  {...register("code", {
                    onChange: (e) => {
                      setShowDialog(true);
                    },
                  })}
                />
                {errors.code && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.code.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>

            <Row style={rowStyles}>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Is Bulk
                  <span className="error-highlight">*</span>
                </p>
              </Col>
              <Col md={4}>
                <div className={classes.customCheckboxWrapper}>
                  <Checkbox
                    className={classes.customCheckbox}
                    inputProps={{ "aria-label": "controlled" }}
                    {...register("isBulk", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                </div>
                {errors.code && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.code.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>

            {/* <Row style={rowStyles}>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Re Supply Group
                  <span className="error-highlight">*</span>
                </p>
              </Col>
              <Col md={4}>
                <TextField
                  inputProps={{
                    style: textBoxStyles,
                  }}
                  {...register("resupplyGroup", {
                    onChange: (e) => {
                      setShowDialog(true);
                    },
                  })}
                />
                {errors.code && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.code.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row> */}

            {/* <Row style={rowStyles}>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Shipping Group
                  <span className="error-highlight">*</span>
                </p>
              </Col>
              <Col md={4}>
                <TextField
                  inputProps={{
                    style: textBoxStyles,
                  }}
                  {...register("shippingGroup", {
                    onChange: (e) => {
                      setShowDialog(true);
                    },
                  })}
                />
                {errors.code && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.code.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row> */}

            <Row style={rowStyles}>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Blinding Group
                  <span className="error-highlight">*</span>
                </p>
              </Col>
              <Col md={4}>
                <Select
                  className={classes.customSelect}
                  name="blindingGroupId"
                  defaultValue={""}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  {...register("blindingGroupId", {
                    onChange: (e) => {
                      setValue("blindingGroupId", e.target.value, {
                        shouldValidate: true,
                      });
                      setShowDialog(true);
                    },
                  })}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {blindingData?.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.id}
                    </MenuItem>
                  ))}
                </Select>
                {errors.blindingGroupId && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.blindingGroupId.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>

            <Row style={rowStyles}>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Max Returnable Pill Count
                  <span className="error-highlight">*</span>
                </p>
              </Col>
              <Col md={4}>
                <TextField
                  className={classes.input}
                  type="number"
                  style={textBoxBody}
                  inputProps={{
                    style: textBoxStyles,
                  }}
                  {...register("maxReturnablePillCount", {
                    onChange: (e) => {
                      setShowDialog(true);
                    },
                  })}
                />
                {errors.maxReturnablePillCount && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.maxReturnablePillCount.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>

            <Row style={rowStyles}>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Package
                  <span className="error-highlight">*</span>
                </p>
              </Col>
              <Col md={4}>
                <Select
                  className={classes.customSelect}
                  name="packageId"
                  defaultValue={""}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  {...register("packageId", {
                    onChange: (e) => {
                      setValue("packageId", e.target.value, {
                        shouldValidate: true,
                      });
                      setShowDialog(true);
                    },
                  })}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {packageData?.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.packageId && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.packageId.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>

            <Row style={rowStyles}>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Trigger Temperature Min
                  <span className="error-highlight">*</span>
                </p>
              </Col>
              <Col md={4}>
                <TextField
                  className={classes.input}
                  type="number"
                  style={textBoxBody}
                  inputProps={{
                    style: textBoxStyles,
                  }}
                  InputProps={{
                    inputProps: {
                      style: {
                        // Hide the default increment and decrement arrows
                        "-webkit-appearance": "none",
                        "-moz-appearance": "textfield",
                        appearance: "none",
                      },
                    },
                  }}
                  {...register("triggerTemperatureMin", {
                    onChange: (e) => {
                      setShowDialog(true);
                    },
                  })}
                />
                {errors.triggerTemperatureMin && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.triggerTemperatureMin.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>

            <Row style={rowStyles}>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Trigger Temperature Max
                  <span className="error-highlight">*</span>
                </p>
              </Col>
              <Col md={4}>
                <TextField
                  className={classes.input}
                  type="number"
                  style={textBoxBody}
                  inputProps={{
                    style: textBoxStyles,
                  }}
                  InputProps={{
                    inputProps: {
                      style: {
                        // Hide the default increment and decrement arrows
                        "-webkit-appearance": "none",
                        "-moz-appearance": "textfield",
                        appearance: "none",
                      },
                    },
                  }}
                  {...register("triggerTemperatureMax", {
                    onChange: (e) => {
                      setShowDialog(true);
                    },
                  })}
                />
                {errors.triggerTemperatureMax && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.triggerTemperatureMax.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>

            <Row style={rowStyles}>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Absolute Temperature Min
                  <span className="error-highlight">*</span>
                </p>
              </Col>
              <Col md={4}>
                <TextField
                  className={classes.input}
                  type="number"
                  style={textBoxBody}
                  inputProps={{
                    style: textBoxStyles,
                  }}
                  InputProps={{
                    inputProps: {
                      style: {
                        // Hide the default increment and decrement arrows
                        "-webkit-appearance": "none",
                        "-moz-appearance": "textfield",
                        appearance: "none",
                      },
                    },
                  }}
                  {...register("absoluteTemperatureMin", {
                    onChange: (e) => {
                      setShowDialog(true);
                    },
                  })}
                />
                {errors.absoluteTemperatureMin && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.absoluteTemperatureMin.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>

            <Row style={rowStyles}>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Absolute Temperature Max
                  <span className="error-highlight">*</span>
                </p>
              </Col>
              <Col md={4}>
                <TextField
                  className={classes.input}
                  type="number"
                  style={textBoxBody}
                  inputProps={{
                    style: textBoxStyles,
                  }}
                  InputProps={{
                    inputProps: {
                      style: {
                        // Hide the default increment and decrement arrows
                        "-webkit-appearance": "none",
                        "-moz-appearance": "textfield",
                        appearance: "none",
                      },
                    },
                  }}
                  {...register("absoluteTemperatureMax", {
                    onChange: (e) => {
                      setShowDialog(true);
                    },
                  })}
                />
                {errors.absoluteTemperatureMax && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.absoluteTemperatureMax.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>

            <Row style={rowStyles}>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Allow Duration Below Min
                  <span className="error-highlight">*</span>
                </p>
              </Col>
              <Col md={4}>
                <TimeSelector
                  selectedDays={selectedDays}
                  setSelectedDays={setSelectedDays}
                  selectedHours={selectedHours}
                  setSelectedHours={setSelectedHours}
                  selectedMinutes={selectedMinutes}
                  setSelectedMinutes={setSelectedMinutes}
                  handleDaysChange={handleDaysChange}
                  handleHoursChange={handleHoursChange}
                  handleMinutesChange={handleMinutesChange}
                />
                {/* {errors.allowedDurationBelowMin && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.allowedDurationBelowMin.message}
                        </span>
                      </div>
                    </div>
                  </>
                )} */}
              </Col>
            </Row>

            <Row style={rowStyles}>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Allow Duration Above Max
                  <span className="error-highlight">*</span>
                </p>
              </Col>
              <Col md={4}>
                <TimeSelector
                  selectedDays={selectedDaysTwo}
                  setSelectedDays={setSelectedDaysTwo}
                  selectedHours={selectedHoursTwo}
                  setSelectedHours={setSelectedHoursTwo}
                  selectedMinutes={selectedMinutesTwo}
                  setSelectedMinutes={setSelectedMinutesTwo}
                  handleDaysChange={handleDaysChangeTwo}
                  handleHoursChange={handleHoursChangeTwo}
                  handleMinutesChange={handleMinutesChangeTwo}
                />
                {/* {errors.allowedDurationAboveMax && (
                  <>
                    <div className="createCaregiverFlexEnd">
                      <div className="createCaregiverError">
                        <span className="error-text">
                          {errors.allowedDurationAboveMax.message}
                        </span>
                      </div>
                    </div>
                  </>
                )} */}
              </Col>
            </Row>

            <Row style={rowStyles}>
              <Col md={8}></Col>
              <Col md={2}>
                <div className="study-management-head-end">
                  <button
                    onClick={(e) => {
                      handleCancel(e);
                    }}
                    className="study-management-cancel-btn"
                  >
                    Cancel
                  </button>
                </div>
              </Col>
              <Col md={2}>
                <div className="study-management-head-end">
                  <button
                    type="submit"
                    className="study-management-create-btn-md"
                  >
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </Box>
        </div>
      )}
    </>
  );
};

export default CreateDrugTypes;
