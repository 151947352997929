// TreatmentReducer.js
import { SET_TREATMENT_DATA, CREATE_TREATMENT_DATA, UPDATE_TREATMENT_DATA, SET_TREATMENT_BY_ID, DISABLE_LOADING, SET_TOTAL_COUNT, SET_PAGE_NUMBER, SET_FILTERS, SET_SORTING, SET_PAGE_SIZE, DELETE_TREATMENT_DATA } from './treatmentActionTypes';

export const initialState = {
    treatmentData: [],
    pageNumber: 1,
    pageSize: 10,
    isLoading: true,
    filters: [],
    filterEnabled: false,
    createTreatmentData: [],
    treatmentById: null,
    totalCount: 0,
    sortingType: null,
    sortingField: 'description',
};

const treatmentReducer = (state, action) => {
    switch (action.type) {
        case SET_TREATMENT_DATA:
            return {
                ...state,
                treatmentData: action.payload,
            };
        case CREATE_TREATMENT_DATA:
            return {
                ...state,
                createTreatmentData: action.payload
            };
        case UPDATE_TREATMENT_DATA:
            return {
                ...state,
                treatmentData: state.treatmentData.map(treatment =>
                    treatment.id === action.payload.id
                        ? { ...treatment, ...action.payload }
                        : treatment
                )
            };
        case SET_TREATMENT_BY_ID:
            return {
                ...state,
                treatmentById: action.payload
            };
        case SET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: action.payload,
            };

        case DISABLE_LOADING:
            return {
                ...state,
                isLoading: false,
            };
        case SET_PAGE_NUMBER:
            return {
                ...state,
                pageNumber: action.payload,
            };
        case SET_PAGE_SIZE:
            return {
                ...state,
                pageSize: action.payload,
                pageNumber: 1,
            };
        case SET_SORTING:
            return {
                ...state,
                sortingType: action.payload.sortingType,
                sortingField: action.payload.sortingField,
            };
        case SET_FILTERS:
            return {
                ...state,
                filters: action.payload,
                pageNumber: 1,
            };
        case DELETE_TREATMENT_DATA:
            return {
                ...state,
                filters: action.payload,
            }
        default:
            return state;
    }
};

export default treatmentReducer;
