import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { Box, TextField, Checkbox, Autocomplete } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import BeatLoader from "react-spinners/BeatLoader";
import { useCallbackPrompt } from "../../../../hooks/useCallbackPrompt";
import DialogBox from "../../../../components/DialogBox";

import { createSystemLanaguage } from "../../../../services/system_language";
// import { getAllSystemCountries } from "../../../../services/system_country";

import "../../Users/User.css";
import { dataServer } from "../../../../services/axios.config";
import { rowStyles } from "../../StudySetup/Visits/Questionnaires/EditVisitQuestionnaire";

const AddLanguages = () => {
  const navigate = useNavigate();

  const [load, setLoad] = React.useState(false);

  const [showDialog, setShowDialog] = React.useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);
  const [systemCountries, setSystemCountries] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState(null);

  const notify = () =>
    toast.success("System Language Added Successfully", {
      toastId: "form-creation",
    });

  const requestFailed = () =>
    toast.error("Something went wrong", {
      toastId: "requestFailed",
    });

  const createLanguageSchema = yup.object().shape({
    cultureName: yup.string().required("This field is required"),
    name: yup.string().required("This field is required"),
    displayName: yup.string().required("This field is required"),
    systemCountryId: yup.string().required("This field is required"),
    isRightToLeft: yup.bool().oneOf([true, false], "Right to left is required"),
  });

  const {
    register,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(createLanguageSchema),
  });

  const onSubmit = async (data) => {
    try {
      setLoad(true);
      setShowDialog(false);
      const payload = {
        ...data,
        systemCountryId: selectedCountry ? selectedCountry.id : "",
        isActive: true,
      };
      const res = await createSystemLanaguage(payload);
      if (res.status) {
        setLoad(false);
        navigate("/system-settings");
        notify();
      }
    } catch (err) {
      setLoad(false);
      requestFailed();
      console.log("Error: ", err.message);
    }
  };

  const getAllSystemCountries = (
    pageNumberRegions,
    pageSizeRegions,
    sortingField,
    sortingType,
    debouncedSearchString
  ) => {
    return new Promise((resolve, reject) => {
      dataServer
        .get(
          pageNumberRegions && pageSizeRegions
            ? `/app/SystemCountry/GetSystemCountries?pageNumber=${pageNumberRegions}&pageSize=${pageSizeRegions}&sorting=%5B%7B"Field"%3A"${sortingField}"%2C"Order"%3A"${sortingType}"%7D%5D%20&filter=%7B"items"%3A%5B%7B"Id"%3A1%2C"columnField"%3A"name"%2C"operatorValue"%3A"contains"%2C"value"%3A"${debouncedSearchString}"%7D%5D%2C"linkOperator"%3A"And"%2C"quickFilterLogicOperator"%3A"And"%7D`
            : `/app/SystemCountry/GetSystemCountries?pageNumber=1&pageSize=10000000`
        )
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  const fetchSystemCountries = async () => {
    try {
      setLoad(true);
      const res = await getAllSystemCountries();
      console.log(res, "region data");
      if (res?.data?.status === "Success" && res?.data?.result) {
        setSystemCountries(
          res?.data.result.map((item, index) => ({ ...item, key: index }))
        );
      } else {
        console.error("Failed to fetch regions");
      }
    } catch (error) {
      console.error("An error occurred while fetching the regions", error);
    } finally {
      setLoad(false);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    navigate("/system-settings");
  };

  React.useEffect(() => {
    fetchSystemCountries();
  }, []);

  const handleCountryChange = (event, value) => {
    setSelectedCountry(value);
    setValue("systemCountryId", value ? value.id : "", {
      shouldValidate: true,
    });
    setShowDialog(true);
  };

  return (
    <>
      {load ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          <BeatLoader color="#3661eb" />
        </div>
      ) : (
        <div className="content-body">
          <p className="admin-link" style={{ fontWeight: "600" }}>
            <Link to="/homepage">Home</Link>
            {" > "}
            <Link to="/system-settings">System Settings</Link>
            {" > "}
            <Link to="/system-settings/languages-add">Add Language</Link>
          </p>

          <div>
            <DialogBox
              showDialog={showPrompt}
              confirmNavigation={confirmNavigation}
              cancelNavigation={cancelNavigation}
            />
          </div>

          <Box sx={{ marginTop: "2%" }}>
            <p className="user-heading">Add Language</p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row style={{ marginTop: "2%" }}>
                <Col md={5}>
                  <label className="uploadInputLabel">
                    Culture Name <span className="error-highlight">*</span>
                  </label>
                  <input
                    className="nameField"
                    type="text"
                    name="cultureName"
                    {...register("cultureName", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                  <span className="error-text">
                    {errors.cultureName?.message}
                  </span>
                </Col>
                <Col md={2}></Col>
                <Col md={5}>
                  <label className="uploadInputLabel">
                    Name <span className="error-highlight">*</span>
                  </label>
                  <input
                    className="nameField"
                    type="text"
                    name="name"
                    {...register("name", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                  <span className="error-text">{errors.name?.message}</span>
                </Col>
              </Row>

              <Row style={{ marginTop: "2%" }}>
                <Col md={5}>
                  <label className="uploadInputLabel">
                    Display Name <span className="error-highlight">*</span>
                  </label>
                  <input
                    className="nameField"
                    type="text"
                    name="displayName"
                    {...register("displayName", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                  <span className="error-text">
                    {errors.displayName?.message}
                  </span>
                </Col>
                <Col md={2}></Col>
                <Col md={5}>
                  <label className="uploadInputLabel">
                    Countries <span className="error-highlight">*</span>
                  </label>
                  <Controller
                    name="systemCountryId"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={systemCountries}
                        getOptionLabel={(option) => option.name || ""}
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // label="Select Country"
                            // error={!!errors.systemCountryId}
                          />
                        )}
                      />
                    )}
                  />
                  <span className="error-text">
                    {errors.systemCountryId?.message}
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: "2%" }}>
                <Col md={5}>
                  <label className="uploadInputLabel">
                    Right To Left{" "}
                    {errors.isRightToLeft?.message ? (
                      <span className="error-highlight">*</span>
                    ) : null}
                  </label>
                  <Controller
                    name="isRightToLeft"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                          setShowDialog(true);
                        }}
                      />
                    )}
                  />
                  <span className="error-text">
                    {errors.isRightToLeft?.message}
                  </span>
                </Col>
                <Col md={2}></Col>
                <Col md={5}></Col>
              </Row>

              {/* <Row style={{ marginTop: "3%" }}>
                <Col md={6}></Col>
                <Col md={6}>
                  <div className="createSponsor-buttons">
                    <button
                      className="sponsorCancelButton"
                      onClick={(e) => handleCancel(e)}
                    >
                      Cancel
                    </button>
                    <button className="sponsorCreateButton" type="submit">
                      Add
                    </button>
                  </div>
                </Col>
              </Row> */}
              <Row style={rowStyles}>
                <Col md={8}></Col>
                <Col md={2}>
                  <div className="study-management-head-end">
                    <button
                      onClick={(e) => {
                        handleCancel(e);
                      }}
                      className="study-management-cancel-btn"
                    >
                      Cancel
                    </button>
                  </div>
                </Col>
                <Col md={2}>
                  <div className="study-management-head-end">
                    <button
                      type="submit"
                      className="study-management-create-btn-md"
                    >
                      Add
                    </button>
                  </div>
                </Col>
              </Row>
            </form>
          </Box>
        </div>
      )}
    </>
  );
};

export default AddLanguages;
