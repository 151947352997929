import React, { useState, useContext, useEffect } from "react";
import UserContext from "../../../context/user/UserContext";
import PropTypes from "prop-types";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import {
  FaEdit,
  FaCheck,
  FaTimes,
  FaPencilAlt,
  FaEye,
  FaTrash,
} from "react-icons/fa";

import {
  postExportExcel,
  postExportPDF,
  getDownloadReport,
} from "../../../services/sponsors";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";
import {
  createTheme,
  ThemeProvider,
  alpha,
  styled,
} from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { Box, Paper, InputBase } from "@mui/material";
import {
  DataGridPro,
  gridClasses,
  GridToolbar,
  GridToolbarContainer,
  GridCsvExportMenuItem,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MenuItem from "@mui/material/MenuItem";
import { SearchSharp } from "@mui/icons-material";
import "./User.css";
import useUserDetails from "../../../hooks/Api/useUserDetails";
import DeleteModal from "../../../components/DeleteModal";
import { editUser, getAllExportUsers } from "../../../services/users";
import { CustomPagination } from "../../../components/CustomPagination";
import { maxWidth } from "@mui/system";
import { mapSponsorData, mapUserData } from "../../../utils/dataMapping";

const getMuiTheme = createTheme({
  typography: {
    fontSize: 25,
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        cell: {
          "&:focus": {
            outline: "none",
          },
        },
      },
    },
  },
});

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.background.default,
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    ffontWeight: "bold",
    fontSize: 18,
    color: "#000",
    backgroundColor: theme.palette.grey[300],
  },
}));

const AllUsers = () => {
  const { usersData, totalCount, fetchUsers } = useContext(UserContext);

  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortingField, setSortingField] = useState(null);
  const [sortingType, setSortingType] = useState(null);
  const [filters, setFilters] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [debouncedSearchString, setDebouncedSearchString] = useState("");
  const [userAllData, setUserAllData] = useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
    firstName: true,
    lastName: true,
    email: true,
    phoneNumber: true,
    role: true,
    actions: true,
  });

  // useEffect(() => {
  //   const handler = setTimeout(() => {
  //     setDebouncedSearchString(searchString);
  //   }, 500);

  //   return () => {
  //     clearTimeout(handler);
  //   };
  // }, [searchString]);

  useEffect(() => {
    fetchAllDataForExport();
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchString(searchString);

      // Construct the filter payload based on the search query
      const searchColumns = [
        "email",
        "firstName",
        "lastName",
        "phoneNumber",
        "role",
      ];
      const newFilters = searchColumns.map((columnField, index) => ({
        Id: index + 1,
        columnField,
        operatorValue: "contains",
        value: searchString,
      }));

      setFilters(newFilters);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchString]);

  useEffect(() => {
    if (usersData?.length > 0) {
      setLoad(false);
    }
  }, [usersData]);

  useEffect(() => {
    fetchUsers(
      pageNumber,
      pageSize,
      sortingType,
      sortingField,
      debouncedSearchString,
      filters
    );
  }, [
    pageNumber,
    pageSize,
    debouncedSearchString,
    filters,
    sortingField,
    sortingType,
  ]);

  const handlePageChange = (event, newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPageNumber(1); // Reset to first page on page size change
  };

  const navigate = useNavigate();
  const location = useLocation();
  // const userId = params.row.id
  // console.log(userId, 'lkjoi')
  // React.useEffect(() => {
  //   fetchUsers();
  //   // disableLoading();
  // }, []);

  const notify = (message) =>
    toast.success(message, {
      toastId: "deleteUsersSuccessToast",
    });
  const handleColumnVisibilityChange = (newModel) => {
    setColumnVisibilityModel(newModel);
  };

  const fetchAllDataForExport = async () => {
    setLoad(true);
    try {
      const res = await getAllExportUsers();
      if (res.status === 200) {
        const mappedData = mapUserData(res.data.result);
        setUserAllData(mappedData);
        setLoad(false);
        return res.data;
      }
    } catch (err) {
      console.log("ERROR: ", err);
      setLoad(false);
    }
    return [];
  };

  const handleFilterChange = (filterModel) => {
    const formattedFilters = filterModel.items.map((filter, index) => ({
      Id: index + 1,
      columnField: filter.columnField,
      operatorValue: filter.operatorValue,
      value: filter.value,
    }));
    setFilters(formattedFilters);
  };

  // const handleSortModelChange = (sortModel) => {
  //   console.log("Sort Model:", sortModel); // Log the entire sort model

  //   if (sortModel.length > 0) {
  //     const { field, sort } = sortModel[0];
  //     setSortingField(field);
  //     setSortingType(sort === "asc" ? "asc" : "desc");
  //   }
  // };

  const handleSortModelChange = (sortModel) => {
    if (sortModel.length > 0) {
      const { field, sort } = sortModel[0];
      setSortingField(field);
      setSortingType(sort === "asc" ? "asc" : "desc");
    } else {
      setSortingField(null);
      setSortingType(null);
    }
  };
  const handleSearch = (event) => {
    const searchValue = event.target.value;
    setSearchString(searchValue);

    // Construct the filter payload based on the search query
    const searchColumns = [
      "email",
      "firstName",
      "lastName",
      "phoneNumber",
      "role",
    ]; // Add other columns if needed
    const newFilters = searchColumns.map((columnField, index) => ({
      Id: index + 1,
      columnField,
      operatorValue: "contains",
      value: searchValue,
    }));

    setFilters(newFilters);
  };

  const rows = usersData?.map((row, index) => ({
    id: row?.id || index,
    email: row?.email || "-",
    firstName: row?.firstName || "-",
    lastName: row?.lastName || "-",
    phoneNumber: row?.phoneNumber || "-",
    role: row?.role || "-",
    // lockoutEnd: row?.lockoutEnd === null ? "Active" : "Inactive",
    // studies: row?.studies,
  }));

  const columns = [
    {
      field: "id",
      hide: columnVisibilityModel.id,
      headerName: "ID",
      width: 0,
      sortable: true,
      filterable: false,
    },
    {
      field: "firstName",
      headerName: "First Name",
      renderHeader: () => <div className="grid-heading">{"First Name"}</div>,
      renderCell: (params) => {
        const firstName = params.row.firstName;

        return (
          <div className="grid-body">
            <p>{firstName}</p>
          </div>
        );
      },
      flex: 1,
      sortable: true,
      minWidth: 220,
      hide: columnVisibilityModel.firstName,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      renderHeader: () => <div className="grid-heading">{"Last Name"}</div>,
      renderCell: (params) => {
        const lastName = params.row.lastName;

        return (
          <div className="grid-body">
            <p>{lastName}</p>
          </div>
        );
      },
      flex: 1,
      sortable: true,
      minWidth: 220,
      hide: columnVisibilityModel.lastName,
    },
    {
      field: "email",
      headerName: "Email",
      renderHeader: () => <div className="grid-heading">{"Email"}</div>,
      renderCell: (params) => {
        const email = params.row.email;

        return (
          <div className="grid-body">
            <p>{email}</p>
          </div>
        );
      },
      flex: 1,
      minWidth: 220,
      sortable: true,
      hide: columnVisibilityModel.email,
    },
    {
      field: "phoneNumber",
      headerName: "Phone",
      renderHeader: () => <div className="grid-heading">{"Phone"}</div>,
      renderCell: (params) => {
        const phoneNumber = params.row.phoneNumber;

        return (
          <div className="grid-body">
            <p>{phoneNumber}</p>
          </div>
        );
      },
      flex: 1,
      minWidth: 220,
      sortable: true,
      hide: columnVisibilityModel.phoneNumber,
    },
    {
      field: "role",
      headerName: "Role",
      renderHeader: () => <div className="grid-heading">{"Role"}</div>,
      renderCell: (params) => {
        const role = params.row.role;

        return (
          <div className="grid-body">
            <p>{role}</p>
          </div>
        );
      },
      flex: 1,
      minWidth: 220,
      sortable: true,
      hide: columnVisibilityModel.role,
    },

    {
      field: "actions",
      headerName: "Actions",
      renderHeader: () => <div className="grid-heading">{"Actions"}</div>,
      filterable: false,
      flex: 1,
      sortable: true,
      minWidth: 220,
      renderCell: (params) => {
        const id = params?.row?.id;
        const email = params.row.email;
        const firstName = params.row.firstName;
        const lastName = params.row.lastName;
        const phone = params.row.phoneNumber;
        const role = params.row.role;
        const middleName = usersData?.find(
          (item) => item?.id === params?.row?.id
        )?.middleName;
        // const status = params.row.lockoutEnd === null ? "Active" : "Inactive";
        return (
          <div>
            <button
              className="bg-green-500"
              style={{
                cursor: "pointer",
                textAlign: "center",
                padding: "8px",
                border: "none",
                borderRadius: "5px",
              }}
              onClick={async () => {
                try {
                  navigate(`/edit-user/${id}`, {
                    state: {
                      id: id,
                      email: email,
                      firstName: firstName,
                      lastName: lastName,
                      phone: phone,
                      role: role,
                      middleName: middleName,
                      // status: status,
                    },
                  });
                } catch (err) {
                  console.log("Error: ", err.message);
                }
              }}
            >
              <FaPencilAlt color="white" size={15} />
            </button>
          </div>
        );
      },
    },
  ];

  const GridExportToPDF = (props) => {
    return (
      <MenuItem
        onClick={async () => {
          try {
            const res = await postExportPDF(props.rows);
            if (res.status === 200) {
              const res2 = await getDownloadReport(res.data);
              if (res2.status === 200) {
                const blob = new Blob([res2.data], { type: "application/pdf" });
                saveAs(blob, "Users.pdf");
              }
            }
            props.hideMenu?.();
          } catch (err) {
            console.log("ERROR: ", err);
          }
        }}
      >
        Export PDF
      </MenuItem>
    );
  };

  const GridExportToExcel = (props) => {
    return (
      <MenuItem
        onClick={async () => {
          try {
            const res = await postExportExcel(props.rows);
            if (res.status === 200) {
              const res2 = await getDownloadReport(res.data);
              if (res2.status === 200) {
                const blob = new Blob([res2.data], {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                saveAs(blob, "Users.xlsx");
              }
            }
            props.hideMenu?.();
          } catch (err) {
            console.log("ERROR: ", err);
          }
        }}
      >
        Export Excel
      </MenuItem>
    );
  };

  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridExportToExcel rows={userAllData} />
      <GridExportToPDF rows={userAllData} />
    </GridToolbarExportContainer>
  );

  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <CustomExportButton />
    </GridToolbarContainer>
  );

  GridExportToExcel.propTypes = {
    hideMenu: PropTypes.func,
  };

  GridExportToPDF.propTypes = {
    hideMenu: PropTypes.func,
  };

  return (
    <ThemeProvider theme={getMuiTheme}>
      <>
        <div className="content-body">
          <p className="admin-link" style={{ fontWeight: "600" }}>
            <Link to="/homepage">Home</Link>
            {" > "}
            <span
              style={{
                color: "#4b8ac0",
                cursor: "pointer",
              }}
            >
              All Users
            </span>
          </p>

          <Row>
            <Col md={5}>
              <p className="sponsor-heading">All Users</p>
            </Col>
            <Col md={3}>
              <Paper
                component="form"
                sx={{
                  p: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <SearchSharp />
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Users"
                  inputProps={{ "aria-label": "search users" }}
                  onChange={(e) => setSearchString(e.target.value)}
                  // value={debouncedSearchString}
                />
              </Paper>
            </Col>
            <Col md={4} className="flex gap-4 justify-end">
              <div className="userButtonContainer !flex !gap-4">
                <Link to="/add-user">
                  <button
                    className="addUserButton font-extrabold"
                    style={{ borderRadius: "5px" }}
                  >
                    + Add User
                  </button>
                </Link>
              </div>
              <div className="userButtonContainer !flex !gap-4">
                <Link to="/import-study">
                  <button
                    className="addUserButton font-extrabold"
                    style={{ borderRadius: "5px" }}
                  >
                    {" "}
                    Import
                  </button>
                </Link>
              </div>
            </Col>
          </Row>

          <Box
            sx={{
              height: 669,
              width: "100%",
              "& .MuiDataGrid-columnHeaders": {
                minHeight: "58px !important",
              },
            }}
          >
            <ThemeProvider theme={getMuiTheme}>
              {/* {load ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "70vh",
                    }}
                  >
                    <BeatLoader color="#3661eb" />
                  </div>
                </>
              ) : (
                <StripedDataGrid
                  // className="allUsers-grid"
                  rows={rows}
                  columns={columns}
                  components={{ Toolbar: CustomToolbar }}
                  columnVisibilityModel={columnVisibilityModel}
                  onColumnVisibilityModelChange={handleColumnVisibilityChange}
                  disableSelectionOnClick={true}
                  experimentalFeatures={{ newEditingApi: true }}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                  }
                />
              )} */}
              {load ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "70vh",
                  }}
                >
                  <BeatLoader color="#3661eb" />
                </div>
              ) : usersData.length === 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "70vh",
                    fontSize: "24px",
                  }}
                >
                  No Results Found
                </div>
              ) : (
                <StripedDataGrid
                  // className="allUsers-grid"
                  rows={rows}
                  columns={columns}
                  components={{ Toolbar: CustomToolbar }}
                  columnVisibilityModel={columnVisibilityModel}
                  onColumnVisibilityModelChange={handleColumnVisibilityChange}
                  disableSelectionOnClick={true}
                  experimentalFeatures={{ newEditingApi: true }}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                  }
                  filterMode="server"
                  onFilterModelChange={handleFilterChange}
                  sortingMode="server"
                  sortModel={
                    sortingField && sortingType
                      ? [{ field: sortingField, sort: sortingType }]
                      : []
                  }
                  onSortModelChange={handleSortModelChange}
                />
              )}
              <CustomPagination
                currentPage={pageNumber}
                totalCount={totalCount}
                pageSize={pageSize}
                size="large"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
              />
            </ThemeProvider>
          </Box>
        </div>
      </>
    </ThemeProvider>
  );
};

export default AllUsers;
