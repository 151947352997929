import React, { useEffect, useState } from "react";
import { ReactFormBuilder } from "react-form-builder2";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import history from "../../../../../hooks/history";
import SubmitQuestionnaire from "../SubmitQuestionnaire";
import * as variables from "./variables";
import FormElementsEdit from "./form-element-edit";
import "react-form-builder2/dist/app.css";
import { toast } from "react-toastify";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import "../Questionnaire.css";
import AgeRange from "../../../../../components/FormComponents/AgeRange";
import AgeRangeYear from "../../../../../components/FormComponents/AgeRangeYear";
import AssignedDrugKit from "../../../../../components/FormComponents/AssignedDrugKit";
import Camera from "../../../../../components/FormComponents/Camera";
import CheckBox from "../../../../../components/FormComponents/CheckBox";
import Date from "../../../../../components/FormComponents/Date";
import Time from "../../../../../components/FormComponents/Time";
import DispenseDrugKit from "../../../../../components/FormComponents/DispenseDrugKit";
import DobAge from "../../../../../components/FormComponents/DobAge";
import DropDown from "../../../../../components/FormComponents/DropDown";
import DurationSpinner from "../../../../../components/FormComponents/DurationSpinner";
import EQ5D5L from "../../../../../components/FormComponents/EQ5D5L";
import HotSpot from "../../../../../components/FormComponents/HotSpot";
import ListView from "../../../../../components/FormComponents/ListView";
import MultiSelectCheckBox from "../../../../../components/FormComponents/MultiSelectCheckBox";
import None from "../../../../../components/FormComponents/None";
import NRS from "../../../../../components/FormComponents/NRS";
import NumberSpinnerNumericPad from "../../../../../components/FormComponents/NumberSpinnerNumericPad";
import NumberSpinner from "../../../../../components/FormComponents/NumberSpinner";
import NumericPad from "../../../../../components/FormComponents/NumericPad";
import PatientVisit from "../../../../../components/FormComponents/PatientVisit";
import PriorityList from "../../../../../components/FormComponents/PriorityList";
import RadioButton from "../../../../../components/FormComponents/RadioButton";
import RadioButtonGroup from "../../../../../components/FormComponents/RadioButtonGroup";
import RadioButtonImage from "../../../../../components/FormComponents/RadioButtonImage";
import ReplaceDrugKit from "../../../../../components/FormComponents/ReplaceDrugKit";
import SingleSelectCheckbox from "../../../../../components/FormComponents/SingleSelectCheckbox";
import StandardSpinnerControl from "../../../../../components/FormComponents/StandardSpinnerControl";
import TextArea from "../../../../../components/FormComponents/TextArea";
import TimeTwo from "../../../../../components/FormComponents/TimeTwo";
import VAS from "../../../../../components/FormComponents/VAS";
import VideoPlayer from "../../../../../components/FormComponents/VideoPlayer";
import VisitDatePicker from "../../../../../components/FormComponents/VisitDatePicker";
import PageBreak from "../../../../../components/FormComponents/PageBreak";
import Label from "../../../../../components/FormComponents/Label";
import Paragraph from "../../../../../components/FormComponents/Paragraph";
// import HeadingOne from "../FormComponents/HeadingOne";
// import HeadingTwo from "../FormComponents/HeadingTwo";
// import HeadingThree from "../FormComponents/HeadingThree";
import DialogBox from "../../../../../components/DialogBox";
import { useCallbackPrompt } from "../../../../../hooks/useCallbackPrompt";
import FormElementsEditAlter from "./form-element-edit-alter";

const QuestionnaireBuilder = () => {
  const navigate = useNavigate();
  const location = useLocation();

  var id = location?.state?.id;
  var formNameCreated = location?.state?.formName;

  const [formData, setFromData] = useState([]);
  const [formId, setFormId] = useState(1);
  const [items, setItems] = useState([]);
  const [load, setLoad] = useState(false);
  const [mounted, setMounted] = useState(false);

  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const alterArrayOptions = [
    "FieldSet",
    "Header",
    "Paragraph",
    "TwoColumnRow",
    "ThreeColumnRow",
  ];


  

  useEffect(() => {
    setItems([
      {
        key: "Header",
      },
      {
        key: "Paragraph",
      },
      {
        key: "FieldSet",
        canHaveAnswer: false,
        element: false,
        name: "FieldSet",
        label: "Field Set",
        icon: "fas fa-bars",
        field_name: "fieldset-element",
      },
      {
        key: "TwoColumnRow",
      },
      {
        key: "ThreeColumnRow",
      },
      {
        key: "PageBreak",
        element: "CustomElement",
        component: PageBreak,
        type: "custom",
        forwardRef: true,
        field_name: "visit_date_picker",
        name: "Page Break",
        icon: "fas fa-divide",
        props: { test: "page_break", customPageBreak: true, hide: false },
        label: "",
      },
      {
        key: "AgeRange",
        element: "CustomElement",
        component: AgeRange,
        type: "custom",
        forwardRef: true,
        field_name: "age_date_range",
        name: "Age Date Range Picker",
        icon: "fa fa-calendar",
        props: {
          test: "age_date_range",
          disabled: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          currentPage: "",
          label: "Age Range",
        },
        label: "",
      },
      {
        key: "AgeRangeYear",
        element: "CustomElement",
        component: AgeRangeYear,
        type: "custom",
        forwardRef: true,
        field_name: "age_date_range_year",
        name: "Age Select For Year Only",
        icon: "fas fa-calendar-alt",
        props: {
          test: "age_date_range_year",
          disabled: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          currentPage: "",
          label: "Age Range Year",
        },
        label: "",
      },
      {
        key: "AssignedDrugKit",
        element: "CustomElement",
        component: AssignedDrugKit,
        type: "custom",
        forwardRef: true,
        field_name: "assigned_drug_kit",
        name: "Assigned Drug Kit Selector",
        icon: "fa fa-medkit",
        props: {
          test: "assigned_drug_kit",
          disabled: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          hasDropDown: true,
          hasPageTitle: true,
          pageTitle: "",
          currentPage: "",
          label: "Assigned Drug Kit",
        },
        label: "",
      },
      {
        key: "Camera",
        element: "CustomElement",
        component: Camera,
        type: "custom",
        forwardRef: true,
        field_name: "camera",
        name: "Camera",
        icon: "fas fa-camera",
        props: {
          test: "camera",
          disabled: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          currentPage: "",
          label: "Upload Image",
        },
        label: "",
      },
      {
        key: "CheckBox",
        element: "CustomElement",
        component: CheckBox,
        type: "custom",
        forwardRef: true,
        field_name: "checkbox",
        name: "CheckBox",
        icon: "fas fa-check-square",
        props: {
          test: "checkbox",
          disabled: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          currentPage: "",
          label: "Checkbox",
        },
        label: "",
      },
      {
        key: "Date",
        element: "CustomElement",
        component: Date,
        type: "custom",
        forwardRef: true,
        field_name: "date",
        name: "date",
        icon: "far fa-calendar-alt",
        props: {
          test: "date",
          disabled: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          currentPage: "",
          label: "Date",
        },
        label: "",
      },
      {
        key: "Time",
        element: "CustomElement",
        component: Time,
        type: "custom",
        forwardRef: true,
        field_name: "time",
        name: "Time",
        icon: "fas fa-clock",
        props: {
          test: "time",
          disabled: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          currentPage: "",
          label: "Time",
        },
        label: "",
      },
      {
        key: "DispenseDrugKit",
        element: "CustomElement",
        component: DispenseDrugKit,
        type: "custom",
        forwardRef: true,
        field_name: "dispenseDrugKit",
        name: "Dispense Drug Kit Selector",
        icon: "fa fa-medkit",
        props: {
          test: "dispense_drug_kit",
          hasDropDown: true,
          hasPageTitle: true,
          pageTitle: "",
          disabled: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          currentPage: "",
          label: "Dispense Drug Kit",
        },
        label: "",
      },
      {
        key: "DobAge",
        element: "CustomElement",
        component: DobAge,
        type: "custom",
        forwardRef: true,
        field_name: "dob_age_selector",
        name: "Dob Age Selector",
        icon: "far fa-calendar-check",
        props: {
          test: "dob_age_selector",
          disabled: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          currentPage: "",
          label: "Dob Age",
        },
        label: "",
      },
      {
        key: "DropDown",
        element: "CustomElement",
        component: DropDown,
        type: "custom",
        forwardRef: true,
        field_name: "drop_down",
        name: "Dropdown",
        icon: "fas fa-caret-square-down",
        props: {
          test: "drop_down",
          hasDropDown: true,
          hasPageTitle: true,
          pageTitle: "",
          disabled: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          currentPage: "",
          label: "Drop Down",
        },
        label: "",
      },
      {
        key: "DurationSpinner",
        element: "CustomElement",
        component: DurationSpinner,
        type: "custom",
        forwardRef: true,
        field_name: "duration-spinner",
        name: "Duration Spinner",
        icon: "fas fa-circle-notch",
        props: {
          test: "duration-spinner",
          disabled: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          currentPage: "",
          label: "Duration Spinner",
        },
        label: "",
      },
      {
        key: "EQ5D5L",
        element: "CustomElement",
        component: EQ5D5L,
        type: "custom",
        forwardRef: true,
        field_name: "eq-5d-5l",
        name: "EQ-5D-5L",
        icon: "fas fa-plus",
        props: {
          test: "eq-5d-5l",
          disabled: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "EQ5D5L",
        },
        label: "",
      },
      {
        key: "HotSpot",
        element: "CustomElement",
        component: HotSpot,
        type: "custom",
        forwardRef: true,
        field_name: "hot-spot",
        name: "Hot Spot Multiple Select HotSpot Single Select",
        icon: "fas fa-rss",
        props: {
          test: "hot-spot",
          disabled: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "Hot Spot",
        },
        label: "",
      },
      {
        key: "ListView",
        element: "CustomElement",
        component: ListView,
        type: "custom",
        forwardRef: true,
        field_name: "list-view",
        name: "ListView",
        icon: "fas fa-list-ul",
        props: {
          test: "list-view",
          disabled: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "List View",
        },
        label: "",
      },
      {
        key: "MultiSelectCheckBox",
        element: "CustomElement",
        component: MultiSelectCheckBox,
        type: "custom",
        forwardRef: true,
        field_name: "multi-select-check-box",
        name: "MultiSelect CheckBox Conrol",
        icon: "fas fa-check-circle",
        props: {
          test: "multi-select-check-box",
          hasDropDown: true,
          hasPageTitle: true,
          pageTitle: "",
          disabled: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "Multi Select CheckBox",
        },
        label: "",
      },
      {
        key: "None",
        element: "CustomElement",
        component: None,
        type: "custom",
        forwardRef: true,
        field_name: "none",
        name: "None",
        icon: "fa fa-ban",
        props: {
          test: "none",
          disabled: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "None",
        },
        label: "",
      },
      {
        key: "NRS",
        element: "CustomElement",
        component: NRS,
        type: "custom",
        forwardRef: true,
        field_name: "nrs",
        name: "NRS",
        icon: "fas fa-plus",
        props: {
          test: "nrs",
          disabled: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "NRS",
        },
        label: "",
      },
      {
        key: "NumberSpinnerNumericPad",
        element: "CustomElement",
        component: NumberSpinnerNumericPad,
        type: "custom",
        forwardRef: true,
        field_name: "number_spinner_numeric_pad",
        name: "NumberSpinner NumericPad",
        icon: "fas fa-plus",
        props: {
          test: "number_spinner_numeric_pad",
          disabled: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "Number SpinnerNumeric Pad",
        },
        label: "",
      },
      {
        key: "NumberSpinner",
        element: "CustomElement",
        component: NumberSpinner,
        type: "custom",
        forwardRef: true,
        field_name: "number_spinner",
        name: "NumberSpinner",
        icon: "fas fa-spinner",
        props: {
          test: "number_spinner",
          disabled: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "Number Spinner",
        },
        label: "",
      },
      {
        key: "NumericPad",
        element: "CustomElement",
        component: NumericPad,
        type: "custom",
        forwardRef: true,
        field_name: "numeric_pad",
        name: "Numeric Pad",
        icon: "fa fa-list-ol",
        props: {
          test: "numeric_pad",
          disabled: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "Numeric Pad",
        },
        label: "",
      },
      {
        key: "PatientVisit",
        element: "CustomElement",
        component: PatientVisit,
        type: "custom",
        forwardRef: true,
        field_name: "patient_visit",
        name: "Patient Visit Drug Kit Status Selector",
        icon: "far fa-hospital",
        props: {
          test: "patient_visit",
          disabled: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "Patient Visit",
        },
        label: "",
      },
      {
        key: "PriorityList",
        element: "CustomElement",
        component: PriorityList,
        type: "custom",
        forwardRef: true,
        field_name: "priority_list",
        name: "Priority List",
        icon: "fas fa-tasks",
        props: {
          test: "priority_list",
          disabled: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "Priority List",
        },
        label: "",
      },
      {
        key: "RadioButton",
        element: "CustomElement",
        component: RadioButton,
        type: "custom",
        forwardRef: true,
        field_name: "radio_button",
        name: "RadioButton",
        icon: "far fa-dot-circle",
        props: {
          test: "radio_button",
          disabled: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "Radio Button",
        },
        label: "",
      },
      {
        key: "RadioButtonGroup",
        element: "CustomElement",
        component: RadioButtonGroup,
        type: "custom",
        forwardRef: true,
        field_name: "radio-button-group",
        name: "Radio Button Group Header",
        icon: "fas fa-list-ul",
        props: {
          test: "radio-button-group",
          disabled: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "Radio Button Group",
        },
        label: "",
      },
      {
        key: "RadioButtonImage",
        element: "CustomElement",
        component: RadioButtonImage,
        type: "custom",
        forwardRef: true,
        field_name: "radio-button-image",
        name: "Radio Button Image",
        icon: "fas fa-file-image",
        props: {
          test: "radio-button-image",
          disabled: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "Radio Button Image",
        },
        label: "",
      },
      {
        key: "ReplaceDrugKit",
        element: "CustomElement",
        component: ReplaceDrugKit,
        type: "custom",
        forwardRef: true,
        field_name: "replace-drug-kit",
        name: "Replace Drug Kit Selector",
        icon: "fas fa-tablets",
        props: {
          test: "replace-drug-kit",
          hasDropDown: true,
          hasPageTitle: true,
          pageTitle: "",
          disabled: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "Replace Drug Kit",
        },
        label: "",
      },
      {
        key: "SingleSelectCheckbox",
        element: "CustomElement",
        component: SingleSelectCheckbox,
        type: "custom",
        forwardRef: true,
        field_name: "single-select-checkbox",
        name: "Single Select Checkbox Spirobank",
        icon: "far fa-check-circle",
        props: {
          test: "single-select-checkbox",
          hasDropDown: true,
          hasPageTitle: true,
          pageTitle: "",
          disabled: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "Single Select Checkbox",
        },
        label: "",
      },
      {
        key: "StandardSpinnerControl",
        element: "CustomElement",
        component: StandardSpinnerControl,
        type: "custom",
        forwardRef: true,
        field_name: "standard-spinner-control",
        name: "Standard Spinner Control Temperature Spinner",
        icon: "fas fa-sync",
        props: {
          test: "standard-spinner-control",
          disabled: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "Standard Spinner Control",
        },
        label: "",
      },
      {
        key: "TextArea",
        element: "CustomElement",
        component: TextArea,
        type: "custom",
        forwardRef: true,
        field_name: "text-area",
        name: "TextArea",
        icon: "fa fa-font",
        props: {
          test: "text-area",
          disabled: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "Text Area",
        },
        label: "",
      },
      {
        key: "TimeTwo",
        element: "CustomElement",
        component: TimeTwo,
        type: "custom",
        forwardRef: true,
        field_name: "time-two",
        name: "TimeTwo",
        icon: "fas fa-clock",
        props: {
          test: "time-two",
          disabled: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "Time Two",
        },
        label: "",
      },
      {
        key: "VAS",
        element: "CustomElement",
        component: VAS,
        type: "custom",
        forwardRef: true,
        field_name: "vas",
        name: "VAS",
        icon: "fas fa-plus",
        props: {
          test: "vas",
          disabled: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "VAS",
        },
        label: "",
      },
      {
        key: "VideoPlayer",
        element: "CustomElement",
        component: VideoPlayer,
        type: "custom",
        forwardRef: true,
        field_name: "video_player",
        name: "Video Player",
        icon: "fa fa-play-circle",
        props: {
          test: "video_player",
          disabled: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "Video Player",
        },
        label: "",
      },
      {
        key: "VisitDatePicker",
        element: "CustomElement",
        component: VisitDatePicker,
        type: "custom",
        forwardRef: true,
        field_name: "visit_date_picker",
        name: "Visit Date Picker",
        icon: "fas fa-calendar-check",
        props: {
          test: "visit_date_picker",
          disabled: false,
          borderTop: false,
          borderBottom: false,
          borderSides: false,
          label: "Visit Date Picker",
        },
        label: "",
      },
    ]);
    setLoad(true);
    setMounted(true);
    // setShowDialog(true);
  }, []);

  function arraymove(arr, fromIndex, toIndex) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  const align = () => {
    formData.forEach((element, i) => {
      if (element.parentId) {
        arraymove(formData, i, element.parentIndex + element.col + 1);
      }
    });
    return formData;
  };

  const notify = () =>
    toast.success("Form Creation Successful", {
      toastId: "form-creation",
    });

  const formEmpty = () =>
    toast.warn("Form Data Is Emtpy", {
      toastId: "form-creation",
    });

  const requestFailed = () =>
    toast.error("Something went wrong", {
      toastId: "requestFailed",
    });

  const onSubmit = () => {
    const formSubmission = align();
    setShowDialog(false);
    if (formSubmission.length === 0 || !formSubmission) {
      formEmpty();
    } else {
      const config = {
        method: "patch",
        url: `${process.env.REACT_APP_FORM_API}/Form/design-form?id=${id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          formJson: [align()],
        },
      };

      axios(config)
        .then(() => {
          notify();
          navigate("/");
        })
        .catch((error) => {
          requestFailed();
          console.log("Form Submission Error ===> ", error);
        });
    }
  };

  const onLoad = () => {
    return new Promise((resolve) => {
      resolve(formData);
    });
  };

  const onPost = (data) => {
    
    setShowDialog(true);
    setFromData(data.task_data);
  };

  const formHandleBack = () => {
    if (showDialog === true) {
      history.push("/");
    } else {
      history.push("/qbuilder");
    }
  };

  return (
    <>
      {load ? (
        <>
          <div>
            <DialogBox
              showDialog={showPrompt}
              confirmNavigation={confirmNavigation}
              cancelNavigation={cancelNavigation}
            />
          </div>
          <Row>
            <Col md={3}>
              <div
                style={{
                  height: "100px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "Roboto",
                  fontWeight: "500",
                }}
              >
                <p style={{ fontSize: "24px" }}>
                  <i
                    onClick={formHandleBack}
                    className="fas fa-arrow-left"
                    style={{
                      fontSize: "25px",
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                  ></i>{" "}
                  Form Elements
                </p>
              </div>
            </Col>
            <Col md={3} style={{ marginTop: "15px" }}>
              
              <SubmitQuestionnaire
                variables={variables}
                formId={id}
                formData={formData}
                formName={formNameCreated}
                showDialog={showDialog}
                setShowDialog={setShowDialog}
              />
            </Col>
            <Col md={6} style={{ marginTop: "15px" }}>
              <button
                type={"submit"}
                className="customBlueFormSaveBtn float-right"
                onClick={onSubmit}
              >
                Save
              </button>
            </Col>
          </Row>

          <div className="App mt-3">
            <div>
              <ReactFormBuilder
                onLoad={onLoad}
                onPost={onPost}
                toolbarItems={items}
                renderEditForm={(props) => (
                  <>
                    {alterArrayOptions.includes(props?.element?.element) ? (
                      <>
                        <FormElementsEditAlter
                          formId={formId}
                          {...props}
                          formData={formData}
                        />
                      </>
                    ) : (
                      <>
                        <FormElementsEdit
                          formId={formId}
                          {...props}
                          formData={formData}
                        />
                      </>
                    )}
                  </>
                )}
              />
              {/* <ReactFormBuilder
                onLoad={onLoad}
                onPost={onPost}
                toolbarItems={items}
                renderEditForm={(props) => (
                  <FormElementsEdit
                    formId={formId}
                    {...props}
                    formData={formData}
                  />
                )}
              /> */}
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default QuestionnaireBuilder;
