import { useMutation, useQuery } from "react-query";
import { createNewDoseLevel, deleteDoseLevels, editDoseLevels, getAllDoseLevels, getDoseLevelById } from "../../services/dose_levels";
import { useDoseManagement } from "../../context/studyManagement/DoseManagement/DoseManagementContext";

const useDoseManagementDetails = () => {
  const { state, dispatch } = useDoseManagement();
  const { pageNumber, pageSize, sortingType, sortingField, filters } = state;

  const fetchDoseLevelData = async () => {
    try {
      const res = await getAllDoseLevels(pageNumber, pageSize, sortingType, sortingField, filters);
      if (res && res.status === "Success" && Array.isArray(res.result)) {
        const trimmedData = res.result.map((row) => ({
          id: row.id,
          name: row.name,
          isRecommended: row.isRecommended,
          isEnabled: row.isEnabled,
          mgDose: row.mgDose,
          dispensationDetails: row.dispensationDetails
        }));

        return { data: trimmedData, totalCount: res.totalCount };
      } else {
        console.error("Invalid response structure:", res);
        throw new Error("Invalid response structure");
      }
    } catch (error) {
      console.error("Error fetching dose level data:", error.message);
      throw error;
    }
  };

  const fetchDoseLevelById = async (id) => {
    try {
      const response = await getDoseLevelById(id);
      if (response && response.data) {
        return response.data; 
      } else {
        console.error("Error fetching dose level by ID:", response);
        throw new Error("Error fetching dose level by ID");
      }
    } catch (error) {
      console.error("Error fetching dose level by ID:", error.message);
      throw error;
    }
  };

  const createDose = async (doseData) => {
    try {
      console.log("Creating dose with data:", doseData);
      const res = await createNewDoseLevel(doseData);
      if (res.status === 200 && res.data && res.data.status === "200") {
        return res.data;
      } else {
        console.error("Error creating dose data:", res.data.message);
        throw new Error(res.data.message || "Error creating dose data");
      }
    } catch (error) {
      console.error("Error creating dose data:", error.message);
      throw error;
    }
  };

  const doseQuery = useQuery(
    ["dosageData", { pageNumber, pageSize, sortingType, sortingField, filters }],
    fetchDoseLevelData,
    {
      onSuccess: (data) => {
        dispatch({ type: "SET_DOSE_DATA", payload: data.data });
        dispatch({ type: "SET_TOTAL_COUNT", payload: data.totalCount });
      },
      onError: (error) => {
        console.error("Error fetching dose data:", error.message);
        dispatch({ type: "DISABLE_LOADING" });
      },
      refetchOnWindowFocus: false,
    }
  );

  const createDoseMutation = useMutation(createDose, {
    onSuccess: () => {
      console.log("Dose data created successfully.");
    },
    onError: (error) => {
      console.error("Error creating dose data:", error);
    },
  });

  const updateDoseMutation = useMutation(
    async ({ id, updatedData }) => {
      const res = await editDoseLevels(id, updatedData);
      console.log(res,"==========")
      if (res?.data?.status === "200") {
        return res;
      } else {
        throw new Error("Failed to update dose level");
      }
    },
    {
      onSuccess: () => {
        doseQuery.refetch();
      },
      onError: (error) => {
        console.error("Error updating dose level:", error.message);
      }
    }
  );

  const deleteDrugMutation = useMutation(deleteDoseLevels, {
    onSuccess: () => {
        dispatch({ type: "DELETE_DOSAGE_DATA" });
        // Optionally refetch treatment data to update the list
        doseQuery.refetch();
    },
    onError: (error) => {
        console.error("Error deleting Dose:", error.message);
    }
});

  return {
    doseQuery,
    createDoseMutation,
    updateDoseMutation,
    fetchDoseLevelById,
    deleteDrugMutation
  };
};

export default useDoseManagementDetails;
