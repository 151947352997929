/* eslint-disable */

import React, { Fragment } from "react";
import TextAreaAutosize from "react-textarea-autosize";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import DynamicOptionList from "./dynamic-option-list";
import { get } from "./requests";
import ID from "./UUID";
import { getCalculation } from "../../../../../services/calculation";
import { getBusinessRules } from "../../../../../services/business-rules";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SettingsIcon from "@mui/icons-material/Settings";
import VisibilityIcon from "@mui/icons-material/Visibility";
import WorkIcon from "@mui/icons-material/Work";
import Box from "@mui/material/Box";
import "../Questionnaire.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Fragment>{children}</Fragment>
        </Box>
      )}
    </div>
  );
}
const toolbar = {
  options: ["inline", "list", "textAlign", "fontSize", "link", "history"],
  inline: {
    inDropdown: false,
    className: undefined,
    options: ["bold", "italic", "underline", "superscript", "subscript"],
  },
};

export default class FormElementsEdit extends React.Component {
  constructor(props, formId) {
    super(props);
    this.state = {
      load: false,
      element: this.props.element,
      data: this.props.data,
      dirty: false,
      activeTab: 0,
      calculations: [],
      businessRules: [],
      filteredRules: [],
      count: 0,
      selectedRuleName: "",
    };
    this.handleAddingDivs = this.handleAddingDivs.bind(this);
  }
  componentDidMount() {
    this.getCalculationData();
    this.getBusinessRulesData();
  }
  toggleRequired() {
    // const this_element = this.state.element;
  }

  handleAddingDivs() {
    this.setState({ count: this.state.count + 1 });
  }

  editElementProp(elemProperty, targProperty, e) {
    // elemProperty could be content or label
    // targProperty could be value or checked
    const this_element = this.state.element;

    if (elemProperty === "VisibleBusinessRuleId") {
      this_element[elemProperty] = e.target[targProperty];
    } else if (elemProperty === "VisibleBusinessRuleId") {
      this_element[elemProperty] = e.target[targProperty];
    } else {
      this_element[elemProperty] = e.target[targProperty];
    }

    this.setState(
      {
        ...this.state,
        element: this_element,
        dirty: true,
      },
      () => {
        if (targProperty === "checked") {
          this.updateElement();
        }
      }
    );
  }

  getCalculationData = async () => {
    try {
      const res = await getCalculation();

      if (res.status) {
        this.setState({ calculations: res.data });
      }
    } catch (err) {
      console.log("Error: ", err.message);
    }
  };

  getBusinessRulesData = async () => {
    try {
      const res = await getBusinessRules();

      if (res.status) {
        // console.log("getBusinessRulesData RESSSS .... ", res);
        this.setState({ businessRules: res.data });
      }
    } catch (err) {
      console.log("Error: ", err.message);
    }
  };

  updateActiveTab = (event, newValue) => {
    this.setState({ activeTab: newValue });
  };

  editPropElement(elemProperty, targProperty, e) {
    // elemProperty could be content or label
    // targProperty could be value or checked
    const this_element = this.state.element;
    this_element.props[elemProperty] =
      e.target[targProperty] == "true" ? true : false;

    this.setState(
      {
        element: this_element,
        dirty: true,
      },
      () => {
        if (targProperty === "checked") {
          this.updateElement();
        }
      }
    );
  }

  onEditorStateChange(index, property, editorContent) {
    // const html = draftToHtml(convertToRaw(editorContent.getCurrentContent())).replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>');
    const html = draftToHtml(convertToRaw(editorContent.getCurrentContent()))
      .replace(/<p>/g, "")
      .replace(/<\/p>/g, "")
      .replace(/&nbsp;/g, " ")
      .replace(/(?:\r\n|\r|\n)/g, " ");
    const this_element = this.state.element;
    this_element.props[property] = html;

    this.setState({
      element: this_element,
      dirty: true,
    });
  }

  updateElement() {
    const this_element = this.state.element;
    // to prevent ajax calls with no change
    if (this.state.dirty) {
      this.props.updateElement.call(this.props.preview, this_element);
      this.setState({ dirty: false });
    }
  }

  convertFromHTML(content) {
    const newContent = convertFromHTML(content);
    if (!newContent.contentBlocks || !newContent.contentBlocks.length) {
      // to prevent crash when no contents in editor
      return EditorState.createEmpty();
    }
    const contentState = ContentState.createFromBlockArray(newContent);
    return EditorState.createWithContent(contentState);
  }

  filterRules(id) {
    let selectedRules = [];
    selectedRules.push(id);
    let filterArray = this.state?.businessRules?.filter((item) => item.id !== id);
    this.setState({ filteredRules: filterArray });
  }

  addOptions() {
    const optionsApiUrl = document.getElementById("optionsApiUrl").value;
    if (optionsApiUrl) {
      get(optionsApiUrl).then((data) => {
        this.props.element.options = [];
        const { options } = this.props.element;
        data.forEach((x) => {
          // eslint-disable-next-line no-param-reassign
          x.key = ID.uuid();
          options.push(x);
        });
        const this_element = this.state.element;
        this.setState({
          element: this_element,
          dirty: true,
        });
      });
    }
  }

  render() {
    if (this.state.dirty) {
      this.props.element.dirty = true;
    }

    const this_checked = this.props.element?.hasOwnProperty("required")
      ? this.props.element.required
      : false;
    const this_read_only = this.props.element?.hasOwnProperty("readOnly")
      ? this.props.element.readOnly
      : false;
    const this_default_today = this.props.element?.hasOwnProperty(
      "defaultToday"
    )
      ? this.props.element.defaultToday
      : false;
    const this_show_time_select = this.props.element?.hasOwnProperty(
      "showTimeSelect"
    )
      ? this.props.element.showTimeSelect
      : false;
    const this_show_time_select_only = this.props.element?.hasOwnProperty(
      "showTimeSelectOnly"
    )
      ? this.props.element.showTimeSelectOnly
      : false;
    const this_checked_inline = this.props.element?.hasOwnProperty("inline")
      ? this.props.element.inline
      : false;
    const this_checked_bold = this.props.element?.hasOwnProperty("bold")
      ? this.props.element.bold
      : false;
    const this_checked_italic = this.props.element?.hasOwnProperty("italic")
      ? this.props.element.italic
      : false;
    const this_checked_center = this.props.element?.hasOwnProperty("center")
      ? this.props.element.center
      : false;
    const this_checked_page_break = this.props.element?.hasOwnProperty(
      "pageBreakBefore"
    )
      ? this.props.element.pageBreakBefore
      : false;
    const this_checked_alternate_form = this.props.element?.hasOwnProperty(
      "alternateForm"
    )
      ? this.props.element.alternateForm
      : false;
    const this_active_check = this.props.element?.hasOwnProperty("isActive")
      ? this.props.element.isActive
      : false;
    const this_auto_resize_check = this.props.element?.hasOwnProperty(
      "autoResizeChoices"
    )
      ? this.props.element.autoResizeChoices
      : false;
    const this_keep_question_text_fixed = this.props.element?.hasOwnProperty(
      "KeepQuestionTextFixed"
    )
      ? this.props.element.KeepQuestionTextFixed
      : false;
    const this_display_on_conf = this.props.element?.hasOwnProperty(
      "DisplayOnConfirmation"
    )
      ? this.props.element.DisplayOnConfirmation
      : false;
    const this_is_instruction = this.props.element?.hasOwnProperty(
      "IsInstruction"
    )
      ? this.props.element.IsInstruction
      : false;
    const land_scape_orientation = this.props.element?.hasOwnProperty(
      "IsLandscapeOrientation"
    )
      ? this.props.element.IsLandscapeOrientation
      : false;

    const is_blinded = this.props?.hasOwnProperty("IsBlinded")
      ? this.props.element.IsBlinded
      : false;

    const is_handwriting_enabled = this.props?.hasOwnProperty(
      "isHandWritingEnabled"
    )
      ? this.props.element.isHandWritingEnabled
      : false;

    const is_scroll_for_more = this.props?.hasOwnProperty(
      "isScrollForMoreEnabled"
    )
      ? this.props.element.isScrollForMoreEnabled
      : false;

    const is_display_summary = this.props?.hasOwnProperty("DisplaySummaryScore")
      ? this.props.element.DisplaySummaryScore
      : false;

    const is_drop_down_navigation = this.props?.hasOwnProperty(
      "IsDropdownNavigationEnabled"
    )
      ? this.props.element.IsDropdownNavigationEnabled
      : false;

    const validate_question_on_save = this.props?.hasOwnProperty(
      "ValidateQuestionnaireOnSave"
    )
      ? this.props.element.ValidateQuestionnaireOnSave
      : false;

    const prompt_incomplete = this.props?.hasOwnProperty(
      "PromptIncompletePagesOnSave"
    )
      ? this.props.element.PromptIncompletePagesOnSave
      : false;

    const enforce_previous_days = this.props?.hasOwnProperty(
      "EnforcePreviousDaysEntry"
    )
      ? this.props.element.EnforcePreviousDaysEntry
      : false;

    const allow_edit = this.props?.hasOwnProperty("AllowEdit")
      ? this.props.element.AllowEdit
      : false;

    const {
      canHavePageBreakBefore,
      canHaveAlternateForm,
      canHaveDisplayHorizontal,
      canHaveOptionCorrect,
      canHaveOptionValue,
    } = this.props.element;

    const this_files = this.props.files.length ? this.props.files : [];
    if (
      this_files.length < 1 ||
      (this_files.length > 0 && this_files[0].id !== "")
    ) {
      this_files.unshift({ id: "", file_name: "" });
    }

    let editorState;
    if (this.props.element?.hasOwnProperty("content")) {
      editorState = this.convertFromHTML(this.props.element.content);
    }
    if (this.props.element?.hasOwnProperty("label")) {
      editorState = this.convertFromHTML(this.props.element.label);
    }
    if (this.props.element?.selectionOptions) {
      editorState = this.convertFromHTML(this.props.element.selectionOptions);
    }
    if (this.props.element?.questions) {
      editorState = this.convertFromHTML(this.props.element.questions);
    }

    let count = this.state.count;

    return (
      <div>
        <div className="clearfix">
          <i
            className="float-right fas fa-times dismiss-edit"
            onClick={this.props.manualEditModeOff}
          ></i>
        </div>
        <Tabs
          value={this.state.activeTab}
          onChange={this.updateActiveTab}
          aria-label="disabled tabs example"
        >
          <Tab icon={<SettingsIcon />} iconPosition="start" label="Settings" />
          <Tab
            label="Control Options"
            icon={<VisibilityIcon />}
            iconPosition="start"
          />
          {/* <Tab label="Properties" icon={<WorkIcon />} iconPosition="start" /> */}
        </Tabs>
        <TabPanel value={this.state.activeTab} index={0}>
          {this.props.element.key === "PageBreak" && (
            <>
              <div className="form-group">
                <label className="control-label" htmlFor="PageId">
                  Page Id
                </label>
                <input
                  disabled={true}
                  id="PageId"
                  type="text"
                  className="form-control"
                  defaultValue={this.props.element.id || ""}
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(this, "PageId", "value")}
                />
              </div>
              <br />

              <div className="form-group">
                <label className="control-label" htmlFor="PageId">
                  Name
                </label>
                <input
                  // disabled={true}
                  id="name"
                  type="text"
                  className="form-control"
                  defaultValue={this.props.element?.text}
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(this, "name", "value")}
                />
              </div>

              <br />

              <div className="form-group">
                <label className="control-label" htmlFor="PageId">
                  Number
                </label>
                <input
                  // disabled={true}
                  min={1}
                  id="number"
                  type="text"
                  className="form-control"
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(this, "number", "value")}
                />
              </div>

              <br />

              <div className="form-group">
                <label className="control-label" htmlFor="FooterText">
                  Footer Text
                </label>
                <input
                  // disabled={true}
                  id="name"
                  type="text"
                  className="form-control"
                  defaultValue={this.props.element?.text}
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(
                    this,
                    "FooterText",
                    "value"
                  )}
                />
              </div>
              <br />

              <div className="form-group">
                <div className="custom-control custom-checkbox">
                  <input
                    id="do-center"
                    className="custom-control-input"
                    type="checkbox"
                    checked={is_handwriting_enabled}
                    value={true}
                    onChange={this.editElementProp.bind(
                      this,
                      "isHandWritingEnabled",
                      "checked"
                    )}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="isHandWritingEnabled"
                  >
                    Is HandWriting Enabled
                  </label>
                </div>
              </div>
              <br />
              <div className="form-group">
                <div className="custom-control custom-checkbox">
                  <input
                    id="do-center"
                    className="custom-control-input"
                    type="checkbox"
                    checked={is_scroll_for_more}
                    value={true}
                    onChange={this.editElementProp.bind(
                      this,
                      "isScrollForMoreEnabled",
                      "checked"
                    )}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="isScrollForMoreEnabled"
                  >
                    Is Scroll For More Enabled
                  </label>
                </div>
              </div>
            </>
          )}
          {this.props.element?.hasOwnProperty("content") && (
            <div className="form-group">
              <label className="control-label">Text to display:</label>

              <Editor
                toolbar={toolbar}
                defaultEditorState={editorState}
                onBlur={this.updateElement.bind(this)}
                onEditorStateChange={this.onEditorStateChange.bind(
                  this,
                  0,
                  "content"
                )}
                stripPastedStyles={true}
              />
            </div>
          )}
          {this.props.element?.hasOwnProperty("file_path") && (
            <div className="form-group">
              <label className="control-label" htmlFor="fileSelect">
                Choose file:
              </label>
              <select
                id="fileSelect"
                className="form-control"
                defaultValue={this.props.element.file_path}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, "file_path", "value")}
              >
                {this_files.map((file) => {
                  const this_key = `file_${file.id}`;
                  return (
                    <option value={file.id} key={this_key}>
                      {file.file_name}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          {this.props.element?.hasOwnProperty("href") && (
            <div className="form-group">
              <TextAreaAutosize
                type="text"
                className="form-control"
                defaultValue={this.props.element.href}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, "href", "value")}
              />
            </div>
          )}
          {this.props.element?.hasOwnProperty("src") && (
            <div>
              <div className="form-group">
                <label className="control-label" htmlFor="srcInput">
                  Link to this :
                </label>
                <input
                  id="srcInput"
                  type="text"
                  className="form-control"
                  defaultValue={this.props.element.src}
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(this, "src", "value")}
                />
              </div>
              <div className="form-group">
                <div className="custom-control custom-checkbox">
                  <input
                    id="do-center"
                    className="custom-control-input"
                    type="checkbox"
                    checked={this_checked_center}
                    value={true}
                    onChange={this.editElementProp.bind(
                      this,
                      "center",
                      "checked"
                    )}
                  />
                  <label className="custom-control-label" htmlFor="do-center">
                    Center?
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-3">
                  <label className="control-label" htmlFor="elementWidth">
                    Width:
                  </label>
                  <input
                    id="elementWidth"
                    type="text"
                    className="form-control"
                    defaultValue={this.props.element.width}
                    onBlur={this.updateElement.bind(this)}
                    onChange={this.editElementProp.bind(this, "width", "value")}
                  />
                </div>
                <div className="col-sm-3">
                  <label className="control-label" htmlFor="elementHeight">
                    Height:
                  </label>
                  <input
                    id="elementHeight"
                    type="text"
                    className="form-control"
                    defaultValue={this.props.element.height}
                    onBlur={this.updateElement.bind(this)}
                    onChange={this.editElementProp.bind(
                      this,
                      "height",
                      "value"
                    )}
                  />
                </div>
              </div>
            </div>
          )}
          {console.log("Element Props ... ", this.props.element)}
          {this.props.element?.hasOwnProperty("label") && (
            <div className="form-group">
              <label>Display Label</label>
              <Editor
                toolbar={toolbar}
                defaultEditorState={editorState}
                onBlur={this.updateElement.bind(this)}
                onEditorStateChange={this.onEditorStateChange.bind(
                  this,
                  0,
                  "label"
                )}
                stripPastedStyles={true}
              />
              <br />
              <div className="custom-control custom-checkbox">
                <input
                  // style={{ width: "100px", height: "100px" }}
                  
                  id="is-required"
                  className="custom-control-input"
                  type="checkbox"
                  checked={this_checked}
                  value={true}
                  onChange={this.editElementProp.bind(
                    this,
                    "required",
                    "checked"
                  )}
                />
                <label className="custom-control-label" htmlFor="is-required">
                  Required
                </label>
              </div>
              {this.props.element?.hasOwnProperty("readOnly") && (
                <div className="custom-control custom-checkbox">
                  <input
                    id="is-read-only"
                    className="custom-control-input"
                    type="checkbox"
                    checked={this_read_only}
                    value={true}
                    onChange={this.editElementProp.bind(
                      this,
                      "readOnly",
                      "checked"
                    )}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="is-read-only"
                  >
                    Read only
                  </label>
                </div>
              )}
              {this.props.element?.hasOwnProperty("defaultToday") && (
                <div className="custom-control custom-checkbox">
                  <input
                    id="is-default-to-today"
                    className="custom-control-input"
                    type="checkbox"
                    checked={this_default_today}
                    value={true}
                    onChange={this.editElementProp.bind(
                      this,
                      "defaultToday",
                      "checked"
                    )}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="is-default-to-today"
                  >
                    Default to Today?
                  </label>
                </div>
              )}
              {this.props.element?.hasOwnProperty("showTimeSelect") && (
                <div className="custom-control custom-checkbox">
                  <input
                    id="show-time-select"
                    className="custom-control-input"
                    type="checkbox"
                    checked={this_show_time_select}
                    value={true}
                    onChange={this.editElementProp.bind(
                      this,
                      "showTimeSelect",
                      "checked"
                    )}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="show-time-select"
                  >
                    Show Time Select?
                  </label>
                </div>
              )}
              {this_show_time_select &&
                this.props.element?.hasOwnProperty("showTimeSelectOnly") && (
                  <div className="custom-control custom-checkbox">
                    <input
                      id="show-time-select-only"
                      className="custom-control-input"
                      type="checkbox"
                      checked={this_show_time_select_only}
                      value={true}
                      onChange={this.editElementProp.bind(
                        this,
                        "showTimeSelectOnly",
                        "checked"
                      )}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="show-time-select-only"
                    >
                      Show Time Select Only?
                    </label>
                  </div>
                )}
              {(this.state.element.element === "RadioButtons" ||
                this.state.element.element === "Checkboxes") &&
                canHaveDisplayHorizontal && (
                  <div className="custom-control custom-checkbox">
                    <input
                      id="display-horizontal"
                      className="custom-control-input"
                      type="checkbox"
                      checked={this_checked_inline}
                      value={true}
                      onChange={this.editElementProp.bind(
                        this,
                        "inline",
                        "checked"
                      )}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="display-horizontal"
                    >
                      Display horizonal
                    </label>
                  </div>
                )}
            </div>
          )}

          {this.state.element.element === "Signature" &&
            this.props.element.readOnly ? (
            <div className="form-group">
              <label className="control-label" htmlFor="variableKey">
                Variable Key:
              </label>
              <input
                id="variableKey"
                type="text"
                className="form-control"
                defaultValue={this.props.element.variableKey}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(
                  this,
                  "variableKey",
                  "value"
                )}
              />
              <p className="help-block">
                This will give the element a key that can be used to replace the
                content with a runtime value.
              </p>
            </div>
          ) : (
            <div />
          )}

          {this.props.element?.hasOwnProperty("step") && (
            <div className="form-group">
              <div className="form-group-range">
                <label className="control-label" htmlFor="rangeStep">
                  Step
                </label>
                <input
                  id="rangeStep"
                  type="number"
                  className="form-control"
                  defaultValue={this.props.element.step}
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(this, "step", "value")}
                />
              </div>
            </div>
          )}
          {this.props.element?.hasOwnProperty("min_value") && (
            <div className="form-group">
              <div className="form-group-range">
                <label className="control-label" htmlFor="rangeMin">
                  Min
                </label>
                <input
                  id="rangeMin"
                  type="number"
                  className="form-control"
                  defaultValue={this.props.element.min_value}
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(
                    this,
                    "min_value",
                    "value"
                  )}
                />
                <input
                  type="text"
                  className="form-control"
                  defaultValue={this.props.element.min_label}
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(
                    this,
                    "min_label",
                    "value"
                  )}
                />
              </div>
            </div>
          )}
          {this.props.element?.hasOwnProperty("max_value") && (
            <div className="form-group">
              <div className="form-group-range">
                <label className="control-label" htmlFor="rangeMax">
                  Max
                </label>
                <input
                  id="rangeMax"
                  type="number"
                  className="form-control"
                  defaultValue={this.props.element.max_value}
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(
                    this,
                    "max_value",
                    "value"
                  )}
                />
                <input
                  type="text"
                  className="form-control"
                  defaultValue={this.props.element.max_label}
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(
                    this,
                    "max_label",
                    "value"
                  )}
                />
              </div>
            </div>
          )}
          {this.props.element?.hasOwnProperty("default_value") && (
            <div className="form-group">
              <div className="form-group-range">
                <label className="control-label" htmlFor="defaultSelected">
                  Default Selected
                </label>
                <input
                  id="defaultSelected"
                  type="number"
                  className="form-control"
                  defaultValue={this.props.element.default_value}
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(
                    this,
                    "default_value",
                    "value"
                  )}
                />
              </div>
            </div>
          )}
          {this.props.element?.hasOwnProperty("static") &&
            this.props.element.static && (
              <div className="form-group">
                <label className="control-label">Text Style</label>
                <div className="custom-control custom-checkbox">
                  <input
                    id="do-bold"
                    className="custom-control-input"
                    type="checkbox"
                    checked={this_checked_bold}
                    value={true}
                    onChange={this.editElementProp.bind(
                      this,
                      "bold",
                      "checked"
                    )}
                  />
                  <label className="custom-control-label ml-2" htmlFor="do-bold">
                    Bold
                  </label>
                </div>
                <div className="custom-control custom-checkbox">
                  <input
                    id="do-italic"
                    className="custom-control-input"
                    type="checkbox"
                    checked={this_checked_italic}
                    value={true}
                    onChange={this.editElementProp.bind(
                      this,
                      "italic",
                      "checked"
                    )}
                  />
                  <label className="custom-control-label ml-2" htmlFor="do-italic">
                    Italic
                  </label>
                </div>
              </div>
            )}
          {this.props.element.showDescription && (
            <div className="form-group">
              <label className="control-label" htmlFor="questionDescription">
                Description
              </label>
              <TextAreaAutosize
                type="text"
                className="form-control"
                id="questionDescription"
                defaultValue={this.props.element.description}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(
                  this,
                  "description",
                  "value"
                )}
              />
            </div>
          )}

          {(this.props.element.props?.show ||
            this.props.element.props?.show == false) && (
              <div className="form-group">
                <label className="control-label" htmlFor="fileSelect">
                  Render ?
                </label>
                <select
                  id="fileSelect"
                  className="form-control"
                  defaultValue={this.props.element.props.show}
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editPropElement.bind(this, "show", "value")}
                >
                  <option value={true} key={true}>
                    YES
                  </option>
                  <option value={false} key={false}>
                    NO
                  </option>
                </select>
              </div>
            )}
          {/* <div className="form-group">
            <label className="control-label" htmlFor="fileSelect">
              Summary ?
            </label>
            <select
              id="fileSelect"
              className="form-control"
              defaultValue={this.props.element.summary || false}
              onBlur={this.updateElement.bind(this)}
              onChange={this.editElementProp.bind(this, "summary", "value")}
            >
              <option value={true} key={true}>
                YES
              </option>
              <option value={false} key={false}>
                NO
              </option>
            </select>
          </div> */}

          {this.props.element.props?.hasOwnProperty("title") && (
            <div className="col-sm-3">
              <label className="control-label" htmlFor="elementHeight">
                Field Title
              </label>
              <input
                id="elementHeight"
                type="text"
                className="form-control"
                defaultValue={this.props.element.title}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, "title", "value")}
              />
            </div>
          )}

          {/* for questions weight mainly for checkklist accident component */}
          {this.props.element.props?.hasOwnProperty("weight") && (
            <div className="col-sm-3">
              <label className="control-label" htmlFor="elementHeight">
                Question Weight (comma seperated)
              </label>
              <input
                id="elementHeight"
                type="text"
                className="form-control"
                defaultValue={this.props.element.weight}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, "weight", "value")}
              />
            </div>
          )}
          {/* for adding the options in pill or multiselect */}
          {this.props.element.props?.selectionOptions && (
            <div className="form-group">
              <label className="control-label">
                Add elements with comma in between
              </label>

              <Editor
                toolbar={toolbar}
                defaultEditorState={editorState}
                onBlur={this.updateElement.bind(this)}
                onEditorStateChange={this.onEditorStateChange.bind(
                  this,
                  0,
                  "selectionOptions"
                )}
                stripPastedStyles={true}
              />
            </div>
          )}
          {this.props.element.props?.questions && (
            <div className="form-group">
              <label className="control-label">Add Questions</label>

              <Editor
                toolbar={toolbar}
                defaultEditorState={editorState}
                onBlur={this.updateElement.bind(this)}
                onEditorStateChange={this.onEditorStateChange.bind(
                  this,
                  0,
                  "questions"
                )}
                stripPastedStyles={true}
              />
            </div>
          )}
          {/* for adding the extra steps in form */}
          {(this.props.element.props?.hasNestedSteps == true ||
            this.props.element.props?.hasNestedSteps == false) && (
              <div className="form-group">
                <label className="control-label" htmlFor="fileSelect">
                  Has Additional Steps ?
                </label>
                <select
                  id="fileSelect"
                  className="form-control"
                  defaultValue={this.props.element.hasNestedSteps || false}
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(
                    this,
                    "hasNestedSteps",
                    "value"
                  )}
                >
                  <option value={true} key={true}>
                    YES
                  </option>
                  <option value={false} key={false}>
                    NO
                  </option>
                </select>
              </div>
            )}
          {/* for the configuration of extra steps in form */}
          {(this.props.element.props?.isNestedStep == true ||
            this.props.element.props?.isNestedStep == false) && (
              <div className="form-group">
                <label className="control-label" htmlFor="fileSelect">
                  is Additional Step ?
                </label>
                <select
                  className="form-control"
                  defaultValue={this.props.element.isNestedStep || false}
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(
                    this,
                    "isNestedStep",
                    "value"
                  )}
                >
                  <option value={true} key={true}>
                    YES
                  </option>
                  <option value={false} key={false}>
                    NO
                  </option>
                </select>
              </div>
            )}

          {/* for the configuration of extra steps in form */}
          {this.props.element.isNestedStep == "true" && (
            <div className="form-group">
              <label className="control-label" htmlFor="fileSelect">
                Parent page?
              </label>
              <input
                id="parentPage"
                type="number"
                className="form-control"
                defaultValue={this.props.element.parentStep || ""}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(
                  this,
                  "parentStep",
                  "value"
                )}
              />
            </div>
          )}

          {/* for the configuration of extra steps in form */}

          {this.props.element.isNestedStep == "true" && (
            <div className="form-group">
              <label className="control-label" htmlFor="fileSelect">
                Parent page field title?
              </label>
              <input
                id="parentOption"
                type="text"
                className="form-control"
                defaultValue={this.props.element.parentTitle || ""}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(
                  this,
                  "parentTitle",
                  "value"
                )}
              />
            </div>
          )}
          {this.props.element.isNestedStep == "true" && (
            <div className="form-group">
              <label className="control-label" htmlFor="fileSelect">
                Parent page option?
              </label>
              <input
                id="parentOption"
                type="text"
                className="form-control"
                defaultValue={this.props.element.parentValue || ""}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(
                  this,
                  "parentValue",
                  "value"
                )}
              />
            </div>
          )}

          {this.props.showCorrectColumn &&
            this.props.element.canHaveAnswer &&
            !this.props.element?.hasOwnProperty("options") && (
              <div className="form-group">
                <label className="control-label" htmlFor="correctAnswer">
                  Correct Answer
                </label>
                <input
                  id="correctAnswer"
                  type="text"
                  className="form-control"
                  defaultValue={this.props.element.correct}
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(this, "correct", "value")}
                />
              </div>
            )}
          {this.props.element.canPopulateFromApi &&
            this.props.element?.hasOwnProperty("options") && (
              <div className="form-group">
                <label className="control-label" htmlFor="optionsApiUrl">
                  Populate Options from API
                </label>
                <div className="row">
                  <div className="col-sm-6">
                    <input
                      className="form-control"
                      style={{ width: "100%" }}
                      type="text"
                      id="optionsApiUrl"
                      placeholder="http://localhost:8080/api/optionsdata"
                    />
                  </div>
                  <div className="col-sm-6">
                    <button
                      onClick={this.addOptions.bind(this)}
                      className="btn btn-success"
                    >
                      Populate
                    </button>
                  </div>
                </div>
              </div>
            )}
          {this.props.element?.hasOwnProperty("options") && (
            <DynamicOptionList
              showCorrectColumn={this.props.showCorrectColumn}
              canHaveOptionCorrect={canHaveOptionCorrect}
              canHaveOptionValue={canHaveOptionValue}
              data={this.props.preview.state.data}
              updateElement={this.props.updateElement}
              preview={this.props.preview}
              element={this.props.element}
              key={this.props.element.options.length}
            />
          )}
          {/* for title */}
          {this.props.element.props?.hasDropDown === true && (
            <div className="form-group">
              <label className="control-label" htmlFor="fileSelect">
                Do you want to add dropdown options?
              </label>
              <select
                className="form-control"
                defaultValue={this.props.element.hasPageTitle || false}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(
                  this,
                  "hasPageTitle",
                  "value"
                )}
              >
                <option value={true} key={true}>
                  YES
                </option>
                <option value={false} key={false}>
                  NO
                </option>
              </select>
            </div>
          )}
          {this.props.element.props?.hasOwnProperty("imgLink") && (
            <div>
              <label className="control-label" htmlFor="elementHeight">
                Img Link
              </label>
              <input
                id="elementHeight"
                type="text"
                className="form-control"
                defaultValue={this.props.element.imgLink}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, "imgLink", "value")}
              />
            </div>
          )}
          {/* when page title has to be added to a field */}
          {this.props.element.hasPageTitle == "true" && (
            <div className="form-group">
              <label className="control-label" htmlFor="fileSelect">
                Dropdown options (Comma Seperated)
              </label>
              <textarea
                id="parentOption"
                type="text"
                rows={5}
                className="form-control"
                defaultValue={this.props.element.pageTitle || ""}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, "pageTitle", "value")}
              ></textarea>
              {/* <input
              id="parentOption"
              type="text"
              className="form-control"
              defaultValue={this.props.element.pageTitle || ""}
              onBlur={this.updateElement.bind(this)}
              onChange={this.editElementProp.bind(this, "pageTitle", "value")}
            /> */}
            </div>
          )}
          <div className="form-group">
            <label className="control-label" htmlFor="QuestionnaireId">
              Questionnaire Id
            </label>
            <select
              disabled={true}
              id="QuestionnaireId"
              className="form-control"
              defaultValue={"TranslationKey"}
              onBlur={this.updateElement.bind(this)}
              onChange={this.editElementProp.bind(
                this,
                "QuestionnaireId",
                "value"
              )}
            >
              <option value={"TranslationKey"} key={"TranslationKey"}>
                {"TranslationKey"}
              </option>
            </select>
          </div>

          <div className="form-group">
            <label className="control-label" htmlFor="DiaryPageId">
              Diary Page
            </label>
            <select
              id="DiaryPageId"
              className="form-control"
              defaultValue={"New Page"}
              onBlur={this.updateElement.bind(this)}
              onChange={this.editElementProp.bind(this, "DiaryPageId", "value")}
            >
              <option value={"New Page"} key={"New Page"}>
                New Page
              </option>
              <option value={"Same Page"} key={"Same Page"}>
                Same Page
              </option>
            </select>
          </div>

          {/* <div className="form-group">
            <label className="control-label" htmlFor="InputFieldTypeId">
              Input Field Type
            </label>
            <select
              id="InputFieldTypeId"
              className="form-control"
              defaultValue={"None"}
              onBlur={this.updateElement.bind(this)}
              onChange={this.editElementProp.bind(
                this,
                "InputFieldTypeId",
                "value"
              )}
            >
              <option value={"None"} key={"None"}>
                None
              </option>
              <option value={"xyz"} key={"xyz"}>
                XYZ
              </option>
            </select>
          </div> */}

          <div className="form-group">
            <label className="control-label" htmlFor="QuestionTypeId">
              Question Type
            </label>
            <select
              id="QuestionTypeId"
              className="form-control"
              defaultValue={"None"}
              onBlur={this.updateElement.bind(this)}
              onChange={this.editElementProp.bind(
                this,
                "QuestionTypeId",
                "value"
              )}
            >
              <option value={"Default"} key={"Default"}>
                Default
              </option>
              <option value={"xyz"} key={"xyz"}>
                XYZ
              </option>
            </select>
          </div>

          <div className="form-group">
            <label className="control-label" htmlFor="QuestionTranslationKey">
              Question Translation Key
            </label>
            <div className="row">
              <div className="col-sm-12">
                <input
                  value={`TranslationKey-${this.props?.element?.id}`}
                  disabled={true}
                  id="QuestionTranslationKey"
                  className="form-control"
                  style={{ width: "100%" }}
                  type="text"
                // placeholder="http://localhost:8080/api/optionsdata"
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <label className="control-label" htmlFor="ExportDisplayName">
              Export Display Name
            </label>
            <div className="row">
              <div className="col-sm-12">
                <input
                  className="form-control"
                  style={{ width: "100%" }}
                  type="text"
                  id="ExportDisplayName"
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(
                    this,
                    "ExportDisplayName",
                    "value"
                  )}
                // placeholder="http://localhost:8080/api/optionsdata"
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <label className="control-label" htmlFor="ExportDisplayOrder">
              Export Display Order
            </label>
            <div className="row">
              <div className="col-sm-12">
                <input
                  className="form-control"
                  style={{ width: "100%" }}
                  type="number"
                  id="ExportDisplayOrder"
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(
                    this,
                    "ExportDisplayOrder",
                    "value"
                  )}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <label className="control-label" htmlFor="SASDataTypeId">
              SAS Data Type
            </label>
            <select
              id="SASDataTypeId"
              className="form-control"
              defaultValue={"None"}
              onBlur={this.updateElement.bind(this)}
              onChange={this.editElementProp.bind(
                this,
                "SASDataTypeId",
                "value"
              )}
            >
              <option value={"INTEGER"} key={"INTEGER"}>
                INTEGER
              </option>
              <option value={"STRING"} key={"STRING"}>
                STRING
              </option>
            </select>
          </div>

          <div className="form-group">
            <label className="control-label" htmlFor="SASLabel">
              SAS Label
            </label>
            <div className="row">
              <div className="col-sm-12">
                <input
                  className="form-control"
                  style={{ width: "100%" }}
                  type="text"
                  id="SASLabel"
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(
                    this,
                    "SASLabel",
                    "value"
                  )}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <label className="control-label" htmlFor="SDTMCode">
              SDTM Code
            </label>
            <div className="row">
              <div className="col-sm-12">
                <input
                  className="form-control"
                  style={{ width: "100%" }}
                  type="number"
                  id="SDTMCode"
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(
                    this,
                    "SDTMCode",
                    "value"
                  )}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <label className="control-label" htmlFor="SDTMText">
              SDTM Text
            </label>
            <div className="row">
              <div className="col-sm-12">
                <input
                  className="form-control"
                  style={{ width: "100%" }}
                  type="text"
                  id="SDTMText"
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(
                    this,
                    "SDTMText",
                    "value"
                  )}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <label className="control-label" htmlFor="SummaryDisplayTextKey">
              Summary Display Text Key
            </label>
            <div className="row">
              <div className="col-sm-12">
                <input
                  value={`TranslationKey-${this.props.element.id}SummaryText`}
                  disabled={true}
                  className="form-control"
                  style={{ width: "100%" }}
                  type="text"
                  id="SummaryDisplayTextKey"
                // placeholder="http://localhost:8080/api/optionsdata"
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <label className="control-label" htmlFor="SummaryDisplayText">
              Summary Display Text
            </label>
            <div className="row">
              <div className="col-sm-12">
                <input
                  className="form-control"
                  style={{ width: "100%" }}
                  type="text"
                  id="SummaryDisplayText"
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(
                    this,
                    "SummaryDisplayText",
                    "value"
                  )}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <label className="control-label" htmlFor="Description">
              Description
            </label>
            <div className="row">
              <div className="col-sm-12">
                <input
                  className="form-control"
                  style={{ width: "100%" }}
                  type="text"
                  id="Description"
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(
                    this,
                    "Description",
                    "value"
                  )}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <label className="control-label" htmlFor="Sequence">
              Sequence (This is used to order questionnaires on handheld that
              are NOT associate with a Visit):
            </label>
            <div className="row">
              <div className="col-sm-12">
                <input
                  className="form-control"
                  style={{ width: "100%" }}
                  type="number"
                  id="Sequence"
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(
                    this,
                    "Sequence",
                    "value"
                  )}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <label className="control-label" htmlFor="GroupNumber">
              Group Number
            </label>
            <div className="row">
              <div className="col-sm-12">
                <input
                  className="form-control"
                  style={{ width: "100%" }}
                  type="text"
                  id="GroupNumber"
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(
                    this,
                    "GroupNumber",
                    "value"
                  )}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <label className="control-label" htmlFor="MinValue">
              Minimum Value
            </label>
            <div className="row">
              <div className="col-sm-12">
                <input
                  className="form-control"
                  style={{ width: "100%" }}
                  type="number"
                  id="MinValue"
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(
                    this,
                    "MinValue",
                    "value"
                  )}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <label className="control-label" htmlFor="MaxValue">
              Maximum Value
            </label>
            <div className="row">
              <div className="col-sm-12">
                <input
                  className="form-control"
                  style={{ width: "100%" }}
                  type="number"
                  id="MaxValue"
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(
                    this,
                    "MaxValue",
                    "value"
                  )}
                />
              </div>
            </div>
          </div>

          <div className="custom-control custom-checkbox">
            <input
              id="isActive"
              className="custom-control-input"
              type="checkbox"
              checked={this_active_check}
              value={true}
              onChange={this.editElementProp.bind(this, "isActive", "checked")}
            />
            <label className="custom-control-label" htmlFor="isActive">
              Active
            </label>
          </div>
          <br />
          <div className="form-group">
            <label
              className="control-label"
              htmlFor="PatientAttributeConfiguration"
            >
              Patient Attribute Configuration
            </label>
            <select
              id="PatientAttributeConfiguration"
              className="form-control"
              defaultValue={"Attribute 1"}
              onBlur={this.updateElement.bind(this)}
              onChange={this.editElementProp.bind(
                this,
                "PatientAttributeConfiguration",
                "value"
              )}
            >
              <option value={"Attribute 1"} key={"Attribute 1"}>
                Attribute 1
              </option>
              <option value={"Attribute 2"} key={"Attribute 2"}>
                Attribute 2
              </option>
            </select>
          </div>

          <div className="form-group">
            <label className="control-label" htmlFor="SubmitActionParameter">
              Submit Action Parameter
            </label>
            <select
              id="SubmitActionParameter"
              className="form-control"
              defaultValue={"Action 1"}
              onBlur={this.updateElement.bind(this)}
              onChange={this.editElementProp.bind(
                this,
                "SubmitActionParameter",
                "value"
              )}
            >
              <option value={"Action 1"} key={"Action 1"}>
                Action 1
              </option>
              <option value={"Action 2"} key={"Action 2"}>
                Action 2
              </option>
            </select>
          </div>

          <div className="custom-control custom-checkbox">
            <input
              id="autoResizeChoices"
              className="custom-control-input"
              type="checkbox"
              checked={this_auto_resize_check}
              value={true}
              onChange={this.editElementProp.bind(
                this,
                "autoResizeChoices",
                "checked"
              )}
            />
            <label className="custom-control-label" htmlFor="autoResizeChoices">
              Auto Resize Choices
            </label>
          </div>
          <br />
          <div className="custom-control custom-checkbox">
            <input
              id="KeepQuestionTextFixed"
              className="custom-control-input"
              type="checkbox"
              checked={this_keep_question_text_fixed}
              value={true}
              onChange={this.editElementProp.bind(
                this,
                "KeepQuestionTextFixed",
                "checked"
              )}
            />
            <label
              className="custom-control-label"
              htmlFor="KeepQuestionTextFixed"
            >
              Keep Question Text Fixed on the screen
            </label>
          </div>
          <br />
          <div className="custom-control custom-checkbox">
            <input
              id="DisplayOnConfirmation"
              className="custom-control-input"
              type="checkbox"
              checked={this_display_on_conf}
              value={true}
              onChange={this.editElementProp.bind(
                this,
                "DisplayOnConfirmation",
                "checked"
              )}
            />
            <label
              className="custom-control-label"
              htmlFor="DisplayOnConfirmation"
            >
              Display On Confirmation
            </label>
          </div>
          <br />
          <div className="custom-control custom-checkbox">
            <input
              id="IsInstruction"
              className="custom-control-input"
              type="checkbox"
              checked={this_is_instruction}
              value={true}
              onChange={this.editElementProp.bind(
                this,
                "IsInstruction",
                "checked"
              )}
            />
            <label className="custom-control-label" htmlFor="IsInstruction">
              Is Instruction
            </label>
          </div>
          <br />
          <div className="form-group">
            <label className="control-label" htmlFor="Language">
              Language
            </label>
            <select
              id="Language"
              className="form-control"
              defaultValue={"English"}
              onBlur={this.updateElement.bind(this)}
              onChange={this.editElementProp.bind(this, "Language", "value")}
            >
              <option value={"English"} key={"English"}>
                English
              </option>
              <option value={"German"} key={"German"}>
                German
              </option>
              <option value={"French"} key={"French"}>
                French
              </option>
            </select>
          </div>

          <div className="form-group">
            <label className="control-label" htmlFor="StudyFileId">
              Study File
            </label>
            <select
              id="StudyFileId"
              className="form-control"
              defaultValue={"Study File"}
              onBlur={this.updateElement.bind(this)}
              onChange={this.editElementProp.bind(this, "StudyFileId", "value")}
            >
              <option value={"Study File"} key={"Study File"}>
                Study File
              </option>
              <option value={"Study File 2"} key={"Study File 2"}>
                Study File 2
              </option>
              <option value={"Study File 3"} key={"Study File 3"}>
                Study File 3
              </option>
            </select>
          </div>

          <div className="form-group">
            <label className="control-label" htmlFor="MinValueTextKey">
              Min Value Text Key
            </label>
            <div className="row">
              <div className="col-sm-12">
                <input
                  value={`TranslationKey-${this.props.element.id}MinText`}
                  disabled={true}
                  className="form-control"
                  style={{ width: "100%" }}
                  type="text"
                  id="MinValueTextKey"
                // placeholder="http://localhost:8080/api/optionsdata"
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <label className="control-label" htmlFor="MinValueTextKey">
              Min Value Display Text
            </label>
            <div className="row">
              <div className="col-sm-12">
                <input
                  className="form-control"
                  style={{ width: "100%" }}
                  type="text"
                  id="MinValueTextKey"
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(
                    this,
                    "MinValueTextKey",
                    "value"
                  )}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <label className="control-label" htmlFor="MaxValueTextKey">
              Max Value Text Key
            </label>
            <div className="row">
              <div className="col-sm-12">
                <input
                  value={`TranslationKey-${this.props.element.id}MaxText`}
                  disabled={true}
                  className="form-control"
                  style={{ width: "100%" }}
                  type="text"
                  id="MaxValueTextKey"
                // placeholder="http://localhost:8080/api/optionsdata"
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <label className="control-label" htmlFor="MaxValueDisplayText">
              Max Value Display Text
            </label>
            <div className="row">
              <div className="col-sm-12">
                <input
                  className="form-control"
                  style={{ width: "100%" }}
                  type="text"
                  id="MaxValueDisplayText"
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(
                    this,
                    "MaxValueDisplayText",
                    "value"
                  )}
                />
              </div>
            </div>
          </div>

          <div className="custom-control custom-checkbox">
            <input
              id="IsLandscapeOrientation"
              className="custom-control-input"
              type="checkbox"
              checked={land_scape_orientation}
              value={true}
              onChange={this.editElementProp.bind(
                this,
                "IsLandscapeOrientation",
                "checked"
              )}
            />
            <label
              className="custom-control-label"
              htmlFor="IsLandscapeOrientation"
            >
              Land Scape Orientation
            </label>
          </div>
          <br />
          <div className="form-group">
            <label className="control-label" htmlFor="InputDisplayText">
              InputDisplayText
            </label>
            <div className="row">
              <div className="col-sm-12">
                <input
                  className="form-control"
                  style={{ width: "100%" }}
                  type="text"
                  id="InputDisplayText"
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(
                    this,
                    "InputDisplayText",
                    "value"
                  )}
                />
              </div>
            </div>
          </div>
          <br />

          <div className="form-group">
            <label className="control-label" htmlFor="Id">
              Id
            </label>
            <div className="row">
              <div className="col-sm-12">
                <input
                  value={`${this.props?.element?.id}`}
                  className="form-control"
                  style={{ width: "100%" }}
                  type="text"
                  id="Id"
                  disabled={true}
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(this, "Id", "value")}
                />
              </div>
            </div>
          </div>
          <br />

          <div className="form-group">
            <label className="control-label" htmlFor="TranslationKeyForEdit">
              Translation Key (Current translated value:)
            </label>
            <div className="row">
              <div className="col-sm-12">
                <input
                  className="form-control"
                  style={{ width: "100%" }}
                  type="text"
                  id="TranslationKeyForEdit"
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(
                    this,
                    "TranslationKeyForEdit",
                    "value"
                  )}
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-sm-12">
                <input
                  className="form-control"
                  style={{ width: "30%" }}
                  value="Edit Translation"
                  type="button"
                  id="EditTranslation"
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(
                    this,
                    "EditTranslation",
                    "value"
                  )}
                />
              </div>
            </div>
          </div>
          <br />

          <div className="form-group">
            <label className="control-label" htmlFor="InternalName">
              Internal Name
            </label>
            <div className="row">
              <div className="col-sm-12">
                <input
                  className="form-control"
                  style={{ width: "100%" }}
                  type="text"
                  id="InternalName"
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(
                    this,
                    "InternalName",
                    "value"
                  )}
                />
              </div>
            </div>
          </div>
          <br />

          <div className="form-group">
            <label className="control-label" htmlFor="QuestionnaireTypeId">
              Questionnaire TypeId
            </label>
            <select
              id="Language"
              className="form-control"
              defaultValue={"Clinician"}
              onBlur={this.updateElement.bind(this)}
              onChange={this.editElementProp.bind(
                this,
                "QuestionnaireTypeId",
                "value"
              )}
            >
              <option value={"Clinician"} key={"Clinician"}>
                Clinician
              </option>
              <option value={"ABC"} key={"ABC"}>
                ABC
              </option>
              <option value={"XYZ"} key={"XYZ"}>
                XYZ
              </option>
            </select>
          </div>

          <br />

          <div className="custom-control custom-checkbox">
            <input
              id="IsBlinded"
              className="custom-control-input"
              type="checkbox"
              checked={is_blinded}
              value={true}
              onChange={this.editElementProp.bind(this, "IsBlinded", "checked")}
            />
            <label className="custom-control-label" htmlFor="IsBlinded">
              Is Blinded
            </label>
          </div>

          <br />

          <div className="custom-control custom-checkbox">
            <input
              id="DisplaySummaryScore"
              className="custom-control-input"
              type="checkbox"
              checked={is_display_summary}
              value={true}
              onChange={this.editElementProp.bind(
                this,
                "DisplaySummaryScore",
                "checked"
              )}
            />
            <label
              className="custom-control-label"
              htmlFor="DisplaySummaryScore"
            >
              Display Summary Score
            </label>
          </div>

          <br />

          <div className="custom-control custom-checkbox">
            <input
              id="IsDropdownNavigationEnabled"
              className="custom-control-input"
              type="checkbox"
              checked={is_drop_down_navigation}
              value={true}
              onChange={this.editElementProp.bind(
                this,
                "IsDropdownNavigationEnabled",
                "checked"
              )}
            />
            <label
              className="custom-control-label"
              htmlFor="IsDropdownNavigationEnabled"
            >
              Is Dropdown Navigation Enabled
            </label>
          </div>

          <br />

          <div className="custom-control custom-checkbox">
            <input
              id="ValidateQuestionnaireOnSave"
              className="custom-control-input"
              type="checkbox"
              checked={validate_question_on_save}
              value={true}
              onChange={this.editElementProp.bind(
                this,
                "ValidateQuestionnaireOnSave",
                "checked"
              )}
            />
            <label
              className="custom-control-label"
              htmlFor="ValidateQuestionnaireOnSave"
            >
              Validate Questionnaire On Save
            </label>
          </div>

          <br />

          <div className="custom-control custom-checkbox">
            <input
              id="PromptIncompletePagesOnSave"
              className="custom-control-input"
              type="checkbox"
              checked={prompt_incomplete}
              value={true}
              onChange={this.editElementProp.bind(
                this,
                "PromptIncompletePagesOnSave",
                "checked"
              )}
            />
            <label
              className="custom-control-label"
              htmlFor="PromptIncompletePagesOnSave"
            >
              Prompt Incomplete Pages On Save
            </label>
          </div>
          <br />

          {/* <div style={{ background: "white", padding: "15px" }}> */}
          <div className="form-group">
            <label className="control-label" htmlFor="VisibleBusinessRuleId">
              Visible Business Rule Id
            </label>
            <select
              id="VisibleBusinessRuleId"
              className="form-control"
              defaultValue={this.props?.element?.VisibleBusinessRuleId}
              onBlur={this.updateElement.bind(this)}
              onChange={this.editElementProp.bind(
                this,
                "VisibleBusinessRuleId",
                "value"
              )}
            >
              <option value="" disabled selected>
                Select A Property
              </option>
              {this.state?.businessRules?.filter(
                  (val) => val.id !== this.props?.element?.EnableBusinessRuleId
                )
                .map((val, index) => (
                  <option value={val.id} key={index}>
                    {val.name}
                  </option>
                ))}
            </select>
          </div>
          {/* </div> */}

          <br />

          {/* <div style={{ background: "white", padding: "15px" }}> */}
          <div className="form-group">
            <label className="control-label" htmlFor="EnableBusinessRuleId">
              Enable Business Rule Id
            </label>
            <select
              id="EnableBusinessRuleId"
              className="form-control"
              defaultValue={this.props?.element?.EnableBusinessRuleId}
              onBlur={this.updateElement.bind(this)}
              onChange={this.editElementProp.bind(
                this,
                "EnableBusinessRuleId",
                "value"
              )}
            >
              <option value="" disabled selected>
                Select A Property{" "}
              </option>
              {this.state?.businessRules?.filter(
                  (val) => val.id !== this.props?.element?.VisibleBusinessRuleId
                )
                .map((val, index) => (
                  <option value={val.id} key={index}>
                    {val.name}
                  </option>
                ))}
            </select>
          </div>
          {/* </div> */}

          <br />

          <div className="form-group">
            <label className="control-label" htmlFor="OpenEndedBusinessRuleId">
              Open Ended Business Rule Id
            </label>
            <select
              id="OpenEndedBusinessRuleId"
              className="form-control"
              defaultValue={""}
              onBlur={this.updateElement.bind(this)}
              onChange={this.editElementProp.bind(
                this,
                "OpenEndedBusinessRuleId",
                "value"
              )}
            >
              <option value={""} key={""}>
                Select Option
              </option>
              <option value={"123"} key={"123"}>
                123
              </option>
              <option value={"ABC"} key={"ABC"}>
                ABC
              </option>
              <option value={"XYZ"} key={"XYZ"}>
                XYZ
              </option>
            </select>
          </div>

          <br />

          {/* <div style={{ background: "white", padding: "15px" }}> */}
          {/* <div className="form-group">
            <label className="control-label" htmlFor="VisibleBusinessRuleId">
              Visible Property
            </label>
            <select
              id="VisibleBusinessRuleId"
              className="form-control"
              defaultValue={this.props?.element?.VisibleBusinessRuleId}
              onBlur={this.updateElement.bind(this)}
              onChange={this.editElementProp.bind(
                this,
                "VisibleBusinessRuleId",
                "value"
              )}
            >
              <option value="" disabled selected>
                Select A Property
              </option>
              {this.state.businessRules
                .filter(
                  (val) => val.id !== this.props?.element?.EnableBusinessRuleId
                )
                .map((val, index) => (
                  <option value={val.id} key={index}>
                    {val.name}
                  </option>
                ))}
            </select>
          </div> */}
          {/* </div> */}

          <br />

          {/* <div style={{ background: "white", padding: "15px" }}> */}
          {/* <div className="form-group">
            <label className="control-label" htmlFor="EnableBusinessRuleId">
              Enable Property
            </label>
            <select
              id="EnableBusinessRuleId"
              className="form-control"
              defaultValue={this.props?.element?.EnableBusinessRuleId}
              onBlur={this.updateElement.bind(this)}
              onChange={this.editElementProp.bind(
                this,
                "EnableBusinessRuleId",
                "value"
              )}
            >
              <option value="" disabled selected>
                Select A Property{" "}
              </option>
              {this.state.businessRules
                .filter(
                  (val) => val.id !== this.props?.element?.VisibleBusinessRuleId
                )
                .map((val, index) => (
                  <option value={val.id} key={index}>
                    {val.name}
                  </option>
                ))}
            </select>
          </div> */}
          {/* </div> */}

          <br />

          {/* <div style={{ background: "white", padding: "15px" }}> */}
          <div className="form-group">
            <label className="control-label" htmlFor="ChoiceDisplayTypeId">
              Choice Display Property
            </label>
            <select
              id="ChoiceDisplayTypeId"
              className="form-control"
              defaultValue={this.props?.element?.ChoiceDisplayTypeId}
              onBlur={this.updateElement.bind(this)}
              onChange={this.editElementProp.bind(
                this,
                "ChoiceDisplayTypeId",
                "value"
              )}
            >
              <option value="" disabled selected>
                Select A Property
              </option>
              <option value="Checkbox" key={"Checkbox"}>
                Checkbox
              </option>
              <option value="CheckboxWithHeader" key={"CheckboxWithHeader"}>
                Checkbox With Header
              </option>
              <option
                value="CheckboxWithHeaderAndValue"
                key={"CheckboxWithHeaderAndValue"}
              >
                Checkbox With Header And Value
              </option>
              <option value="CheckboxWithValue" key={"CheckboxWithValue"}>
                Checkbox With Value
              </option>
              <option value="Standard" key={"Standard"}>
                Standard
              </option>
              <option value="VerticalRadioButtons" key={"VerticalRadioButtons"}>
                Vertical Radio Buttons
              </option>
            </select>
          </div>
          {/* </div> */}
          <br />

          <div className="form-group">
            <label
              className="control-label"
              htmlFor="EnableVisitQuestionnaireId"
            >
              Enable Visit Questionnaire Id (This diary will not be available
              until the specified Visit / Questionnaire is completed).
            </label>
            <select
              id="EnableVisitQuestionnaireId"
              className="form-control"
              defaultValue={""}
              onBlur={this.updateElement.bind(this)}
              onChange={this.editElementProp.bind(
                this,
                "EnableVisitQuestionnaireId",
                "value"
              )}
            >
              <option value={""} key={""}>
                Select Option
              </option>
              <option value={"123"} key={"123"}>
                123
              </option>
              <option value={"ABC"} key={"ABC"}>
                ABC
              </option>
              <option value={"XYZ"} key={"XYZ"}>
                XYZ
              </option>
            </select>
          </div>

          <br />
          <div className="form-group">
            <label className="control-label" htmlFor="PreviousDaysEntry">
              Previous Days Entry (This indicates how many days previous MUST be
              completed in the scale. 0 indicates just today. This would
              typically NOT have an available businessRule Id).
            </label>
            <div className="row">
              <div className="col-sm-12">
                <input
                  min={0}
                  className="form-control"
                  style={{ width: "100%" }}
                  type="number"
                  id="PreviousDaysEntry"
                  // disabled={true}
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(
                    this,
                    "PreviousDaysEntry",
                    "value"
                  )}
                />
              </div>
            </div>
          </div>

          <br />
          <div className="form-group">
            <label className="control-label" htmlFor="PreviousDaysEdit">
              Previous Days Edit (This indicates how many days previous can be
              edited. 0 indicates just today. This would typically NOT have an
              available businessRule Id. It is directly related to the Previous
              Days Entry and would not typically be greater than that value).
            </label>
            <div className="row">
              <div className="col-sm-12">
                <input
                  min={0}
                  className="form-control"
                  style={{ width: "100%" }}
                  type="number"
                  id="PreviousDaysEdit"
                  // disabled={true}
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(
                    this,
                    "PreviousDaysEdit",
                    "value"
                  )}
                />
              </div>
            </div>
          </div>

          <br />

          <div className="custom-control custom-checkbox">
            <input
              id="EnforcePreviousDaysEntry"
              className="custom-control-input"
              type="checkbox"
              checked={enforce_previous_days}
              value={true}
              onChange={this.editElementProp.bind(
                this,
                "EnforcePreviousDaysEntry",
                "checked"
              )}
            />
            <label
              className="custom-control-label"
              htmlFor="EnforcePreviousDaysEntry"
            >
              Enforce Previous Days Entry
            </label>
          </div>

          <br />

          <div className="custom-control custom-checkbox">
            <input
              id="AllowEdit"
              className="custom-control-input"
              type="checkbox"
              checked={allow_edit}
              value={true}
              onChange={this.editElementProp.bind(this, "AllowEdit", "checked")}
            />
            <label className="custom-control-label" htmlFor="AllowEdit">
              Allow Edit
            </label>
          </div>
          <br />

          <label for="submit-actions">Submit Actions:</label>
          <div class="form-group">
            <label for="first-select">Unassigned:</label>
            <select multiple class="form-control" id="first-select">
              <option disabled selected>
                Unassigned
              </option>
              <option>Subject Status Based initial Site Order</option>
              <option>Dispense Drug</option>
              <option>Create a Patient</option>
              <option>Assign Srata</option>
              <option>Randomize Patient</option>
              <option>Change Patient Status To Enrolled</option>
              <option>Randomize blocked by Site</option>
              <option>Randomize With Site Blocking</option>
              <option>Screen Fail</option>
              <option>Discontinue</option>
              <option>Dispense Drug With Titration</option>
            </select>
          </div>
          <div class="form-group">
            <label for="second-select">Assigned:</label>
            <select
              multiple
              class="form-control"
              id="second-select"
              disabled
              selected
            >
              <option>Assigned</option>
            </select>
          </div>

          <h3>Question Styles</h3>
          <br />
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="control-label" htmlFor="width">
                  Width
                </label>
                <div className="row">
                  <div className="col-sm-12">
                    <input
                      className="form-control"
                      style={{ width: "100%" }}
                      type="number"
                      id="width"
                      onBlur={this.updateElement.bind(this)}
                      onChange={this.editElementProp.bind(
                        this,
                        "width",
                        "value"
                      )}
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="form-group">
                <label className="control-label" htmlFor="textSize">
                  Text Size
                </label>
                <div className="row">
                  <div className="col-sm-12">
                    <input
                      className="form-control"
                      style={{ width: "100%" }}
                      type="number"
                      id="textSize"
                      onBlur={this.updateElement.bind(this)}
                      onChange={this.editElementProp.bind(
                        this,
                        "textSize",
                        "value"
                      )}
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="form-group">
                <label className="control-label" htmlFor="marginTop">
                  Margin Top
                </label>
                <div className="row">
                  <div className="col-sm-12">
                    <input
                      className="form-control"
                      style={{ width: "100%" }}
                      type="number"
                      id="marginTop"
                      onBlur={this.updateElement.bind(this)}
                      onChange={this.editElementProp.bind(
                        this,
                        "marginTop",
                        "value"
                      )}
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="form-group">
                <label className="control-label" htmlFor="marginBottom">
                  Margin Bottom
                </label>
                <div className="row">
                  <div className="col-sm-12">
                    <input
                      className="form-control"
                      style={{ width: "100%" }}
                      type="number"
                      id="marginBottom"
                      onBlur={this.updateElement.bind(this)}
                      onChange={this.editElementProp.bind(
                        this,
                        "marginBottom",
                        "value"
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="control-label" htmlFor="displayAlignment">
                  Display Alignment
                </label>
                <select
                  id="displayAlignment"
                  className="form-control"
                  defaultValue={"center"}
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(
                    this,
                    "displayAlignment",
                    "value"
                  )}
                >
                  <option value={"center"} key={"center"}>
                    Center
                  </option>
                  <option value={"right"} key={"right"}>
                    Right
                  </option>
                  <option value={"left"} key={"left"}>
                    Left
                  </option>
                </select>
              </div>
              <br />
              <div className="form-group">
                <label className="control-label" htmlFor="textAlignment">
                  Text Alignment
                </label>
                <select
                  id="textAlignment"
                  className="form-control"
                  defaultValue={"center"}
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(
                    this,
                    "textAlignment",
                    "value"
                  )}
                >
                  <option value={"center"} key={"center"}>
                    Center
                  </option>
                  <option value={"right"} key={"right"}>
                    Right
                  </option>
                  <option value={"left"} key={"left"}>
                    Left
                  </option>
                </select>
              </div>
              <br />
              <div className="form-group">
                <label className="control-label" htmlFor="marginLeft">
                  Margin Left
                </label>
                <div className="row">
                  <div className="col-sm-12">
                    <input
                      className="form-control"
                      style={{ width: "100%" }}
                      type="number"
                      id="marginLeft"
                      onBlur={this.updateElement.bind(this)}
                      onChange={this.editElementProp.bind(
                        this,
                        "marginLeft",
                        "value"
                      )}
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="form-group">
                <label className="control-label" htmlFor="marginRight">
                  Margin Right
                </label>
                <div className="row">
                  <div className="col-sm-12">
                    <input
                      className="form-control"
                      style={{ width: "100%" }}
                      type="number"
                      id="marginRight"
                      onBlur={this.updateElement.bind(this)}
                      onChange={this.editElementProp.bind(
                        this,
                        "marginRight",
                        "value"
                      )}
                    />
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={this.state.activeTab} index={1}>
          <div style={{ background: "white", padding: "15px" }}>
            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input
                  id="useCalculation"
                  className="custom-control-input"
                  type="checkbox"
                  checked={this.props.element.useCalculation}
                  value={this.props.element.useCalculation}
                  onChange={this.editElementProp.bind(
                    this,
                    "useCalculation",
                    "checked"
                  )}
                />
                <label
                  className="custom-control-label"
                  htmlFor="useCalculation"
                >
                  Select from Calculations ?
                </label>
              </div>
            </div>

            {!!!this.props.element.useCalculation ? (
              <>
                <div className="form-group">
                  <label className="control-label" htmlFor="depends_on">
                    If
                  </label>
                  <select
                    id="depends_on"
                    className="form-control"
                    placeholder="Select a dependant components"
                    defaultValue={this.props.element.dependsOn || ""}
                    onBlur={this.updateElement.bind(this)}
                    onChange={this.editElementProp.bind(
                      this,
                      "dependsOn",
                      "value"
                    )}
                  >
                    <option value="" disabled selected>
                      Select a dependant components
                    </option>

                    {this.props?.formData?.map((formElement, index) => {
                      if (this.props.element.id != formElement.id) {
                        return (
                          <option value={formElement.id} key={formElement.id}>
                            {index + 1 + ". " + formElement.text}
                          </option>
                        );
                      }
                    })}
                  </select>
                </div>
                <div className="form-group">
                  <label className="control-label" htmlFor="hasCondition">
                    Condition
                  </label>
                  <select
                    id="hasCondition"
                    className="form-control"
                    placeholder="Select a value for parent components"
                    defaultValue={this.props.element.hasCondition || ""}
                    onBlur={this.updateElement.bind(this)}
                    onChange={this.editElementProp.bind(
                      this,
                      "hasCondition",
                      "value"
                    )}
                  >
                    <option value="" disabled selected>
                      Select a condition
                    </option>

                    <option value={"=="} key={"eq"}>
                      {"equal ="}
                    </option>
                    <option value={"<"} key={"le"}>
                      {"Less Than <"}
                    </option>
                    <option value={">"} key={"gt"}>
                      {"Greater Than >"}
                    </option>
                    <option value={"<="} key={"le-eq"}>
                      {"Less Than and equal  <="}
                    </option>
                    <option value={">="} key={"gt-eq"}>
                      {"Greater Than and equal >="}
                    </option>
                  </select>
                </div>
              </>
            ) : (
              <div className="form-group">
                <label className="control-label" htmlFor="calculation">
                  Calculation
                </label>
                <select
                  id="calculation"
                  className="form-control"
                  placeholder="Select a Calculation"
                  defaultValue={this.props.element.calculation || ""}
                  onBlur={this.updateElement.bind(this)}
                  onChange={this.editElementProp.bind(
                    this,
                    "calculation",
                    "value"
                  )}
                >
                  <option value="" disabled selected>
                    Select a Calculation
                  </option>

                  {this.state?.calculations?.map((item, index) => {
                    return (
                      <option value={item.id} key={item.id}>
                        {index + 1 + ". " + item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
            <div className="form-group">
              <label className="control-label" htmlFor="hasValue">
                has Value
              </label>
              <input
                id="hasValue"
                className="form-control"
                placeholder="Select a value for parent components"
                defaultValue={this.props.element.hasValue || ""}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, "hasValue", "value")}
              ></input>
            </div>
          </div>

          <hr></hr>
          <div style={{ background: "white", padding: "15px" }}>
            <div className="form-group">
              <label className="control-label" htmlFor="rule_do">
                Do
              </label>
              <select
                id="rule_do"
                className="form-control"
                placeholder="Select a child component behaviour"
                defaultValue={this.props.element.doRule || ""}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(this, "doRule", "value")}
              >
                <option value="" disabled selected>
                  Select a child component behaviour
                </option>

                <option value={true} key={true}>
                  Show
                </option>
                <option value={false} key={false}>
                  Hide
                </option>
                <option value={-1} key={-1}>
                  Disable
                </option>
              </select>
            </div>
          </div>
        </TabPanel>
        {/* <TabPanel value={this.state.activeTab} index={2}> */}
        {/* {console.log("THIS PROSP >>> ", this.props)}
          <div style={{ background: "white", padding: "15px" }}>
            <div className="form-group">
              <label className="control-label" htmlFor="VisibleBusinessRuleId">
                Visible Property
              </label>
              <select
                id="VisibleBusinessRuleId"
                className="form-control"
                defaultValue={this.props?.element?.VisibleBusinessRuleId}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(
                  this,
                  "VisibleBusinessRuleId",
                  "value"
                )}
              >
                <option value="" disabled selected>
                  Select A Property
                </option>
                <option value="show" key={"show"}>
                  Show
                </option>
                <option value="hide" key={"hide"}>
                  Hide
                </option>
              </select>
            </div>
          </div>

          <div style={{ background: "white", padding: "15px" }}>
            <div className="form-group">
              <label className="control-label" htmlFor="EnableBusinessRuleId">
                Enable Property
              </label>
              <select
                id="EnableBusinessRuleId"
                className="form-control"
                defaultValue={this.props?.element?.EnableBusinessRuleId}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(
                  this,
                  "EnableBusinessRuleId",
                  "value"
                )}
              >
                <option value="" disabled selected>
                  Select A Property
                </option>
                <option value="enable" key={"enable"}>
                  Enable
                </option>
                <option value="disable" key={"disable"}>
                  Disable
                </option>
              </select>
            </div>
          </div>

          <div style={{ background: "white", padding: "15px" }}>
            <div className="form-group">
              <label className="control-label" htmlFor="ChoiceDisplayTypeId">
                Choice Display Property
              </label>
              <select
                id="ChoiceDisplayTypeId"
                className="form-control"
                defaultValue={this.props?.element?.ChoiceDisplayTypeId}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(
                  this,
                  "ChoiceDisplayTypeId",
                  "value"
                )}
              >
                <option value="" disabled selected>
                  Select A Property
                </option>
                <option value="Checkbox" key={"Checkbox"}>
                  Checkbox
                </option>
                <option value="CheckboxWithHeader" key={"CheckboxWithHeader"}>
                  Checkbox With Header
                </option>
                <option
                  value="CheckboxWithHeaderAndValue"
                  key={"CheckboxWithHeaderAndValue"}
                >
                  Checkbox With Header And Value
                </option>
                <option value="CheckboxWithValue" key={"CheckboxWithValue"}>
                  Checkbox With Value
                </option>
                <option value="Standard" key={"Standard"}>
                  Standard
                </option>
                <option
                  value="VerticalRadioButtons"
                  key={"VerticalRadioButtons"}
                >
                  Vertical Radio Buttons
                </option>
              </select>
            </div>
          </div> */}

        {/* <div style={{ background: "white", padding: "15px" }}>
            <div className="form-group">
              <label className="control-label" htmlFor="ChoiceDisplayTypeId">
                Choice Display
              </label>
              <select
                id="EnableBusinessRuleId"
                className="form-control"
                placeholder="Select a child component behaviour"
                defaultValue={this.props?.element?.ChoiceDisplayTypeId}
                onBlur={this.updateElement.bind(this)}
                onChange={this.editElementProp.bind(
                  this,
                  "ChoiceDisplayTypeId",
                  "value"
                )}
              >
                <option value="" disabled selected>
                  Select A Property{" "}
                </option>
                {this.state.businessRules
                  .filter(
                    (val) => val.id !== this.props?.element?.ChoiceDisplayTypeId
                  )
                  .map((val, index) => (
                    <option value={val.id} key={index}>
                      {val.name}
                    </option>
                  ))}
              </select>
            </div>
          </div> */}
        {/* </TabPanel> */}
      </div>
    );
  }
}
FormElementsEdit.defaultProps = { className: "edit-element-fields" };
