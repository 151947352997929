import React from "react";
import "../../pages/admin/StudySetup/Questionnaire/Questionnaire.css";

const PageBreak = React.forwardRef((props, ref) => {

  const { hide } = props;
  
  return (
    <>
      <div
        ref={ref}
        className={hide ? "pageBreakInactive" : "pageBreakContainer"}
        id={"childBreaker"}
      >
        <h5>Page Break</h5>
      </div>
    </>
  );
});

export default PageBreak;
